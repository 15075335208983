import BackTo from "../../../common/button/BackTo";
import UserForm from "../userForm/UserForm";

const AddUser = () => {
  return (
    <div>
      <BackTo name="Users" path="/portal/users" />
      <UserForm />
    </div>
  );
};

export default AddUser;
