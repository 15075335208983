import CountrySetReducer from "./countrySet/CountrySetReducer";
import MovieReducer from "./movie/MovieReducer";
import PurchaseReducer from "./purchase/PurchaseReducer";
import RemarkReducer from "./remark/RemarkReducer";
import RightReducer from "./right/RightReducer";
import RoleReducer from "./role/RoleReducer";
import SaleReducer from "./sale/SaleReducer";
import UserReducer from "./user/UserReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  user: UserReducer,
  movie: MovieReducer,
  countrySet: CountrySetReducer,
  purchase: PurchaseReducer,
  sale: SaleReducer,
  right: RightReducer,
  role: RoleReducer,
  remark: RemarkReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
