import { RECEIVE_SALES, REQUEST_SALES, SaleActionTypes } from "./SaleTypes";

import { Api } from "../../utils/Api";
import { Notification } from "../../utils/Notification";
import { RootState } from "..";
import { SaleDto } from "../../dto/sale/SaleDto";
import { SaleMediaRequestDto } from "../../dto/sale/SaleMediaRequestDto";
import { SaleRequestDto } from "../../dto/sale/SaleRequestDto";
import { ThunkAction } from "redux-thunk";
import { isBlockingError } from "../../dto/common/AppErrorDto";

const requestSales = (): SaleActionTypes => ({
  type: REQUEST_SALES,
});

const receiveSales = (sales: SaleDto[]): SaleActionTypes => ({
  type: RECEIVE_SALES,
  payload: sales,
});

const getAllSalesFromApi = async (): Promise<SaleDto[]> => {
  try {
    const sales = await Api().get<SaleDto[]>("/sales");
    return sales.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to get all sales");
    return [];
  }
};

const addSaleApi = async (
  saleDto: SaleRequestDto
): Promise<SaleDto | undefined> => {
  try {
    const sales = await Api().post<SaleDto>("/sales", saleDto);
    Notification.showSuccess("Created sale successfully");
    return sales.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to add new sale");
    if (isBlockingError(err)) {
      throw err;
    }
    return undefined;
  }
};

const updateSaleApi = async (
  saleId: string,
  saleDto: SaleRequestDto
): Promise<SaleDto> => {
  try {
    const sales = await Api().put<SaleDto>(`/sales/${saleId}`, saleDto);
    Notification.showSuccess("Updated sale successfully");
    return sales.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to update sale");
    throw err;
  }
};

const updateSaleMediaApi = async (
  saleId: string,
  saleDto: SaleMediaRequestDto
): Promise<SaleDto> => {
  try {
    const sales = await Api().post<SaleDto>(`/sales/${saleId}/media`, saleDto);
    return sales.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to update sale files");
    throw err;
  }
};

export const getSaleDetailsApi = async (saleId: string): Promise<SaleDto> => {
  try {
    const sales = await Api().get<SaleDto>(`/sales/${saleId}`);
    return sales.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to get sale");
    throw err;
  }
};

export const confirmSaleApi = async (saleId: string): Promise<SaleDto> => {
  try {
    const sales = await Api().put<SaleDto>(`/sales/${saleId}/confirm`);
    return sales.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to confirm sale");
    throw err;
  }
};

const deleteSaleApi = async (
  saleId: string
): Promise<SaleDto> => {
  try {
    const sale = await Api().delete<SaleDto>(
      `/sales/${saleId}`
    );
    Notification.showSuccess("Sale deleted successfully");
    return sale.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to delete Sale");
    throw err;
  }
};

export const getAllSales =
  (force = false): ThunkAction<void, RootState, unknown, SaleActionTypes> =>
  async (dispatch, getState) => {
    const { sale } = getState();
    if (sale.loading || (!force && sale.loaded)) {
      return;
    }
    dispatch(requestSales());
    const sales = await getAllSalesFromApi();
    dispatch(receiveSales(sales));
  };

export const addNewSale =
  (
    saleDto: SaleRequestDto
  ): ThunkAction<
    Promise<SaleDto | undefined>,
    RootState,
    unknown,
    SaleActionTypes
  > =>
  async (dispatch) => {
    const sale = await addSaleApi(saleDto);
    dispatch(getAllSales(true));
    return Promise.resolve(sale);
  };

export const updateSale =
  (
    saleId: string,
    saleDto: SaleRequestDto
  ): ThunkAction<Promise<SaleDto>, RootState, unknown, SaleActionTypes> =>
  async (dispatch) => {
    const sale = await updateSaleApi(saleId, saleDto);
    dispatch(getAllSales(true));
    return Promise.resolve(sale);
  };

export const updateSaleMedia =
  (
    saleId: string,
    saleDto: SaleMediaRequestDto
  ): ThunkAction<Promise<SaleDto>, RootState, unknown, SaleActionTypes> =>
  async (dispatch) => {
    const sale = await updateSaleMediaApi(saleId, saleDto);
    dispatch(getAllSales(true));
    return Promise.resolve(sale);
  };

export const deleteSale =
  (
    saleId: string
  ): ThunkAction<
    Promise<SaleDto>,
    RootState,
    unknown,
    SaleActionTypes
  > =>
  async (dispatch) => {
    const sale = await deleteSaleApi(saleId);
    dispatch(getAllSales(true));
    return Promise.resolve(sale);
  };
