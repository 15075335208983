import { MovieActionTypes, RECEIVE_MOVIES, REQUEST_MOVIES } from "./MovieTypes";

import { Api } from "../../utils/Api";
import { MovieDto } from "../../dto/movie/MovieDto";
import { Notification } from "../../utils/Notification";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";

const requestMovies = (): MovieActionTypes => ({
  type: REQUEST_MOVIES,
});

const receiveMovies = (movies: MovieDto[]): MovieActionTypes => ({
  type: RECEIVE_MOVIES,
  payload: movies,
});

const getAllMoviesFromApi = async (): Promise<MovieDto[]> => {
  try {
    const movies = await Api().get<MovieDto[]>("/movies");
    return movies.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get all movies");
    return [];
  }
};

export const getMovieDetails = async (movieId: string): Promise<MovieDto> => {
  try {
    const movie = await Api().get<MovieDto>(`/movies/${movieId}`);
    return movie.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get movie info");
    throw err;
  }
};

const addMovieDetailsApi = async (movieDto: MovieDto) => {
  try {
    const movie = await Api().post<MovieDto>(`/movies`, movieDto);
    Notification.showSuccess("Created movie successfully");
    return movie.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot add movie");
    throw err;
  }
};

const updateMovieDetailsApi = async (movieId: string, movieDto: MovieDto) => {
  try {
    const movie = await Api().put<MovieDto>(`/movies/${movieId}`, movieDto);
    Notification.showSuccess("Updated movie successfully");
    return movie.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot update movie info");
    throw err;
  }
};

export const deleteMovie =
  (
    movieId: string
  ): ThunkAction<
    Promise<MovieDto>,
    RootState,
    unknown,
    MovieActionTypes
  > =>
  async (dispatch) => {
    const movie = await deleteMovieApi(movieId);
    dispatch(getAllMovies(true));
    return Promise.resolve(movie);
  };

export const getAllMovies =
  (force = false): ThunkAction<void, RootState, unknown, MovieActionTypes> =>
  async (dispatch, getState) => {
    const { movie } = getState();
    if (movie.loading || (!force && movie.loaded)) {
      return;
    }
    dispatch(requestMovies());
    const movies: MovieDto[] = await getAllMoviesFromApi();
    dispatch(receiveMovies(movies));
  };

export const addMovie =
  (
    movieDto: MovieDto
  ): ThunkAction<Promise<MovieDto>, RootState, unknown, MovieActionTypes> =>
  async (dispatch) => {
    const movie = await addMovieDetailsApi(movieDto);
    dispatch(getAllMovies(true));
    return Promise.resolve(movie);
  };

export const updateMovie =
  (
    movieId: string,
    movieDto: MovieDto
  ): ThunkAction<Promise<MovieDto>, RootState, unknown, MovieActionTypes> =>
  async (dispatch) => {
    const movie = await updateMovieDetailsApi(movieId, movieDto);
    dispatch(getAllMovies(true));
    return Promise.resolve(movie);
  };

const deleteMovieApi = async (
  movieId: string
): Promise<MovieDto> => {
  try {
    const movie = await Api().delete<MovieDto>(
      `/movies/${movieId}`
    );
    Notification.showSuccess("Movie deleted successfully");
    return movie.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to delete movie");
    throw err;
  }
};
