import { Route, useRouteMatch } from "react-router-dom";

import CountrySetAdd from "./countrySetAdd/CountrySetAdd";
import CountrySetDetails from "./countrySetDetails/CountrySetDetails";
import CountrySetEdit from "./countrySetEdit/CountrySetEdit";
import CountrySetList from "./countrySetList/CountrySetList";
import { GetPrevilage } from "../../../utils/Previlage";
import { PAGE_NOT_AUTHORIZED } from "../../../constants/constants";
import { Switch } from "react-router-dom";

const CountrySet = () => {
  const match = useRouteMatch();
  const viewPrevilage = GetPrevilage('CountrySet', 'View');
  
  return (
    <>
      {viewPrevilage ? (
        <Switch>
          <Route path={match.path} exact component={CountrySetList} />
          <Route path={`${match.path}/add`} component={CountrySetAdd} />
          <Route
            path={`${match.path}/:countrySetId/edit`}
            component={CountrySetEdit}
          />
          <Route
            path={`${match.path}/:countrySetId`}
            component={CountrySetDetails}
          />
        </Switch>
      ) : (
        <span>{PAGE_NOT_AUTHORIZED}</span>
      )}
    </>
  );
};

export default CountrySet;
