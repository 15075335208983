import { useEffect, useState } from "react";

export default function usePersistedState<T>(
  key: string,
  defaultValue: T,
  translateInitialState?: (value: T) => T
) {
  const [state, setState] = useState<T>(() => {
    const value = window.localStorage.getItem(key);
    if (!value) {
      return defaultValue;
    }
    const initalValue = JSON.parse(value) || defaultValue;
    return translateInitialState
      ? translateInitialState(initalValue)
      : initalValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return { state, setState };
}
