import { MovieDto } from "../../dto/movie/MovieDto";

export const REQUEST_MOVIES = "REQUEST_MOVIES";
export const RECEIVE_MOVIES = "RECEIVE_MOVIES";

interface RequestMoviesAction {
  type: typeof REQUEST_MOVIES;
}

interface ReceiveMoviesAction {
  type: typeof RECEIVE_MOVIES;
  payload: MovieDto[];
}

export type MovieActionTypes = RequestMoviesAction | ReceiveMoviesAction;
