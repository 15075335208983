import { Checkbox, CheckboxProps, FormControlLabel } from "@material-ui/core";

interface CustomCheckboxProps {
  label: string;
}

export default function CustomCheckbox({
  label,
  className,
  ...props
}: CustomCheckboxProps & CheckboxProps) {
  return (
    <FormControlLabel
      className={className}
      control={<Checkbox {...props} color={props.color ?? "primary"} />}
      label={label}
    />
  );
}
