import { PlatformOptions, PlatformType } from "../../../../dto/sale/SaleDto";

import CustomAutocomplete from "../../../common/form/CustomAutocomplete";

interface ChoosePlatformProps {
  platform: PlatformType;
  setPlatForm: (platform: PlatformType) => void;
  label?: string;
  disabled?: boolean;
}

export function ChoosePlatform(props: ChoosePlatformProps) {
  return (
    <CustomAutocomplete
      value={props.platform}
      onChange={props.setPlatForm}
      label={props.label ?? "Platform"}
      options={PlatformOptions}
      disabled={props.disabled}
    />
  );
}
