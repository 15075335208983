import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import { ChangeEvent, useCallback, useState } from "react";

import { Column } from "../../../common/layout/Table";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "scroll",
    maxHeight: "100%",
  },
  selectedColumns: {
    overflow: "scroll",
  },
  row: {
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
  addColumnSelect: {
    margin: theme.spacing(0.5, 2),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    margin: theme.spacing(2),
    "& > *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

interface SelectedColumnItemProps<T extends object> {
  column: Column<T>;
  removeColumn: (column: string) => void;
}

const SelectedColumnItem = <T extends object>({
  column,
  removeColumn,
}: SelectedColumnItemProps<T>) => {
  const classes = useStyles();

  const field: string = column.field as string;
  const onRemoveColumn = () => {
    removeColumn(field);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.row}
    >
      {column.title}
      <IconButton aria-label="delete" size="small" onClick={onRemoveColumn}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

interface ColumnPickerProps<T extends object> {
  allColumns: Column<T>[];
  columns: string[];
  setColumns: (columns: string[]) => void;
}

const ColumnPicker = <T extends object>({
  allColumns,
  columns,
  setColumns,
}: ColumnPickerProps<T>) => {
  const classes = useStyles();

  const [internalColumns, setInternalColumns] = useState(columns);

  const chooseColumns = useCallback(
    (e: ChangeEvent<{ value: any }>) => {
      const selectedColumns: string = e.target.value;
      setInternalColumns([...internalColumns, selectedColumns]);
    },
    [internalColumns, setInternalColumns]
  );

  const onRemoveColumn = useCallback(
    (column: string) => {
      let newColumns = [...internalColumns];
      newColumns = newColumns.filter((c) => c !== column);
      setInternalColumns(newColumns);
    },
    [internalColumns, setInternalColumns]
  );

  const newColumns = allColumns.filter(
    (c) => !internalColumns.includes(c.field as string)
  );

  const choosenColumns = allColumns.filter((c) =>
    internalColumns.includes(c.field as string)
  );

  const onSubmit = useCallback(() => {
    setColumns(internalColumns);
  }, [internalColumns, setColumns]);

  return (
    <Box display="flex" flexDirection="column" className={classes.container}>
      <Box flexGrow={1} className={classes.selectedColumns}>
        {choosenColumns.map((column) => (
          <SelectedColumnItem
            column={column}
            key={column.field as string}
            removeColumn={onRemoveColumn}
          />
        ))}
      </Box>
      <form onSubmit={onSubmit}>
        <Box className={classes.addColumnSelect}>
          <FormControl fullWidth>
            <InputLabel id="add-new-column-label">Add new column</InputLabel>
            <Select
              labelId="add-new-column-label"
              value=""
              onChange={chooseColumns}
            >
              {newColumns.map((column) => (
                <MenuItem
                  key={column.field as string}
                  value={column.field as string}
                >
                  {column.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          className={classes.buttonContainer}
        >
          <Button color="primary" variant="text" type="reset">
            CANCEL
          </Button>
          <Button color="primary" variant="contained" type="submit">
            APPLY
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ColumnPicker;
