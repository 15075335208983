import { FilterCondition } from "../../../../dto/report/FilterCondition";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import { MovieFieldNames } from "../../../../dto/report/movie/MovieFieldNames";
import PurchaseReport from "./PurchaseReport";

interface MoviePurchaseReportProps {
  movie: MovieDto;
}

export default function MoviePurchaseReport({
  movie,
}: MoviePurchaseReportProps) {
  return (
    <PurchaseReport
      filters={[
        {
          field: MovieFieldNames.MOVIE_ID,
          condition: FilterCondition.EQUALS,
          value: movie.id,
        },
      ]}
      tableId="movie-purchase-report"
      title={`Purchases for movie "${movie.name}"`}
    />
  );
}
