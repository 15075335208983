import React, { useEffect, useState } from 'react'

import BackTo from '../../../common/button/BackTo';
import { UserDto } from '../../../../dto/user/UserDto';
import UserForm from '../userForm/UserForm';
import { getUserDetails } from '../../../../store/user/UserAction';
import { useParams } from 'react-router-dom';

const EditUser = () => {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<UserDto>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getUserDetails(userId)
      .then((user) => setUser(user))
      .catch(() => setError(true));
  }, [userId]);

  return (
    <div>
      <BackTo name="User Detail" />
      {user ? <UserForm user={user} /> : null}
      {error ? <p>Failed to load user detail</p> : null}
    </div>
  )
}

export default EditUser;
