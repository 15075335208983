import { Box, Chip, IconButton, makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";

import ColumnPickerPopup from "./ColumnPickerPopup";
import FilterChip from "./FilterChip";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { FilterField } from "../../../../dto/report/FilterField";
import FilterTablePopupForm from "./FilterTablePopupForm";
import { MTableToolbar } from "@material-table/core";
import TuneIcon from "@material-ui/icons/Tune";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(1, 2),
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function FilterTableToolbar(
  filters: FilterDto[],
  setFilters: ((filters: FilterDto[]) => void) | undefined,
  fields: FilterField[],
  columnFormComponent: JSX.Element
) {
  const classes = useStyles();

  const [index, setIndex] = useState<number>(-1);
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [columnPopupOpen, setColumnPopupOpen] = useState(false);

  const openPopup = useCallback(() => {
    if (setFilters !== undefined) {
      setFilterPopupOpen(true);
    }
  }, [setFilters]);

  const closeFilterPopup = useCallback(() => {
    setIndex(-1);
    setFilterPopupOpen(false);
  }, []);

  const onAddFilter = useCallback(() => {
    setIndex(-1);
    openPopup();
  }, [openPopup]);

  const onUpdateFitler = useCallback(
    (index: number) => {
      setIndex(index);
      openPopup();
    },
    [openPopup]
  );

  const onDeleteFilter = useCallback(
    (index: number) => {
      const newFilters = [...filters];
      newFilters.splice(index, 1);
      setFilters?.(newFilters);
    },
    [filters, setFilters]
  );

  const setFilter = useCallback(
    (filter: FilterDto) => {
      const newFilters = [...filters];
      if (index >= 0) {
        newFilters.splice(index, 1, filter);
      } else {
        newFilters.push(filter);
      }
      setFilters?.(newFilters);
      closeFilterPopup();
    },
    [filters, setFilters, index, closeFilterPopup]
  );

  const openColumnPopup = useCallback(() => {
    setColumnPopupOpen(true);
  }, []);

  const closeColumnPopup = useCallback(() => {
    setColumnPopupOpen(false);
  }, []);

  return (props: any) => (
    <div>
      <MTableToolbar {...props} />
      <FilterTablePopupForm
        open={filterPopupOpen}
        fields={fields}
        filter={index >= 0 ? filters[index] : undefined}
        setFilter={setFilter}
        onCancel={closeFilterPopup}
      />
      <ColumnPickerPopup
        open={columnPopupOpen}
        onClose={closeColumnPopup}
        form={columnFormComponent}
      />
      <Box display="flex" alignItems="center">
        <div className={classes.filterContainer}>
          {filters.map((filter, index) => (
            <FilterChip
              key={index}
              filter={filter}
              index={index}
              fields={fields}
              onUpdate={onUpdateFitler}
              onDelete={onDeleteFilter}
            />
          ))}
          {setFilters && <Chip label="Add Filter" onClick={onAddFilter} />}
        </div>
        <div>
          <IconButton aria-label="settings" onClick={openColumnPopup}>
            <TuneIcon />
          </IconButton>
        </div>
      </Box>
    </div>
  );
}
