import {
  Jukebox,
  JukeboxSongType,
  JukeboxType,
} from "../../../../../dto/sale/SaleDto";

import { ChooseJukebox } from "./ChooseJukebox";
import { ChooseJukeboxSongType } from "./ChooseJukeboxSongType";
import CustomAutocomplete from "../../../../common/form/CustomAutocomplete";
import { Grid } from "@material-ui/core";
import TimeDurationInput from "../../../../common/form/TimeDurationInput";
import useSaleProperty from "../../../../../store/sale/useSaleProperty";

interface JukeboxFormProps {
  jukebox?: Jukebox;
  setJukebox: (jukebox: Jukebox) => void;
}

export default function JukeboxForm({ jukebox, setJukebox }: JukeboxFormProps) {
  const onChangeType = (type: JukeboxType) => setJukebox({ ...jukebox, type });

  const onChangeName = (name: string) => setJukebox({ ...jukebox, name });
  const onChangeVideoId = (videoId: string) =>
    setJukebox({ ...jukebox, videoId });

  const onChangeItemDuration = (itemDuration?: number) =>
    setJukebox({ ...jukebox, itemDuration });

  const onChangeJukeboxDuration = (jukeboxDuration?: number) =>
    setJukebox({ ...jukebox, jukeboxDuration });

  const onChangeJukeboxSong = (songType: JukeboxSongType) =>
    setJukebox({ ...jukebox, songType });

  const nameOptions = useSaleProperty("jukeboxName");
  const videoIdOptions = useSaleProperty("jukeboxVideoId");

  return (
    <>
      <Grid item xs={12} md={6}>
        <ChooseJukebox jukebox={jukebox?.type} setJukebox={onChangeType} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomAutocomplete
          label="Jukebox Name"
          type="text"
          options={nameOptions}
          freeSolo
          inputValue={jukebox?.name ?? ""}
          onInputChange={onChangeName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomAutocomplete
          label="Jukebox Video Id"
          type="text"
          options={videoIdOptions}
          freeSolo
          inputValue={jukebox?.videoId ?? ""}
          onInputChange={onChangeVideoId}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TimeDurationInput
          label="Item Duration"
          value={jukebox?.itemDuration}
          onChange={onChangeItemDuration}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TimeDurationInput
          label="Jukebox Duration"
          value={jukebox?.jukeboxDuration}
          onChange={onChangeJukeboxDuration}
        />
      </Grid>
      {jukebox?.type === JukeboxType.Song && (
        <Grid item xs={12} md={6}>
          <ChooseJukeboxSongType
            jukeboxSong={jukebox?.songType}
            setJukeboxSong={onChangeJukeboxSong}
          />
        </Grid>
      )}
    </>
  );
}
