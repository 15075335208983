import Table, { Column } from "../../../common/layout/Table";

import { BoxProps } from "@material-ui/core";
import { CrewRoles } from "../../../../dto/movie/CrewRoles";
import DetailCard from "../../common/DetailCard";
import { GetPrevilage } from "../../../../utils/Previlage";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import { SongDto } from "../../../../dto/movie/SongDto";
import TableUtils from "../../../../utils/TableUtils";

interface SongWithIdDto extends SongDto {
  id: string;
}

const columns: Column<SongWithIdDto>[] = [
  { title: "Name", field: "name" },
  {
    title: "Singer",
    field: "crew.singer",
    render: TableUtils.movieCrewValueRender("crew", CrewRoles.SINGER),
  },
  {
    title: "Lyricist",
    field: "crew.lyricist",
    render: TableUtils.movieCrewValueRender("crew", CrewRoles.LYRICIST),
  },
  { title: "Genre", field: "genre" },
];

interface MovieSongDetailsProps {
  movie: MovieDto;
}

export default function MovieSongDetails({
  movie,
  ...props
}: MovieSongDetailsProps & BoxProps) {
  const songs: SongWithIdDto[] = movie.songs.map((s, id) => ({
    ...s,
    id: `${id}`,
  }));
  const movieReportPrevilage = GetPrevilage('Reports', 'Movie');

  return (
    <DetailCard title="Song information" {...props}>
      <Table id="song-list" title="Songs" data={songs} columns={columns} reportPrevilage={movieReportPrevilage} />
    </DetailCard>
  );
}
