export interface CountrySetDto {
  id?: string;
  name: string;
  countries: string[];
  included: boolean;
}

export type CountrySetStringProperties = "name" | "country";

export const selectCountrySetValueByProperty = (
  countrySets: CountrySetDto[],
  property: CountrySetStringProperties
) => {
  let values: string[] = [];
  switch (property) {
    case "name":
      values = countrySets.map((countrySet) => countrySet.name);
      break;
    case "country":
      values = countrySets.flatMap((countrySet) => countrySet.countries);
      break;
  }
  values = values.filter((value) => value !== "");
  return Array.from(new Set(values));
};
