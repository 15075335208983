import BackTo from "../../../common/button/BackTo";
import MovieForm from "../movieForm/MovieForm";

const AddMovie = () => {
  return (
    <div>
      <BackTo name="Movies" path="/portal/movies" />
      <MovieForm />
    </div>
  );
};

export default AddMovie;
