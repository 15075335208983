import { FilterCondition } from "../../../../dto/report/FilterCondition";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import { MovieFieldNames } from "../../../../dto/report/movie/MovieFieldNames";
import SalesReport from "./SalesReport";

interface MovieSalesReportProps {
  movie: MovieDto;
}
export default function MovieSalesReport({ movie }: MovieSalesReportProps) {
  return (
    <SalesReport
      filters={[
        {
          field: MovieFieldNames.MOVIE_ID,
          condition: FilterCondition.EQUALS,
          value: movie.id,
        },
      ]}
      tableId="movie-sale-report"
      title={`Sales for movie "${movie.name}"`}
    />
  );
}
