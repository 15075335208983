import DateField from "../../../common/form/DateField";
import { FilterFieldType } from "../../../../dto/report/FilterFieldType";
import PriceAmountField from "../../../common/form/PriceAmountField";
import { TextField } from "@material-ui/core";
import TimeDurationInput from "../../../common/form/TimeDurationInput";

export const getTextFieldType = (fieldType: FilterFieldType) => {
  switch (fieldType) {
    case FilterFieldType.NUMBER:
    case FilterFieldType.TIME_LENGTH:
      return "number";
    case FilterFieldType.DATE:
    case FilterFieldType.DURATION:
      return "date";
    case FilterFieldType.STRING:
    case FilterFieldType.LIST:
    default:
      return "text";
  }
};

interface FilterInputFieldProps {
  label: string;
  name: string;
  value: any;
  required?: boolean;
  fullWidth?: boolean;
  fieldType: FilterFieldType;
  onChange: (value: any) => void;
}

export default function FilterInputField(props: FilterInputFieldProps) {
  const type = getTextFieldType(props.fieldType);

  if (type === "date") {
    return (
      <DateField
        label={props.label}
        fullWidth={props.fullWidth}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
      />
    );
  } else if (props.fieldType === FilterFieldType.CURRENCY) {
    return <PriceAmountField value={props.value} setValue={props.onChange} />;
  } else if (props.fieldType === FilterFieldType.TIME_LENGTH) {
    return (
      <TimeDurationInput
        label={props.label}
        value={props.value}
        onChange={props.onChange}
      />
    );
  } else {
    return (
      <TextField
        label={props.label}
        type={type}
        name={props.name}
        required={props.required}
        fullWidth={props.fullWidth}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    );
  }
}
