import { MovieActionTypes, RECEIVE_MOVIES, REQUEST_MOVIES } from "./MovieTypes";

import { MovieDto } from "../../dto/movie/MovieDto";
import { Reducer } from "redux";

export interface MovieState {
  movies: MovieDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: MovieState = {
  movies: [],
  loading: false,
  loaded: false,
};

const MovieReducer: Reducer<MovieState, MovieActionTypes> = (
  state: MovieState = initialState,
  action: MovieActionTypes
) => {
  switch (action.type) {
    case REQUEST_MOVIES:
      return { ...state, loading: true };
    case RECEIVE_MOVIES:
      const movies = action.payload;
      return { movies: [...movies], loaded: true, loading: false };
    default:
      return state;
  }
};

export default MovieReducer;
