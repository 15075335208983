import { ChangeEvent, useCallback } from "react";
import { Grid, GridProps, TextField } from "@material-ui/core";

import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import CustomCheckbox from "../../../common/form/CustomCheckbox";
import CustomSpacingGrid from "../../../common/layout/CustomSpacingGrid";
import DateField from "../../../common/form/DateField";
import FormSection from "../../common/FormSection";
import { MovieBasicDetailsDto } from "../../../../dto/movie/MovieDto";
import TextFieldArray from "../../../common/form/TextFieldArray";
import useMovieAutocomplete from "../../../common/hooks/UseMovieAutocomplete";

interface MovieBasicDetailsFormProps {
  movie: MovieBasicDetailsDto;
  setMovie: (movie: MovieBasicDetailsDto) => void;
}

export default function MovieBasicDetailsForm({
  movie,
  setMovie,
  ...props
}: MovieBasicDetailsFormProps & GridProps) {
  const optionsGenerator = useMovieAutocomplete();

  const setName = useCallback(
    (name: string) => {
      setMovie({ ...movie, name });
    },
    [movie, setMovie]
  );

  const setWorkingTitle = useCallback(
    (workingTitle: string) => {
      setMovie({ ...movie, workingTitle });
    },
    [movie, setMovie]
  );

  const setReleaseTitle = useCallback(
    (releaseTitle: string) => {
      setMovie({ ...movie, releaseTitle });
    },
    [movie, setMovie]
  );

  const setLanguage = useCallback(
    (language: string) => {
      setMovie({ ...movie, language });
    },
    [movie, setMovie]
  );

  const setGenre = useCallback(
    (genre: string) => {
      setMovie({ ...movie, genre });
    },
    [movie, setMovie]
  );

  const setFormat = useCallback(
    (format: string[]) => {
      setMovie({ ...movie, format });
    },
    [movie, setMovie]
  );

  const setReleaseDate = useCallback(
    (releaseDate?: Date) => {
      setMovie({ ...movie, releaseDate });
    },
    [movie, setMovie]
  );

  const setSynopsis = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setMovie({ ...movie, synopsis: e.target.value });
    },
    [movie, setMovie]
  );

  const setSourceAvailability = useCallback(
    (sourceAvailability: boolean) => {
      setMovie({ ...movie, sourceAvailability });
    },
    [movie, setMovie]
  );

  return (
    <FormSection label="Movie Details" {...props}>
      <CustomSpacingGrid container horizontalSpacing={2} verticalSpacing={1}>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Name"
            options={optionsGenerator.name}
            freeSolo
            inputValue={movie.name}
            onInputChange={setName}
            type="text"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Working Title"
            options={optionsGenerator.workingTitle}
            freeSolo
            required={false}
            inputValue={movie.workingTitle}
            onInputChange={setWorkingTitle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Release Title"
            options={optionsGenerator.releaseTitle}
            freeSolo
            required={false}
            inputValue={movie.releaseTitle}
            onInputChange={setReleaseTitle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            options={optionsGenerator.language}
            freeSolo
            label="Language"
            type="text"
            inputValue={movie.language}
            onInputChange={setLanguage}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Genre"
            type="text"
            options={optionsGenerator.genre}
            freeSolo
            inputValue={movie.genre}
            onInputChange={setGenre}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DateField
            label="Release Date"
            fullWidth
            value={movie.releaseDate}
            onChange={setReleaseDate}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            label="Synopsis"
            type="text"
            variant="standard"
            fullWidth
            value={movie.synopsis}
            onChange={setSynopsis}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCheckbox
            checked={movie.sourceAvailability}
            onChange={(e) => setSourceAvailability(e.target.checked)}
            name="sourceAvailability"
            label="Source Availability"
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldArray
            label="Format"
            type="text"
            name="format"
            fullWidth
            options={optionsGenerator.format}
            freeSolo
            value={movie.format ?? []}
            onUpdate={setFormat}
          />
        </Grid>
      </CustomSpacingGrid>
    </FormSection>
  );
}
