import { RECEIVE_REMARK, REQUEST_REMARK, RemarkActionTypes } from "./RemarkTypes";

import { Api } from "../../utils/Api";
import { Notification } from "../../utils/Notification";
import { RemarkDto } from "../../dto/purchase/remarks/RemarkDto";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";
import { isBlockingError } from "../../dto/common/AppErrorDto";

const requestRemark = (): RemarkActionTypes => ({
  type: REQUEST_REMARK,
});

const receiveRemark = (remarks: RemarkDto[]): RemarkActionTypes => ({
  type: RECEIVE_REMARK,
  payload: remarks,
});

const getAllRemarksFromApi = async (purchaseId: string, collectionType: string): Promise<RemarkDto[]> => {
  try {
    const remarks = await Api().get<RemarkDto[]>(`/${collectionType}/${purchaseId}/remarks`);
    return remarks.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to get all remarks for this purchase");
    return [];
  }
};

const addRemarkApi = async (
  collectionType: string,
  purchaseId: string,
  remarkDto: RemarkDto
): Promise<RemarkDto | undefined> => {
  try {
    const remarks = await Api().post<RemarkDto>(`/${collectionType}/${purchaseId}/remarks`, remarkDto);
    Notification.showSuccess("Created remark successfully");
    return remarks.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to add new remark");
    if (isBlockingError(err)) {
      throw err;
    }
    return undefined;
  }
};

const updateRemarkApi = async (
  collectionType: string,
  purchaseId: string,
  remarkDto: RemarkDto,
  remarkId: string
): Promise<RemarkDto> => {
  try {
    const remarks = await Api().put<RemarkDto>(
      `/${collectionType}/${purchaseId}/remarks/${remarkId}`,
      remarkDto
    );
    Notification.showSuccess("Updated remark successfully");
    return remarks.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to update remark");
    throw err;
  }
};

const deleteRemarkApi = async (
  collectionType: string,
  purchaseId: string,
  remarkId: string
): Promise<void> => {
  try {
    const remarks = await Api().delete<void>(
      `/${collectionType}/${purchaseId}/remarks/${remarkId}`
    );
    Notification.showSuccess("Delete remark successfully");
    return remarks.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to delete remark");
    throw err;
  }
};

export const getRemarkDetailsApi = async (
  collectionType: string,
  purchaseId: string,
  remarkId: string
): Promise<RemarkDto> => {
  try {
    const remark = await Api().get<RemarkDto>(`/${collectionType}/${purchaseId}/remarks/${remarkId}`);
    return remark.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to get remark");
    throw err;
  }
};

export const getAllRemarks =
  (force = false, purchaseId: string, collectionType: string): ThunkAction<void, RootState, unknown, RemarkActionTypes> =>
  async (dispatch, getState) => {
    const { remark } = getState();
    if (remark.loading || (!force && remark.loaded)) {
      return;
    }
    dispatch(requestRemark());
    const remarks = await getAllRemarksFromApi(purchaseId, collectionType);
    dispatch(receiveRemark(remarks));
  };

export const addNewRemark =
  (
    collectionType: string,
    remarkDto: RemarkDto,
    purchaseId: string
  ): ThunkAction<
    Promise<RemarkDto | undefined>,
    RootState,
    unknown,
    RemarkActionTypes
  > =>
  async (dispatch) => {
    const remarks = await addRemarkApi(collectionType, purchaseId, remarkDto);
    return Promise.resolve(remarks);
  };

export const updateRemark =
  (
    collectionType: string,
    purchaseId: string,
    remarkDto: RemarkDto,
    remarkId: string
  ): ThunkAction<
    Promise<RemarkDto>,
    RootState,
    unknown,
    RemarkActionTypes
  > =>
  async (dispatch) => {
    const remarks = await updateRemarkApi(collectionType, purchaseId, remarkDto, remarkId);
    return Promise.resolve(remarks);
  };

export const deleteRemark =
  (
    collectionType: string,
    purchaseId: string,
    remarkId: string
  ): ThunkAction<
    void,
    RootState,
    unknown,
    RemarkActionTypes
  > =>
  async (dispatch) => {
    const remarks = await deleteRemarkApi(collectionType, purchaseId, remarkId);
    return Promise.resolve(remarks);
  };
