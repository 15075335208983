import { AuthContextProps, withAuthContext } from "../WithAppContext";
import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Api } from "../../../utils/Api";
import { Notification } from "../../../utils/Notification";

const Logout = withRouter(
  withAuthContext(
    ({ history, auth, updateAuth }: AuthContextProps & RouteComponentProps) => {
      useEffect(() => {
        if (!auth.user) {
          Notification.showSuccess("Logged Out");
          history.push("/");
        } else {
          Api()
            .post("/auth/logout")
            .then(updateAuth)
            .catch((err) => {
              Notification.showApiError(err, "Unable to logout");
            });
        }
      }, [auth, updateAuth, history]);

      return <div>Logging Out...</div>;
    }
  )
);

export default Logout;
