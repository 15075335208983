import { Button, Paper, Typography, makeStyles } from "@material-ui/core";
import { MovieBasicDetailsDto, MovieDto } from "../../../../dto/movie/MovieDto";
import { addMovie, updateMovie } from "../../../../store/movie/MovieAction";
import { useCallback, useEffect, useState } from "react";

import { CrewDto } from "../../../../dto/movie/CrewDto";
import CustomForm from "../../../common/form/CustomForm";
import FormButtonContainer from "../../common/FormButtonContainer";
import { MovieActionTypes } from "../../../../store/movie/MovieTypes";
import MovieBasicDetailsForm from "./MovieBasicDetailsForm";
import MovieCrewForm from "./MovieCrewForm";
import { RootState } from "../../../../store";
import { SongDto } from "../../../../dto/movie/SongDto";
import SongsForm from "./SongsForm";
import { StringUtils } from "../../../../utils/StringUtils";
import SubmitButton from "../../../common/button/SubmitButton";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3),
  },
  crewContainer: {
    margin: theme.spacing(5, 0),
  },
}));

const DefaultMovieBasicDetails = (movie?: MovieDto): MovieBasicDetailsDto => ({
  name: movie?.name ?? "",
  releaseTitle: movie?.releaseTitle ?? "",
  workingTitle: movie?.workingTitle ?? "",
  format: movie?.format ?? [],
  genre: movie?.genre ?? "",
  language: movie?.language ?? "",
  synopsis: movie?.synopsis ?? "",
  sourceAvailability: movie?.sourceAvailability ?? false,
});

interface MovieFormProps {
  movie?: MovieDto;
}

const MovieForm = ({ movie }: MovieFormProps) => {
  const classes = useStyles();

  const [movieBasicDetails, setMovieBasicDetails] = useState(
    DefaultMovieBasicDetails()
  );
  const [crew, setCrew] = useState<CrewDto[]>([]);
  const [songs, setSongs] = useState<SongDto[]>([]);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, MovieActionTypes>>();

  const history = useHistory();

  const submitMovie = async () => {
    const newMovie: MovieDto = {
      name: movieBasicDetails.name.trim(),
      workingTitle: StringUtils.formatFieldValue(
        movieBasicDetails.workingTitle
      ),
      releaseTitle: StringUtils.formatFieldValue(
        movieBasicDetails.releaseTitle
      ),
      synopsis: StringUtils.formatFieldName(movieBasicDetails.synopsis),
      language: StringUtils.formatFieldValue(movieBasicDetails.language),
      genre: StringUtils.formatFieldValue(movieBasicDetails.genre),
      format: movieBasicDetails.format,
      releaseDate: movieBasicDetails.releaseDate ?? new Date(),
      sourceAvailability: movieBasicDetails.sourceAvailability,
      crew: crew,
      songs,
    };
    if (movie && movie.id) {
      await dispatch(updateMovie(movie.id, newMovie));
      history.push(`/portal/movies/${movie.id}`);
    } else {
      await dispatch(addMovie(newMovie));
      history.push("/portal/movies");
    }
  };

  const resetForm = useCallback(() => {
    setMovieBasicDetails(DefaultMovieBasicDetails(movie));
    setCrew(movie?.crew ?? []);
    setSongs(movie?.songs ?? []);
  }, [movie]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Paper elevation={2} className={classes.container}>
      <Typography variant="h5">
        {movie ? "Update Movie" : "Add Movie"}
      </Typography>
      <CustomForm onSubmit={submitMovie}>
        <MovieBasicDetailsForm
          movie={movieBasicDetails}
          setMovie={setMovieBasicDetails}
        />
        <MovieCrewForm
          crew={crew}
          setCrew={setCrew}
          className={classes.crewContainer}
        />

        <SongsForm songs={songs} onUpdate={setSongs} />
        <FormButtonContainer>
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {movie ? "Update movie" : "Add movie"}
          </SubmitButton>
          <Button
            type="button"
            variant="text"
            color="secondary"
            size="large"
            onClick={resetForm}
          >
            RESET
          </Button>
        </FormButtonContainer>
      </CustomForm>
    </Paper>
  );
};

export default MovieForm;
