import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

import { FormStateConsumer } from "../form/CustomForm";

const useStyles = makeStyles((theme) => ({
  progress: {
    marginRight: theme.spacing(2),
  },
}));

export default function SubmitButton(props: ButtonProps) {
  const classes = useStyles();
  return (
    <FormStateConsumer>
      {({ disabled }) => (
        <Button {...props} disabled={props.disabled || disabled}>
          {disabled ? (
            <>
              <CircularProgress
                size={24}
                color="primary"
                className={classes.progress}
              />{" "}
              Processing...
            </>
          ) : (
            props.children
          )}
        </Button>
      )}
    </FormStateConsumer>
  );
}
