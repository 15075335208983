import { Box, BoxProps, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

import AddButton from "../../common/button/AddButton";
import { Link } from "react-router-dom";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  addButtonContainer: {
    width: "100%",
    marginBottom: theme.spacing(3),
  },
}));

interface AddButtonRowProps {
  label: string;
  route: string;
  addPrevilage?: boolean; 
}

export default function AddButtonRow({
  label,
  route,
  addPrevilage,
  ...props
}: AddButtonRowProps & BoxProps) {
  const classes = useStyles();
  const [addEnable, setAddEnable] = useState(true);

  useEffect(() => {
    if(typeof(addPrevilage) === 'boolean') {
      setAddEnable(addPrevilage);
    }
  }, [addPrevilage]);

  return (
    <Box
      {...props}
      display="flex"
      justifyContent="flex-end"
      className={classNames(classes.addButtonContainer, props.className)}
    >
      {addEnable && (
        <Link to={route}>
          <AddButton label={label} />
        </Link>
      )}
    </Box>
  );
}
