import {
  InternetType,
  PlatformType,
  TelevisionType,
} from "../../../../dto/sale/SaleDto";

import InternetForm from "./InternetForm";
import { SalePlatformDetailsRequestDto } from "../../../../dto/sale/SaleRequestDto";
import TelevisionForm from "./platform/TelevisionForm";

interface PlatformSpecificFormProps {
  platform: PlatformType;
  sales: SalePlatformDetailsRequestDto;
  setSales: (sale: SalePlatformDetailsRequestDto) => void;
}

export default function PlatformSpecificForm({
  platform,
  sales,
  setSales,
}: PlatformSpecificFormProps) {
  const setTelevision = (television: TelevisionType) =>
    setSales({ ...sales, television });

  const setInternet = (internet: InternetType) =>
    setSales({ ...sales, internet });

  switch (platform) {
    case PlatformType.Television:
      return (
        <TelevisionForm
          television={sales.television}
          setTelevision={setTelevision}
        />
      );
    case PlatformType.Internet:
      return (
        <InternetForm internet={sales.internet} setInternet={setInternet} />
      );
    default:
      return null;
  }
}
