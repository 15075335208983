import {
  PurchaseStringProperties,
  selectPurchaseValuesByProperty,
} from "../../dto/purchase/PurchaseDto";

import { RootState } from "..";
import { createSelector } from "reselect";

const selectPurchases = (state: RootState) => state.purchase.purchases;
const selectProperty = (_: RootState, property: PurchaseStringProperties) =>
  property;

export const makeSelectPurchasePropertyValues = () => {
  const selectPurchasePropertyValues = createSelector(
    selectPurchases,
    selectProperty,
    selectPurchaseValuesByProperty
  );
  return selectPurchasePropertyValues;
};
