import { useEffect, useState } from "react";

import { Api } from "../../../../utils/Api";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { GetPrevilage } from "../../../../utils/Previlage";
import { Notification } from "../../../../utils/Notification";
import { SaleDto } from "../../../../dto/sale/SaleDto";
import { SalesReportFields } from "./SalesReportFields";
import SalesReportResult from "./SalesReportResult";
import useCountrySetAutocomplete from "../../../common/hooks/UseCountrySetAutocomplete";
import useMovieAutocomplete from "../../../common/hooks/UseMovieAutocomplete";
import useRightGroupsAutocomplete from "../../../common/hooks/UseRightGroupsAutocomplete";
import useSaleAutocomplete from "../../../common/hooks/UseSaleAutocomplete";

interface SalesReportProps {
  filters: FilterDto[];
  setFilters?: (filters: FilterDto[]) => void;
  tableId?: string;
  title?: string;
}

export default function SalesReport({
  filters,
  setFilters,
  tableId,
  title,
}: SalesReportProps) {
  const [sales, setSales] = useState<SaleDto[]>([]);
  const salesReportPrevilage = GetPrevilage('Reports', 'Sales');

  useEffect(() => {
    if(salesReportPrevilage) {
      Api()
        .post<SaleDto[]>("/reports/sales", { filters })
        .then((res) => {
          setSales(res.data);
        })
        .catch((err) => {
          Notification.showApiError(err, "Unable to get sales report");
        });
    }
  }, [filters, salesReportPrevilage]);

  const movieOptions = useMovieAutocomplete();
  const saleOptions = useSaleAutocomplete();
  const rightOptions = useRightGroupsAutocomplete().rights;
  const countrySetOptions = useCountrySetAutocomplete();

  return (
    <>
      {salesReportPrevilage ? (
        <SalesReportResult
          tableId={tableId}
          title={title}
          sales={sales}
          filters={filters}
          setFilters={setFilters}
          fields={SalesReportFields(
            movieOptions,
            saleOptions,
            rightOptions,
            countrySetOptions
          )}
        />
      ) : (
        null
      )}
    </>
  );
}
