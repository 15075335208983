import { Currency } from "./Currency";
import { PriceDto } from "./PriceDto";

export class PriceRequestDto {
  amount: string = "";
  currency: Currency | null = null;

  constructor(priceDto?: PriceDto) {
    if (priceDto) {
      this.amount = `${priceDto.amount ?? ""}`;
      this.currency = priceDto.currency;
    }
  }
}
