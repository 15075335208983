import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { OptionType } from "../../../dto/report/FilterField";
import { getAllRights } from "../../../store/right/RightAction";
import { selectRightGroups } from "../../../store/right/RightSelector";

export default function useRightGroupsAutocomplete() {
  const rightGroups = useSelector(selectRightGroups);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllRights());
  }, [dispatch]);

  return useMemo(
    () => ({
      rightGroups,
      rights: rightGroups
        .flatMap((rightGroup) => rightGroup.rights)
        .map(
          (rightType): OptionType => ({
            label: rightType.name,
            name: rightType.id,
          })
        ),
    }),
    [rightGroups]
  );
}
