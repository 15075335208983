import { Box, BoxProps, Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

import BackTo from "../../common/button/BackTo";
import { Link } from "react-router-dom";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
}));

interface EditItemRowProps {
  backRoute: string;
  backLabel: string;
  editRoute?: string;
  editLabel?: string;
  editPrevilage?: boolean;
}

export default function EditItemRow({
  backRoute,
  backLabel,
  editRoute,
  editLabel,
  editPrevilage,
  ...props
}: EditItemRowProps & BoxProps) {
  const classes = useStyles();
  const [editEnable, setEditEnable] = useState(true);

  useEffect(() => {
    if(typeof(editPrevilage) === 'boolean') {
      setEditEnable(editPrevilage);
    }
  }, [editPrevilage]);

  return (
    <Box
      {...props}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classNames(classes.container, props.className)}
    >
      <BackTo name={backLabel} path={backRoute} />
      {editRoute && editEnable && (
        <Box display="flex" justifyContent="space-between">
          <Link to={editRoute}>
            <Button variant="contained" size="medium" color="primary">
              Edit {editLabel}
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
}
