import { ComponentType, useState } from "react";
import { NavLink, Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import logo from "../../../assets/img/aplogo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100%",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appLogo: {
      width: "50%",
      padding: "5px",
      margin: "auto",
      display: "block",
    },
    appMenus: {
      flex: 1,
    },
    appCopyright: {
      padding: 20,
      textAlign: "center",
    },
    appBar: {
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
      boxShadow:
        "0px 0px 2px -1px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%)",
      borderBottom: `1px solid ${theme.palette.border.primary}`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    appBarContent: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: theme.spacing(0.5),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    toolbarActiveElement: {
      backgroundColor: theme.palette.primary.light,
      display: "block",
      borderLeft: `5px solid ${theme.palette.primary.main}`,
      "&, & svg": {
        color: theme.palette.primary.main,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      width: `calc(100% - ${drawerWidth}px)`,
      maxWidth: 1400,
      padding: theme.spacing(3),
      margin: "auto",
    },
  })
);

export type TabType = {
  id: string;
  icon: ComponentType;
  label: string;
  component: ComponentType;
  isVisible?: boolean;
};

export interface ResponsiveDrawerProps {
  tabs: TabType[];
  routePrefix: string;
  defaultRoute: string;
}

export default function ResponsiveDrawer({
  children,
  tabs,
  routePrefix,
  defaultRoute,
}: ResponsiveDrawerProps & RouteProps) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const closeDrawer = () => setMobileOpen(false);

  const drawer = (
    <>
      <div className={classes.toolbar}>
        <img src={logo} alt="logo" className={classes.appLogo} />
      </div>
      <Divider />
      <List className={classes.appMenus}>
        {tabs.map((tab, index) => (
          <>
            {tab.isVisible ? (
              <NavLink
                key={index}
                to={routePrefix + tab.id}
                activeClassName={classes.toolbarActiveElement}
                onClick={closeDrawer}
              >
                <ListItem button>
                  <ListItemIcon>
                    <tab.icon />
                  </ListItemIcon>
                  <ListItemText primary={tab.label} />
                </ListItem>
              </NavLink>
            ) : null}
          </>
        ))}
      </List>
      <Divider />
      <div className={classes.appCopyright}>AP International films © 2021</div>
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" noWrap className={classes.appBarContent}>
            {children}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          {tabs.map((tab, index) => (
            <Route
              key={index}
              path={routePrefix + tab.id}
              component={tab.component}
            ></Route>
          ))}
          <Route
            path="*"
            render={() => <Redirect to={routePrefix + defaultRoute} />}
          ></Route>
        </Switch>
      </main>
    </div>
  );
}
