import { Box, Grid, makeStyles } from "@material-ui/core";
import { ChangeEvent, HTMLProps, SyntheticEvent, useCallback } from "react";

import CustomCheckbox from "../../common/form/CustomCheckbox";
import { RightTypeWithoutGroup } from "../../../dto/right/RightTypeDto";
import useRightGroupsAutocomplete from "../../common/hooks/UseRightGroupsAutocomplete";

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(0.5),
  },
  rightGroupLabel: {
    "& .MuiFormControlLabel-label": {
      fontWeight: "bold",
      fontSize: theme.typography.body2.fontSize,
    },
  },
  rightTypeLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.typography.body2.fontSize,
    },
  },
}));

const includesAll = (value: string[], compare: RightTypeWithoutGroup[]) => {
  if (value.length === 0) {
    return false;
  }
  const compareIds = compare.map((c) => c.id);
  return !compareIds.some((cId) => !value.includes(cId));
};

const includesSome = (value: string[], compare: RightTypeWithoutGroup[]) => {
  if (value.length === 0) {
    return false;
  }
  const compareIds = compare.map((c) => c.id);
  const excludesOne = compareIds.some((cId) => !value.includes(cId));
  const includesOne = compareIds.some((cId) => value.includes(cId));
  return includesOne && excludesOne;
};

interface RightTypesInputFormProps {
  value: string[];
  setValue: (value: string[]) => void;
}

export default function RightTypesInputForm({
  value,
  setValue,
  ...props
}: RightTypesInputFormProps & HTMLProps<HTMLDivElement>) {
  const classes = useStyles();

  const rightGroups = useRightGroupsAutocomplete().rightGroups.filter(
    (rg) => rg.rights.length > 0
  );

  const stopPropagation = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
  }, []);

  const onGroupCheckedChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const rightGroupId = event.target.name;
      const rightGroup = rightGroups.find((rg) => rg.id === rightGroupId);
      if (rightGroup) {
        const rightIds = rightGroup.rights.map((r) => r.id);
        const newValue = [...value].filter((v) => !rightIds.includes(v));
        if (checked) {
          newValue.push(...rightIds);
        }
        setValue(newValue);
      }
    },
    [value, setValue, rightGroups]
  );

  const onCheckedChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const rightTypeId = event.target.name;
      let newValue = [...value].filter((v) => v !== rightTypeId);
      if (checked) {
        newValue.push(rightTypeId);
      }
      setValue(newValue);
    },
    [value, setValue]
  );

  return (
    <div {...props}>
      {rightGroups.map((rightGroup) => (
        <Grid container spacing={1} key={rightGroup.id} className={classes.row}>
          <Grid item xs={12} md={4} lg={3}>
            <CustomCheckbox
              checked={includesAll(value, rightGroup.rights)}
              indeterminate={includesSome(value, rightGroup.rights)}
              onClick={stopPropagation}
              onFocus={stopPropagation}
              onChange={onGroupCheckedChange}
              name={rightGroup.id}
              label={rightGroup.name}
              color="primary"
              className={classes.rightGroupLabel}
              size="small"
              title="Select All"
            />
          </Grid>

          <Grid item xs={12} md={8} lg={9}>
            <Box display="flex" flexWrap="wrap">
              {rightGroup.rights.map((rightType) => (
                <div key={rightType.id}>
                  <CustomCheckbox
                    checked={value.includes(rightType.id)}
                    onChange={onCheckedChange}
                    name={rightType.id}
                    label={rightType.name}
                    className={classes.rightTypeLabel}
                    size="small"
                  />
                </div>
              ))}
            </Box>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
