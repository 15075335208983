import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { ChangeEvent, Ref, useCallback } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

interface CustomAutocompleteProps {
  label: string;
  variant?: TextFieldProps["variant"];
  type?: TextFieldProps["type"];
  required?: TextFieldProps["required"];
  autoFocus?: TextFieldProps["autoFocus"];
  inputRef?: Ref<any>;
  onChange?: (value: any) => void;
  onInputChange?: (value: string) => void;
}

export default function CustomAutocomplete<T>({
  label,
  variant,
  type,
  required,
  autoFocus,
  inputRef,
  onChange,
  onInputChange,
  ...props
}: Omit<
  AutocompleteProps<
    T,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >,
  "renderInput" | "onChange" | "onInputChange"
> &
  CustomAutocompleteProps) {
  // Called when an option is selected
  const onCustomChange = useCallback(
    (event: ChangeEvent<{}>, v: any) => (event ? onChange?.(v) : null),
    [onChange]
  );

  // Called when the text changes
  const onCustomInputChange = useCallback(
    (event: ChangeEvent<{}>, v: string) => (event ? onInputChange?.(v) : null),
    [onInputChange]
  );

  return (
    <Autocomplete
      {...props}
      inputValue={props.inputValue}
      value={props.value ?? null}
      onChange={onCustomChange}
      onInputChange={onCustomInputChange}
      aria-label={label}
      autoHighlight={props.autoHighlight ?? true}
      selectOnFocus={props.selectOnFocus ?? true}
      openOnFocus={props.openOnFocus ?? true}
      fullWidth={props.fullWidth ?? true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          type={type}
          required={required ?? true}
          autoFocus={autoFocus}
          inputRef={inputRef}
          inputProps={{
            ...params.inputProps,
            autoComplete: "custom-autocomplete",
          }}
        />
      )}
    />
  );
}
