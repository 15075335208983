import {
  Button,
  Paper,
  PaperProps,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";

import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import CustomForm from "../../../common/form/CustomForm";
import FormButtonContainer from "../../common/FormButtonContainer";
import { Notification } from "../../../../utils/Notification";
import { RightActionTypes } from "../../../../store/right/RightTypes";
import { RightGroupWithRights } from "../../../../dto/right/RightGroupDto";
import { RightTypeWithoutGroup } from "../../../../dto/right/RightTypeDto";
import { RootState } from "../../../../store";
import SubmitButton from "../../../common/button/SubmitButton";
import { ThunkDispatch } from "redux-thunk";
import classNames from "classnames";
import { updateRightTypes } from "../../../../store/right/RightAction";
import { useDispatch } from "react-redux";
import useRightGroupsAutocomplete from "../../../common/hooks/UseRightGroupsAutocomplete";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

const DefaultRightGroup = (
  rightGroups: RightGroupWithRights[],
  rightType: RightTypeWithoutGroup
) =>
  rightGroups.find((rightGroup) =>
    rightGroup.rights.some((right) => right.id === rightType.id)
  );

const RightGroupLabel = (rightGroup: RightGroupWithRights) => rightGroup.name;
const RightGroupCompare = (
  r1: RightGroupWithRights,
  r2: RightGroupWithRights
) => r1.id === r2.id;

interface RightTypeMoveFormProps {
  rightType: RightTypeWithoutGroup;
  onMoveRight: () => void;
  onClose?: () => void;
}

export default function RightTypeMoveForm({
  rightType,
  onMoveRight,
  onClose,
  ...props
}: RightTypeMoveFormProps & PaperProps) {
  const classes = useStyles();

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, RightActionTypes>>();
  const [rightGroup, setRightGroup] = useState<RightGroupWithRights>();
  const rightGroups = useRightGroupsAutocomplete().rightGroups;

  const submitRight = async () => {
    if (!rightGroup) {
      Notification.showError("Please select a primary right");
      return;
    }
    if (rightGroup.id !== DefaultRightGroup(rightGroups, rightType)?.id) {
      await dispatch(updateRightTypes(rightGroup.id, [rightType]));
      onMoveRight();
      Notification.showSuccess("Moved secondary rights");
    }
    onClose?.();
  };

  const resetForm = useCallback(() => {
    setRightGroup(DefaultRightGroup(rightGroups, rightType));
  }, [rightType, rightGroups]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Paper
      elevation={2}
      {...props}
      className={classNames(classes.container, props.className)}
    >
      <CustomForm onSubmit={submitRight}>
        <Typography variant="body1">Move {rightType.name} to</Typography>
        <CustomAutocomplete
          label="Primary Right"
          options={rightGroups}
          getOptionLabel={RightGroupLabel}
          getOptionSelected={RightGroupCompare}
          value={rightGroup ?? null}
          onChange={setRightGroup}
        />
        <FormButtonContainer>
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Move Secondary Right
          </SubmitButton>
          <Button
            type="button"
            variant="text"
            color="secondary"
            size="large"
            onClick={resetForm}
          >
            RESET
          </Button>
        </FormButtonContainer>
      </CustomForm>
    </Paper>
  );
}
