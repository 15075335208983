import { useEffect, useState } from "react";

import BackTo from "../../../common/button/BackTo";
import { SaleDto } from "../../../../dto/sale/SaleDto";
import SaleForm from "../salesForm/SalesForm";
import { getSaleDetailsApi } from "../../../../store/sale/SaleAction";
import { useParams } from "react-router-dom";

const SaleEdit = () => {
  const { saleId } = useParams<{ saleId: string }>();
  const [sale, setSale] = useState<SaleDto>();
  const [error, setError] = useState(false);

  useEffect(() => {
    getSaleDetailsApi(saleId)
      .then((sale) => setSale(sale))
      .catch(() => setError(true));
  }, [saleId]);

  return (
    <div>
      <BackTo name="Sale Detail" />
      {sale ? <SaleForm sale={sale} /> : null}
      {error ? <p>Failed to load sale</p> : null}
    </div>
  );
};

export default SaleEdit;
