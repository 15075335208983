import { Box, Grid, makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";

import AddButton from "../../common/button/AddButton";
import DetailCard from "../common/DetailCard";
import { RemarkDto } from "../../../dto/purchase/remarks/RemarkDto";
import RemarkFormPopup from "./RemarkFormPopup";
import RemarkItem from "./RemarkItem";

const useStyle = makeStyles((theme) => ({
  addButtonContainer: {
    marginTop: theme.spacing(2),
  },
}));

interface RemarkListAndUpdateProps<T> {
  name: T;
  label: string;
  remarks?: RemarkDto[];
  onAddComment?: (name: T, remark: RemarkDto) => Promise<void>;
  onDeleteComment?: (remarkId: string) => Promise<void>;
  editPrevilage: boolean;
}

export default function RemarkListAndUpdate<T>({
  name,
  label,
  remarks,
  onAddComment,
  onDeleteComment,
  editPrevilage
}: RemarkListAndUpdateProps<T>) {
  const classes = useStyle();

  const [open, setOpen] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(-1);

  const onOpenForm = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseForm = useCallback(() => {
    setOpen(false);
    setEditIndex(-1);
  }, []);

  const onStartEdit = useCallback(
    (i: number) => {
      if (remarks && i >= 0 && i < remarks.length) {
        setEditIndex(i);
        onOpenForm();
      }
    },
    [remarks, onOpenForm]
  );

  const onAddRemark = useCallback(
    async (remark: RemarkDto) => {
      await onAddComment?.(name, remark);
      onCloseForm();
    },
    [name, onAddComment, onCloseForm]
  );

  const onDelete = useCallback(
    async (remarkId: string) => {
      await onDeleteComment?.(remarkId);
      onCloseForm();
    },
    [onDeleteComment, onCloseForm]
  );

  return (
    <DetailCard title={label}>
      <RemarkFormPopup
        label={label}
        open={open}
        onClose={onCloseForm}
        onAddRemark={onAddRemark}
        remark={editIndex >= 0 ? remarks?.[editIndex] : undefined}
      />

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Grid container spacing={1}>
          {remarks?.map((remark, i) => (
            <Grid item xs={12} key={i}>
              <RemarkItem
                remark={remark}
                index={i}
                onEdit={onStartEdit}
                onDelete={onDelete}
              />
            </Grid>
          ))}
        </Grid>
        {editPrevilage && (
          <div className={classes.addButtonContainer}>
            <AddButton onClick={onOpenForm} label={label} />
          </div>
        )}
      </Box>
    </DetailCard>
  );
}
