import {
  PurchaseActionTypes,
  RECEIVE_PURCHASES,
  REQUEST_PURCHASES,
} from "./PurchaseTypes";

import { Api } from "../../utils/Api";
import { Notification } from "../../utils/Notification";
import { PurchaseDto } from "../../dto/purchase/PurchaseDto";
import { PurchaseMediaRequestDto } from "../../dto/purchase/PurchaseMediaRequestDto";
import { PurchaseRequestDto } from "../../dto/purchase/PurchaseRequestDto";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";
import { isBlockingError } from "../../dto/common/AppErrorDto";

const requestPurchases = (): PurchaseActionTypes => ({
  type: REQUEST_PURCHASES,
});

const receivePurchases = (purchases: PurchaseDto[]): PurchaseActionTypes => ({
  type: RECEIVE_PURCHASES,
  payload: purchases,
});

const getAllPurchasesFromApi = async (): Promise<PurchaseDto[]> => {
  try {
    const purchases = await Api().get<PurchaseDto[]>("/purchases");
    return purchases.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to get all purchases");
    return [];
  }
};

const addPurchaseApi = async (
  purchaseDto: PurchaseRequestDto
): Promise<PurchaseDto | undefined> => {
  try {
    const purchases = await Api().post<PurchaseDto>("/purchases", purchaseDto);
    Notification.showSuccess("Created purchase successfully");
    return purchases.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to add new purchase");
    if (isBlockingError(err)) {
      throw err;
    }
    return undefined;
  }
};

const updatePurchaseApi = async (
  purchaseId: string,
  purchaseDto: PurchaseRequestDto
): Promise<PurchaseDto> => {
  try {
    const purchases = await Api().put<PurchaseDto>(
      `/purchases/${purchaseId}`,
      purchaseDto
    );
      Notification.showSuccess("Updated purchase successfully");
    return purchases.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to update purchase");
    throw err;
  }
};

const deletePurchaseApi = async (
  purchaseId: string
): Promise<PurchaseDto> => {
  try {
    const purchases = await Api().delete<PurchaseDto>(
      `/purchases/${purchaseId}`
    );
    Notification.showSuccess("Purchase deleted successfully");
    return purchases.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to delete purchase");
    throw err;
  }
};

const updatePurchaseMediaApi = async (
  purchaseId: string,
  purchaseDto: PurchaseMediaRequestDto
): Promise<PurchaseDto> => {
  try {
    const purchases = await Api().post<PurchaseDto>(
      `/purchases/${purchaseId}/media`,
      purchaseDto
    );
    return purchases.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to update purchase files");
    throw err;
  }
};

export const getPurchaseDetailsApi = async (
  purchaseId: string
): Promise<PurchaseDto> => {
  try {
    const purchases = await Api().get<PurchaseDto>(`/purchases/${purchaseId}`);
    return purchases.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to get purchase");
    throw err;
  }
};

export const confirmPurchaseApi = async (
  purchaseId: string
): Promise<PurchaseDto> => {
  try {
    const purchases = await Api().put<PurchaseDto>(
      `/purchases/${purchaseId}/confirm`
    );
    return purchases.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to confirm purchase");
    throw err;
  }
};

export const getAllPurchases =
  (force = false): ThunkAction<void, RootState, unknown, PurchaseActionTypes> =>
  async (dispatch, getState) => {
    const { purchase } = getState();
    if (purchase.loading || (!force && purchase.loaded)) {
      return;
    }
    dispatch(requestPurchases());
    const purchases = await getAllPurchasesFromApi();
    dispatch(receivePurchases(purchases));
  };

export const addNewPurchase =
  (
    purchaseDto: PurchaseRequestDto
  ): ThunkAction<
    Promise<PurchaseDto | undefined>,
    RootState,
    unknown,
    PurchaseActionTypes
  > =>
  async (dispatch) => {
    const purchase = await addPurchaseApi(purchaseDto);
    dispatch(getAllPurchases(true));
    return Promise.resolve(purchase);
  };

export const updatePurchase =
  (
    purchaseId: string,
    purchaseDto: PurchaseRequestDto
  ): ThunkAction<
    Promise<PurchaseDto>,
    RootState,
    unknown,
    PurchaseActionTypes
  > =>
  async (dispatch) => {
    const purchase = await updatePurchaseApi(purchaseId, purchaseDto);
    dispatch(getAllPurchases(true));
    return Promise.resolve(purchase);
  };

export const deletePurchase =
  (
    purchaseId: string
  ): ThunkAction<
    Promise<PurchaseDto>,
    RootState,
    unknown,
    PurchaseActionTypes
  > =>
  async (dispatch) => {
    const purchase = await deletePurchaseApi(purchaseId);
    dispatch(getAllPurchases(true));
    return Promise.resolve(purchase);
  };

export const updatePurchaseMedia =
  (
    purchaseId: string,
    purchaseDto: PurchaseMediaRequestDto
  ): ThunkAction<
    Promise<PurchaseDto>,
    RootState,
    unknown,
    PurchaseActionTypes
  > =>
  async (dispatch) => {
    const purchase = await updatePurchaseMediaApi(purchaseId, purchaseDto);
    dispatch(getAllPurchases(true));
    return Promise.resolve(purchase);
  };
