import { AgreementExpiryDto } from "../common/AgreementExpirtyDto";
import { MediaDto } from "../common/MediaDto";
import { MovieDto } from "../movie/MovieDto";
import { OptionWithLabelType } from "../report/FilterField";
import { PriceDto } from "../common/PriceDto";
import { RemarkDto } from "../purchase/remarks/RemarkDto";
import { RightDto } from "../right/RightDto";
import { SalesStatus } from "./SalesStatus";

export enum PlatformType {
  PayPerUse = "Pay Per Use",
  Television = "Television",
  Airlines = "Airlines",
  TVOD = "TVOD",
  SVOD = "SVOD",
  Internet = "Internet",
  Theatrical = "Theatrical",
}

export const PlatformOptions = [
  PlatformType.Airlines,
  PlatformType.Internet,
  PlatformType.PayPerUse,
  PlatformType.SVOD,
  PlatformType.TVOD,
  PlatformType.Television,
  PlatformType.Theatrical,
];

export enum InternetMediaType {
  Movie = "Movie",
  PlayList = "PlayList",
  Jukebox = "Jukebox",
  Trailers = "Trailers",
  ShortFilms = "Short Films",
}

export const InternetMediaOptions = [
  InternetMediaType.Jukebox,
  InternetMediaType.Movie,
  InternetMediaType.PlayList,
  InternetMediaType.ShortFilms,
  InternetMediaType.Trailers,
];

export enum JukeboxType {
  Clip = "Clip",
  Song = "Song",
}

export const JukeboxTypeOptions: OptionWithLabelType[] = [
  { name: JukeboxType.Clip, label: "Clip" },
  { name: JukeboxType.Song, label: "Song" },
];

export enum JukeboxSongType {
  Audio = "Audio",
  Video = "Video",
}

export const JukeboxSongTypeOptions = [
  JukeboxSongType.Audio,
  JukeboxSongType.Video,
];

export interface Jukebox {
  type?: JukeboxType;
  songType?: JukeboxSongType;
  name?: string;
  videoId?: string;
  itemDuration?: number;
  jukeboxDuration?: number;
}

export interface TelevisionType {
  permittedRuns?: number;
}

export interface InternetType {
  media?: InternetMediaType;
  channelName?: string;
  jukebox?: Jukebox;
}
export interface SaleDto {
  id?: string;
  movie: MovieDto;
  language: string;

  platform: PlatformType;
  customerName: string;
  dateOfPublishing: Date;

  television?: TelevisionType;
  internet?: InternetType;

  agreementDate: Date;
  validFrom: Date;
  agreementExpiry?: AgreementExpiryDto;
  validTill: Date;
  saleAmount: PriceDto;

  rights: RightDto[];
  status: SalesStatus;

  saleAgreement?: MediaDto[];
  saleInvoices?: MediaDto[];
  remarks: RemarkDto[];
  isDeleted: boolean;
}

export interface SaleReportDto extends SaleDto {
  parentId?: string;
}

export type SaleStringProperties =
  | "language"
  | "customerName"
  | "channelName"
  | "jukeboxName"
  | "jukeboxVideoId";

export const selectSaleValuesByProperty = (
  sales: SaleDto[],
  key: SaleStringProperties
) => {
  const values = sales
    .map((sale) => {
      switch (key) {
        case "channelName":
          return sale.internet?.channelName ?? "";
        case "jukeboxName":
          return sale.internet?.jukebox?.name ?? "";
        case "jukeboxVideoId":
          return sale.internet?.jukebox?.videoId ?? "";
        default:
          return sale[key] ?? "";
      }
    })
    .filter((value) => value !== "");
  return Array.from(new Set(values));
};
