import { Button, GridProps, makeStyles } from "@material-ui/core";

import Add from "@material-ui/icons/Add";
import FormSection from "../../common/FormSection";
import { SongDto } from "../../../../dto/movie/SongDto";
import SongForm from "./SongForm";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: theme.spacing(2),
  },
  addIcon: {
    marginRight: theme.spacing(1.5),
  },
}));

interface SongFormProps {
  songs: SongDto[];
  onUpdate: (songs: SongDto[]) => void;
}

export default function SongsForm({
  songs,
  onUpdate,
  ...props
}: SongFormProps & GridProps) {
  const onElementAdd = () => {
    onUpdate([...songs, { name: "", crew: [], genre: "" }]);
  };

  const onElementDelete = (index: number) => {
    const newArray = [...songs];
    newArray.splice(index, 1);
    onUpdate(newArray);
  };

  const onElementUpdate = (index: number, song: SongDto) => {
    const newArray = [...songs];
    newArray[index] = song;
    onUpdate(newArray);
  };

  const classes = useStyles();
  return (
    <FormSection label="Songs" {...props}>
      {songs.map((song, index) => (
        <SongForm
          key={index}
          song={song}
          index={index}
          onUpdateSong={onElementUpdate}
          onDeleteSong={onElementDelete}
        />
      ))}
      <Button
        onClick={onElementAdd}
        variant="outlined"
        className={classes.addButton}
      >
        <Add className={classes.addIcon} /> Add Song
      </Button>
    </FormSection>
  );
}
