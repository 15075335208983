import {
  InternetMediaOptions,
  InternetMediaType,
} from "../../../../dto/sale/SaleDto";

import CustomAutocomplete from "../../../common/form/CustomAutocomplete";

interface ChooseMediaProps {
  media?: InternetMediaType;
  setMedia: (media: InternetMediaType) => void;
  label?: string;
  disabled?: boolean;
}

export function ChooseMedia(props: ChooseMediaProps) {
  return (
    <CustomAutocomplete
      value={props.media ?? null}
      onChange={props.setMedia}
      label={props.label ?? "Media Type"}
      options={InternetMediaOptions}
      disabled={props.disabled}
      required={false}
    />
  );
}
