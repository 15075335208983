import { Grid, GridProps, Paper, makeStyles } from "@material-ui/core";
import { memo, useCallback } from "react";

import AddButton from "../../common/button/AddButton";
import FormSection from "../common/FormSection";
import RightForm from "./RightForm";
import { RightRequestDto } from "../../../dto/right/RightsRequestDto";

const useStyles = makeStyles((theme) => ({
  addbutton: {
    marginTop: theme.spacing(1.5),
  },
  rightRowContainer: {
    marginTop: theme.spacing(2),
  },
  rightRow: {
    padding: theme.spacing(1, 2),
  },
}));

interface RightsFormProps {
  label?: string;
  rights: RightRequestDto[];
  onUpdate: (rights: RightRequestDto[]) => void;
}

function RightsForm({
  label,
  rights,
  onUpdate,
  ...props
}: RightsFormProps & GridProps) {
  const classes = useStyles();

  const onElementAdd = useCallback(() => {
    onUpdate([...rights, { rightTypes: [], countrySet: "", exclusive: false }]);
  }, [rights, onUpdate]);

  const onElementDelete = useCallback(
    (index: number) => {
      const newArray = [...rights];
      newArray.splice(index, 1);
      onUpdate(newArray);
    },
    [rights, onUpdate]
  );

  const onElementUpdate = useCallback(
    (
      index: number,
      key: "rightTypes" | "countrySet" | "exclusive",
      newValue: any
    ) => {
      const newArray = [...rights];
      newArray[index] = {
        ...newArray[index],
        [key]: newValue,
      };
      onUpdate(newArray);
    },
    [rights, onUpdate]
  );

  return (
    <FormSection label={label ?? "Rights"} wrapSection {...props}>
      <Grid container spacing={2}>
        {rights.map((right, index) => (
          <Grid item xs={12} key={index} className={classes.rightRowContainer}>
            <Paper elevation={2} className={classes.rightRow}>
              <RightForm
                right={right}
                index={index}
                onElementUpdate={onElementUpdate}
                onElementDelete={onElementDelete}
              />
            </Paper>
          </Grid>
        ))}
        <Grid item xs={12}>
          <AddButton
            label="Right"
            onClick={onElementAdd}
            className={classes.addbutton}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
}

export default memo(RightsForm);
