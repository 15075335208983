import { useEffect, useMemo } from "react";

import { CountrySetActionTypes } from "../../../store/countrySet/CountrySetTypes";
import { CountrySetStringProperties } from "../../../dto/countrySet/CountrySetDto";
import { RootState } from "../../../store";
import { ThunkDispatch } from "redux-thunk";
import { getAllCountrySets } from "../../../store/countrySet/CountrySetAction";
import useCountrySetProperty from "../../../store/countrySet/useCountrySetProperty";
import { useDispatch } from "react-redux";

export type CountrySetOptions = {
  [key in CountrySetStringProperties]: string[];
};

export default function useCountrySetAutocomplete() {
  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, CountrySetActionTypes>>();

  useEffect(() => {
    dispatch(getAllCountrySets());
  }, [dispatch]);

  const nameOptions = useCountrySetProperty("name");
  const countryOptions = useCountrySetProperty("country");

  return useMemo(
    (): CountrySetOptions => ({
      name: nameOptions,
      country: countryOptions,
    }),
    [nameOptions, countryOptions]
  );
}
