import {
  SaleStringProperties,
  selectSaleValuesByProperty,
} from "../../dto/sale/SaleDto";

import { RootState } from "..";
import { createSelector } from "reselect";

const selectSales = (state: RootState) => state.sale.sales;
const selectProperty = (_: RootState, property: SaleStringProperties) =>
  property;

export const makeSelectSalePropertyValues = () => {
  const selectSalePropertyValues = createSelector(
    selectSales,
    selectProperty,
    selectSaleValuesByProperty
  );
  return selectSalePropertyValues;
};
