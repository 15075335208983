import Table, { Column } from "../../common/layout/Table";

import DetailCard from "../common/DetailCard";
import { GetPrevilage } from "../../../utils/Previlage";
import { RightDto } from "../../../dto/right/RightDto";
import { StringUtils } from "../../../utils/StringUtils";

interface NumberRightDto extends RightDto {
  index: number;
}

const columns: Column<NumberRightDto>[] = [
  { title: "No", field: "index", width: 100 },
  {
    title: "Right Type",
    field: "rightTypes",
    render: (rowData) => rowData.rightTypes.map((rt) => rt.name).join(", "),
  },
  {
    title: "Territory Set Name",
    field: "countrySet",
    render: (rowData) => rowData.countrySet.name,
  },
  {
    title: "Exclusive",
    field: "exclusive",
    render: (rowData) => StringUtils.getBooleanStringValue(rowData.exclusive),
  },
];

interface RightDetailsProps {
  rights: RightDto[];
  label?: string;
}

export default function RightDetails({ label, rights }: RightDetailsProps) {
  const indexedRights = rights.map((right, index) => ({
    ...right,
    index: index + 1,
  }));
  const rightsReportPrevilage = GetPrevilage('Reports', 'Owned Rights');
  
  return (
    <DetailCard title={label ?? "Right"}>
      <Table
        id="right-details"
        title={label ?? "Right"}
        data={indexedRights}
        columns={columns}
        reportPrevilage={rightsReportPrevilage}
      />
    </DetailCard>
  );
}
