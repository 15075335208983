import {
  Grid,
  GridProps,
  GridSpacing,
  Theme,
  makeStyles,
} from "@material-ui/core";

import classNames from "classnames";

const useStyles = makeStyles<Theme, CustomSpacingGridProps>((theme) => ({
  formContainer: {
    width: (props) =>
      `calc(100% + ${theme.spacing(props.horizontalSpacing * 2)}px)`,
    margin: (props) =>
      theme.spacing(-props.verticalSpacing, -props.horizontalSpacing),
    "& > *": {
      padding: (props) =>
        theme.spacing(props.verticalSpacing, props.horizontalSpacing),
    },
  },
}));

interface CustomSpacingGridProps {
  horizontalSpacing: GridSpacing;
  verticalSpacing: GridSpacing;
}

export default function CustomSpacingGrid({
  horizontalSpacing,
  verticalSpacing,
  ...props
}: CustomSpacingGridProps & GridProps) {
  const classes = useStyles({ horizontalSpacing, verticalSpacing });
  return (
    <Grid
      {...props}
      className={classNames(classes.formContainer, props.className)}
    >
      {props.children}
    </Grid>
  );
}
