import { Column } from "../../../components/common/layout/Table";
import { CrewRoles } from "../../movie/CrewRoles";
import { MovieFieldNames } from "../movie/MovieFieldNames";
import { PurchaseFieldNames } from "./PurchaseFieldNames";
import { PurchaseReportDto } from "../../purchase/PurchaseDto";
import { PurchaseTypeLabels } from "../../purchase/PurchaseType";
import { RightFieldNames } from "../right/RightFieldNames";
import TableUtils from "../../../utils/TableUtils";

export const PurchaseColumns: {
  [key in
    | MovieFieldNames
    | PurchaseFieldNames
    | RightFieldNames]: Column<PurchaseReportDto>;
} = {
  MovieId: {
    title: "Movie Id",
    field: "movie.id",
    render: TableUtils.stringValueRender("movie.id"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Name: {
    title: "Movie Name",
    field: "movie.name",
    render: TableUtils.stringValueRender("movie.name"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  WorkingTitle: {
    title: "Movie Working Title",
    field: "movie.workingTitle",
    render: TableUtils.stringValueRender("movie.workingTitle"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  ReleaseTitle: {
    title: "Movie Release Title",
    field: "movie.releaseTitle",
    render: TableUtils.stringValueRender("movie.releaseTitle"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MovieLanguage: {
    title: "Movie Language",
    field: "movie.language",
    render: TableUtils.stringValueRender("movie.language"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MovieGenre: {
    title: "Movie Genre",
    field: "movie.genre",
    render: TableUtils.stringValueRender("movie.genre"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  ReleaseDate: {
    title: "Movie Release Date",
    field: "movie.releaseDate",
    render: TableUtils.dateValueRender("movie.releaseDate"),
    customSort: TableUtils.dateValueCustomSort("movie.releaseDate"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Format: {
    title: "Movie Format",
    field: "movie.format",
    render: TableUtils.stringArrayValueRender("movie.format"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SourceAvailability: {
    title: "Movie Source Availability",
    field: "movie.sourceAvailability",
    render: TableUtils.booleanValueRender("movie.sourceAvailability"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Actor: {
    title: "Actors",
    field: "movie.crew.actor",
    render: TableUtils.movieCrewValueRender("movie.crew", CrewRoles.ACTOR),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Director: {
    title: "Directors",
    field: "movie.crew.director",
    render: TableUtils.movieCrewValueRender("movie.crew", CrewRoles.DIRECTOR),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Cinematographer: {
    title: "Cinematographer",
    field: "movie.crew.cinematographer",
    render: TableUtils.movieCrewValueRender(
      "movie.crew",
      CrewRoles.CINEMATOGRAPHER
    ),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Lyricist: {
    title: "Lyricist",
    field: "movie.crew.lyricist",
    render: TableUtils.movieCrewValueRender("movie.crew", CrewRoles.LYRICIST),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MusicDirector: {
    title: "Music Director",
    field: "movie.crew.musicDirector",
    render: TableUtils.movieCrewValueRender(
      "movie.crew",
      CrewRoles.MUSIC_DIRECTOR
    ),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Producer: {
    title: "Producer",
    field: "movie.crew.producer",
    render: TableUtils.movieCrewValueRender("movie.crew", CrewRoles.PRODUCER),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Singer: {
    title: "Singer",
    field: "movie.songs.crew.singer",
    render: TableUtils.songCrewValueRender("movie.songs", CrewRoles.SINGER),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  PostersCount: {
    title: "Posters",
    field: "movie.posters",
    render: TableUtils.attachmentValueRender("movie.posters"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  TrailersCount: {
    title: "Trailers",
    field: "movie.trailers",
    render: TableUtils.attachmentValueRender("movie.trailers"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  AgreementDate: {
    title: "Agreement Date",
    field: "agreementDate",
    render: TableUtils.dateValueRender("agreementDate"),
    customSort: TableUtils.dateValueCustomSort("agreementDate"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  ProductionBanner: {
    title: "Production Banner",
    field: "productionBanner",
  },
  Buyer: {
    title: "Buyer",
    field: "buyer",
  },
  Seller: { title: "Seller", field: "seller" },
  Validity: {
    title: "Validity",
    field: "validFrom",
    render: TableUtils.dateRangeValueRender("validFrom", "validTill"),
    customSort: TableUtils.dateValueCustomSort("validFrom"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  PurchaseType: {
    title: "Purchase Type",
    field: "purchaseType",
    render: (rowData) => PurchaseTypeLabels(rowData.purchaseType),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  PurchaseAmount: {
    title: "Purchase Amount",
    field: "purchaseAmount",
    render: TableUtils.priceValueRender("purchaseAmount"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  PurchaseAmountCurrency: {
    title: "Purchase Amount (Currency)",
    field: "purchaseAmount.currency",
    render: TableUtils.stringValueRender("purchaseAmount.currency"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  PurchaseAmountValue: {
    title: "Purchase Amount (Value)",
    field: "purchaseAmount.amount",
    render: TableUtils.stringValueRender("purchaseAmount.amount"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  PurchasePercentage: {
    title: "Purchase Percentage",
    field: "purchasePercentage",
    render: TableUtils.percentageValueRender("purchasePercentage"),
  },
  AdvancePaid: {
    title: "Advance Paid",
    field: "advancePaid",
    render: TableUtils.booleanValueRender("advancePaid"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  AdvanceAmount: {
    title: "Advance Amount",
    field: "advanceAmount",
    render: TableUtils.priceValueRender("advanceAmount"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  AdvanceAmountCurrency: {
    title: "Advance Amount (Currency)",
    field: "advanceAmount.currency",
    render: TableUtils.stringValueRender("advanceAmount.currency"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  AdvanceAmountValue: {
    title: "Advance Amount (Value)",
    field: "advanceAmount.amount",
    render: TableUtils.stringValueRender("advanceAmount.amount"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MinimumGuarantee: {
    title: "Minimum Guarantee",
    field: "minimumGuarantee",
    render: TableUtils.booleanValueRender("minimumGuarantee"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  IsIntermediary: {
    title: "Intermediary Purchase",
    field: "isIntermediary",
    render: TableUtils.booleanValueRender("isIntermediary"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  RightType: {
    title: "Right Types",
    field: "rights.rightType",
    render: TableUtils.rightTypeValueRender("rights"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
    cellStyle: {
      minWidth: 300,
    },
  },
  RightTerritory: {
    title: "Right Territory",
    field: "rights.countrySet",
    render: TableUtils.rightCountryValueRender("rights"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  RightIncluded: {
    title: "Right Exclusive",
    field: "rights.exclusive",
    render: TableUtils.booleanValueRender("rights.0.exclusive"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MainAgreementCount: {
    title: "Main Agreement",
    field: "mainAgreement",
    render: TableUtils.attachmentValueRender("mainAgreement"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  LinkAgreementCount: {
    title: "Link Agreement",
    field: "linkAgreement",
    render: TableUtils.attachmentValueRender("linkAgreement"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  CensorCertificateCount: {
    title: "Censor Certificate",
    field: "censorCertificate",
    render: TableUtils.attachmentValueRender("censorCertificate"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  LabCertificateCount: {
    title: "Lab Certificate",
    field: "labCertificate",
    render: TableUtils.attachmentValueRender("labCertificate"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  RealImageLetterCount: {
    title: "Real Image Letter",
    field: "realImageLetter",
    render: TableUtils.attachmentValueRender("realImageLetter"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
};
