import { useEffect, useMemo } from "react";

import { SaleStringProperties } from "../../../dto/sale/SaleDto";
import { getAllSales } from "../../../store/sale/SaleAction";
import { useDispatch } from "react-redux";
import useSaleProperty from "../../../store/sale/useSaleProperty";

export type SaleOptions = { [key in SaleStringProperties]: string[] };

export default function useSaleAutocomplete() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSales());
  }, [dispatch]);

  const channelNameOptions = useSaleProperty("channelName");
  const customerNameOptions = useSaleProperty("customerName");
  const jukeboxNameOptions = useSaleProperty("jukeboxName");
  const jukeboxVideoIdOptions = useSaleProperty("jukeboxVideoId");
  const languageOptions = useSaleProperty("language");

  return useMemo(
    (): SaleOptions => ({
      channelName: channelNameOptions,
      customerName: customerNameOptions,
      jukeboxName: jukeboxNameOptions,
      jukeboxVideoId: jukeboxVideoIdOptions,
      language: languageOptions,
    }),
    [
      channelNameOptions,
      customerNameOptions,
      jukeboxNameOptions,
      jukeboxVideoIdOptions,
      languageOptions,
    ]
  );
}
