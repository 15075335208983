import { addNewRemark, deleteRemark, updateRemark } from "../../../../store/remark/RemarkAction";
import {
  getSaleDetailsApi,
  updateSaleMedia,
} from "../../../../store/sale/SaleAction";
import { useCallback, useEffect, useState } from "react";

import EditItemRow from "../../common/EditItemRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { Grid } from "@material-ui/core";
import { MediaDto } from "../../../../dto/common/MediaDto";
import MediaListAndUpdate from "../../media/MediaListAndUpdate";
import MoviePurchaseReport from "../../report/purchaseReport/MoviePurchaseReport";
import { RemarkDto } from "../../../../dto/purchase/remarks/RemarkDto";
import RemarkListAndUpdate from "../../remark/RemarkListAndUpdate";
import RightDetails from "../../right/RightDetails";
import { RootState } from "../../../../store";
import { SaleActionTypes } from "../../../../store/sale/SaleTypes";
import { SaleDto } from "../../../../dto/sale/SaleDto";
import { SaleMediaRequestDto } from "../../../../dto/sale/SaleMediaRequestDto";
import SalesBasicDetails from "./SalesBasicDetails";
import SalesCostDetails from "./SalesCostDetails";
import SalesPlatformDetails from "./SalesPlatformDetails";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

type SaleMediaKeys = keyof SaleMediaRequestDto;
type SaleRemarkKey = "remarks";

const SaleDetails = () => {
  const editPrevilage = GetPrevilage('Sales', 'Edit');
  const purchaseViewPrevilage = GetPrevilage('Purchase', 'View');

  const { saleId } = useParams<{ saleId: string }>();

  const [sale, setSale] = useState<SaleDto>();
  const [error, setError] = useState(false);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, SaleActionTypes>>();

  const getSales = useCallback(() => {
    getSaleDetailsApi(saleId)
      .then((sale) => setSale(sale))
      .catch(() => setError(true));
  }, [saleId]);

  useEffect(getSales, [getSales]);

  const onUpdateMedia = async (key: SaleMediaKeys, medias: MediaDto[]) => {
    if (sale) {
      const newSale: SaleMediaRequestDto = {
        [key]: medias,
      };
      await dispatch(updateSaleMedia(saleId, newSale));
      getSales();
    }
  };

  const onUploadFiles = async (key: SaleMediaKeys, media: MediaDto) => {
    if (sale) {
      let medias = sale[key] ?? [];
      medias = medias.concat(media);
      await onUpdateMedia(key, medias);
    }
  };

  const onDeleteComment =async (remarkId: string) => {
    await dispatch(deleteRemark('sales', saleId, remarkId));
    getSales();
  }

  const onAddComment = async (key: SaleRemarkKey, remark: RemarkDto) => {
    let reqBody: any = {
      remark: remark.remark,
      createdBy: remark.createdBy?.id ?? "",
      timeCreated: remark.timeCreated,
      timeUpdated: remark.timeUpdated,
    }
    if(remark && saleId) {
      if(remark.id === '') {
        await dispatch(addNewRemark('sales', reqBody, saleId));
      } else {
        let remarkId = remark.id ?? "";
        await dispatch(updateRemark('sales', saleId, reqBody, remarkId));
      }
      getSales();
    }
  };

  return (
    <div className="sale-details-container">
      <EditItemRow
        backLabel="Sales"
        backRoute="/portal/sales"
        editLabel="Sale"
        editPrevilage={editPrevilage}
        editRoute={sale && `/portal/sales/${sale.id}/edit`}
      />
      {error ? <p>Failed to fetch sale</p> : null}
      {sale ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SalesBasicDetails sale={sale} />
          </Grid>
          <Grid item xs={12}>
            <SalesPlatformDetails sale={sale} />
          </Grid>
          <Grid item xs={12}>
            <SalesCostDetails sale={sale} />
          </Grid>
          <Grid item xs={12}>
            <RightDetails label="Sold Rights" rights={sale.rights} />
          </Grid>
          <Grid item xs={12}>
            <RemarkListAndUpdate
              name="remarks"
              key="Remark"
              label="Remark"
              remarks={sale.remarks}
              onAddComment={onAddComment}
              onDeleteComment={onDeleteComment}
              editPrevilage={editPrevilage ? editPrevilage : false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MediaListAndUpdate
              name="saleAgreement"
              label="Sales Agreement"
              medias={sale.saleAgreement}
              onUpdateMedias={onUpdateMedia}
              onUploadMedia={onUploadFiles}
              editPrevilage={editPrevilage ? editPrevilage : false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MediaListAndUpdate
              name="saleInvoices"
              label="Sales Invoices"
              medias={sale.saleInvoices}
              onUpdateMedias={onUpdateMedia}
              onUploadMedia={onUploadFiles}
              editPrevilage={editPrevilage ? editPrevilage : false}
            />
          </Grid>
          {purchaseViewPrevilage ? (
            <Grid item xs={12}>
              <MoviePurchaseReport movie={sale.movie} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </div>
  );
};

export default SaleDetails;
