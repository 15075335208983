import {
  Box,
  Grid,
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ChangeEvent, HTMLProps } from "react";

import { StringUtils } from "../../../utils/StringUtils";
import TimeUtils from "../../../utils/TimeUtils";

const useStyles = makeStyles((theme) => ({
  separator: {
    fontWeight: "bold",
    padding: theme.spacing(0, 1),
  },
  input: {
    textAlign: "right",
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}));

interface TimeDurationInputProps {
  label: string;
  value?: number;
  onChange: (value?: number) => void;
}

export default function TimeDurationInput({
  label,
  value = 0,
  onChange,
  ...props
}: TimeDurationInputProps & Omit<HTMLProps<HTMLDivElement>, "onChange">) {
  const classes = useStyles();

  const { hours, minutes, seconds } = TimeUtils.splitTime(value);

  const setValue = (h: number, m: number, s: number) => {
    onChange(h * 3600 + m * 60 + s);
  };

  const setHours = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const h = StringUtils.formatNumberField(e.target.value) ?? 0;
    const m = minutes ?? 0;
    const s = seconds ?? 0;
    setValue(h, m, s);
  };

  const setMinutes = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const h = hours ?? 0;
    const m = StringUtils.formatNumberField(e.target.value) ?? 0;
    const s = seconds ?? 0;
    setValue(h, m, s);
  };
  const setSeconds = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const h = hours ?? 0;
    const m = minutes ?? 0;
    const s = StringUtils.formatNumberField(e.target.value) ?? 0;
    setValue(h, m, s);
  };

  return (
    <div {...props}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Grid container>
        <Grid item xs={4}>
          <Box display="flex" alignItems="flex-end">
            <TextField
              inputProps={{
                className: classes.input,
              }}
              value={hours ?? ""}
              onChange={setHours}
              type="number"
              label="HH"
              fullWidth
            />
            <Typography variant="h6" className={classes.separator}>
              :
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="flex-end">
            <TextField
              inputProps={{
                className: classes.input,
              }}
              value={minutes ?? ""}
              onChange={setMinutes}
              type="number"
              label="MM"
              fullWidth
            />
            <Typography variant="h6" className={classes.separator}>
              :
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <TextField
            inputProps={{
              className: classes.input,
            }}
            value={seconds ?? ""}
            onChange={setSeconds}
            type="number"
            label="SS"
          />
        </Grid>
      </Grid>
    </div>
  );
}
