import { RECEIVE_SALES, REQUEST_SALES, SaleActionTypes } from "./SaleTypes";

import { Reducer } from "redux";
import { SaleDto } from "../../dto/sale/SaleDto";

export interface SalesState {
  sales: SaleDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: SalesState = {
  sales: [],
  loading: false,
  loaded: false,
};

const SaleReducer: Reducer<SalesState, SaleActionTypes> = (
  state: SalesState = initialState,
  action: SaleActionTypes
) => {
  switch (action.type) {
    case REQUEST_SALES:
      return { ...state, loading: true };
    case RECEIVE_SALES:
      const sales = action.payload;
      return {
        ...state,
        sales: [...sales],
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
};

export default SaleReducer;
