import BackTo from "../../../common/button/BackTo";
import PurchaseForm from "../purchaseForm/PurchaseForm";

const PurchaseAdd = () => {
  return (
    <div>
      <BackTo name="Purchases" path="/portal/purchase" />
      <PurchaseForm />
    </div>
  );
};

export default PurchaseAdd;
