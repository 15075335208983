import { Box, BoxProps, Typography, makeStyles } from "@material-ui/core";

import { Colors } from "../../../utils/DefaultTheme";
import ConditionalLink from "../../common/button/ConditionalLink";

const useStyles = makeStyles(() => ({
  title: {
    color: Colors.GREY_0,
  },
}));

interface DetailItemProps {
  label: string;
  link?: string;
}

export default function DetailItem({
  label,
  link,
  ...props
}: DetailItemProps & BoxProps) {
  const classes = useStyles();

  return (
    <Box {...props}>
      <Typography variant="body2" className={classes.title}>
        {label}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <ConditionalLink link={link}>{props.children}</ConditionalLink>
      </Typography>
    </Box>
  );
}
