import { RECEIVE_USERS, REQUEST_USERS, UserActionTypes } from "./UserTypes";

import { Api } from "../../utils/Api";
import { Notification } from "../../utils/Notification";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";
import { UserDto } from "../../dto/user/UserDto";
import { UserRequestDto } from "../../dto/user/UserRequestDto";

const requestUsers = (): UserActionTypes => ({
  type: REQUEST_USERS,
});

const recieveUsers = (users: UserDto[]): UserActionTypes => ({
  type: RECEIVE_USERS,
  payload: users,
});

const getAllUsersFromApi = async (): Promise<UserDto[]> => {
  try {
    const users = await Api().get<UserDto[]>("/users");
    return users.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get all users");
    return [];
  }
};

const updateUserDetailsApi = async (userId: string, userDto: UserDto, type: string) => {
  try {
    const user = await Api().put<UserDto>(`/users/${userId}`, userDto);
    if(type === 'delete') {
      Notification.showSuccess("User deleted successfully");
    } else {
      Notification.showSuccess("Updated user successfully");
    }
    return user.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot update user info");
    throw err;
  }
};

const addNewUserApi = async (userDto: UserRequestDto): Promise<void> => {
  try {
    await Api().post<UserDto>("/users", userDto);
    Notification.showSuccess("User added successfully");
  } catch (err) {
    Notification.showApiError(err, "Cannot add new user");
  }
};

export const getAllUsers =
  (
    force = false
  ): ThunkAction<Promise<void>, RootState, unknown, UserActionTypes> =>
  async (dispatch, getState) => {
    const { user } = getState();
    if (user.loading || (!force && user.loaded)) {
      return;
    }
    dispatch(requestUsers());
    const users: UserDto[] = await getAllUsersFromApi();
    dispatch(recieveUsers(users));
  };

export const addNewUser =
  (
    newUser: UserRequestDto
  ): ThunkAction<Promise<void>, RootState, unknown, UserActionTypes> =>
  async (dispatch) => {
    await addNewUserApi(newUser);
    await dispatch(getAllUsers(true));
  };

export const getUserDetails = async (userId: string): Promise<UserDto> => {
  try {
    const user = await Api().get<UserDto>(`/users/${userId}`);
    return user.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get user info");
    throw err;
  }
};

export const updateUser =
  (
    userId: string,
    userDto: UserDto,
    type: string
  ): ThunkAction<Promise<UserDto>, RootState, unknown, UserActionTypes> =>
  async (dispatch) => {
    const user = await updateUserDetailsApi(userId, userDto, type);
    dispatch(getAllUsers(true));
    return Promise.resolve(user);
  };
