import { RECEIVE_RIGHTS, REQUEST_RIGHTS, RightActionTypes } from "./RightTypes";
import RightGroupDto, {
  RightGroupRequestDto,
} from "../../dto/right/RightGroupDto";
import RightTypeDto, {
  RightTypeRequestDto,
  RightTypeWithoutGroup,
} from "../../dto/right/RightTypeDto";

import { Api } from "../../utils/Api";
import { Notification } from "../../utils/Notification";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";

const requestRights = (): RightActionTypes => ({
  type: REQUEST_RIGHTS,
});

const receiveRights = (
  rights: RightTypeDto[],
  rightGroups: RightGroupDto[]
): RightActionTypes => ({
  type: RECEIVE_RIGHTS,
  rights,
  rightGroups,
});

const getAllRightTypesFromApi = async (): Promise<RightTypeDto[]> => {
  try {
    const rights = await Api().get<RightTypeDto[]>("/rights");
    return rights.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get all rights");
    return [];
  }
};

export const getAllRightGroups = async (): Promise<RightGroupDto[]> => {
  try {
    const group = await Api().get("/rights-group");
    return group.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get primary rights list");
    throw err;
  }
};

export const addRightGroup = async (
  rightGroup: RightGroupRequestDto
): Promise<RightGroupDto> => {
  try {
    const group = await Api().post("/rights-group", rightGroup);
    Notification.showSuccess("Created new primary right");
    return group.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot create new primary right");
    throw err;
  }
};

const updateRightGroupApi = async (
  rightGroupId: string,
  rightGroup: RightGroupRequestDto
): Promise<RightGroupDto> => {
  try {
    const group = await Api().put(`/rights-group/${rightGroupId}`, rightGroup);
    return group.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot update primary right");
    throw err;
  }
};

export const deleteRightGroup = async (rightGroupId: String) => {
  try {
    const group = await Api().delete(`/rights-group/${rightGroupId}`);
    Notification.showSuccess("Deleted primary right");
    return group.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot delete primary right");
    throw err;
  }
};

const addRightTypesApi = async (
  rightTypes: RightTypeRequestDto[]
): Promise<RightTypeDto[]> => {
  try {
    const rights = await Api().post("/rights", { rights: rightTypes });
    return rights.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot create new secondary right");
    throw err;
  }
};

const updateRightTypeApi = async (
  rightTypeId: string,
  rightType: RightTypeRequestDto
): Promise<RightTypeDto> => {
  try {
    const group = await Api().put(`/rights/${rightTypeId}`, rightType);
    return group.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot update secondary right");
    throw err;
  }
};

export const getAllRights =
  (
    force = false
  ): ThunkAction<Promise<void>, RootState, unknown, RightActionTypes> =>
  async (dispatch, getState) => {
    const { right } = getState();

    if (right.loading || (!force && right.loaded)) {
      return;
    }

    dispatch(requestRights());
    const rights = await getAllRightTypesFromApi();
    const rightGroups = await getAllRightGroups();
    dispatch(receiveRights(rights, rightGroups));
  };

export const addRightTypes =
  (
    rightTypes: RightTypeRequestDto[],
    refresh = true
  ): ThunkAction<
    Promise<RightTypeDto[]>,
    RootState,
    unknown,
    RightActionTypes
  > =>
  async (dispatch) => {
    const result = await addRightTypesApi(rightTypes);
    if (refresh) {
      dispatch(getAllRights(true));
    }
    return result;
  };

export const updateRightTypes =
  (
    rightGroupId: string,
    rightTypes: RightTypeWithoutGroup[]
  ): ThunkAction<
    Promise<RightTypeDto[]>,
    RootState,
    unknown,
    RightActionTypes
  > =>
  async (dispatch) => {
    const result = await Promise.all(
      rightTypes.map((rightType) =>
        updateRightTypeApi(rightType.id, {
          group: rightGroupId,
          name: rightType.name,
        })
      )
    );
    dispatch(getAllRights(true));
    return result;
  };

export const updateRightGroup =
  (
    rightGroupId: string,
    rightGroup: RightGroupRequestDto,
    refresh = true
  ): ThunkAction<
    Promise<RightGroupDto>,
    RootState,
    unknown,
    RightActionTypes
  > =>
  async (dispatch) => {
    const result = await updateRightGroupApi(rightGroupId, rightGroup);
    if (refresh) {
      dispatch(getAllRights(true));
    }
    return result;
  };
