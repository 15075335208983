import { useEffect, useMemo } from "react";

import { MovieStringProperties } from "../../../dto/movie/MovieDto";
import { getAllMovies } from "../../../store/movie/MovieAction";
import { useDispatch } from "react-redux";
import useMovieProperty from "../../../store/movie/useMovieProperty";

export type MovieOptions = { [key in MovieStringProperties]: string[] };

export default function useMovieAutocomplete() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMovies());
  }, [dispatch]);

  const nameOptions = useMovieProperty("name");
  const releaseTitleOptions = useMovieProperty("releaseTitle");
  const workingTitleOptions = useMovieProperty("workingTitle");
  const languageOptions = useMovieProperty("language");
  const genreOptions = useMovieProperty("genre");
  const formatOptions = useMovieProperty("format");
  const peopleOptions = useMovieProperty("people");

  return useMemo(
    (): MovieOptions => ({
      name: nameOptions,
      releaseTitle: releaseTitleOptions,
      workingTitle: workingTitleOptions,
      language: languageOptions,
      genre: genreOptions,
      format: formatOptions,
      people: peopleOptions,
    }),
    [
      nameOptions,
      releaseTitleOptions,
      workingTitleOptions,
      languageOptions,
      genreOptions,
      formatOptions,
      peopleOptions,
    ]
  );
}
