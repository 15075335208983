import { Grid, GridProps } from "@material-ui/core";

import { ChoosePlatform } from "./ChoosePlatform";
import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import CustomSpacingGrid from "../../../common/layout/CustomSpacingGrid";
import DateField from "../../../common/form/DateField";
import FormSection from "../../common/FormSection";
import { PlatformType } from "../../../../dto/sale/SaleDto";
import { SalePlatformDetailsRequestDto } from "../../../../dto/sale/SaleRequestDto";
import SalePlatformSpecificForm from "./SalePlatformSpecificForm";
import useSaleProperty from "../../../../store/sale/useSaleProperty";

interface SalePlatformDetailsFormProps {
  sales: SalePlatformDetailsRequestDto;
  setSales: (sale: SalePlatformDetailsRequestDto) => void;
}

export default function SalePlatformDetailsForm({
  sales,
  setSales,
  ...props
}: SalePlatformDetailsFormProps & GridProps) {
  const setPlatform = (platform: PlatformType) =>
    setSales({ ...sales, platform });
  const setCustomerName = (customerName: string) =>
    setSales({ ...sales, customerName });
  const setDateOfPublishing = (dateOfPublishing?: Date) =>
    setSales({ ...sales, dateOfPublishing });

  const customerNameOptions = useSaleProperty("customerName");

  const showDateOfPublishing =
    sales.platform === PlatformType.Internet ||
    sales.platform === PlatformType.PayPerUse;

  return (
    <FormSection label="Platform Details" {...props}>
      <CustomSpacingGrid
        container
        horizontalSpacing={2}
        verticalSpacing={1}
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <ChoosePlatform platform={sales.platform} setPlatForm={setPlatform} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Customer Name"
            type="text"
            options={customerNameOptions}
            freeSolo
            required={false}
            inputValue={sales.customerName}
            onInputChange={setCustomerName}
          />
        </Grid>
        {showDateOfPublishing && (
          <Grid item xs={12} md={6}>
            <DateField
              label="Date of Publishing"
              fullWidth
              value={sales.dateOfPublishing}
              onChange={setDateOfPublishing}
            />
          </Grid>
        )}

        <SalePlatformSpecificForm
          platform={sales.platform}
          sales={sales}
          setSales={setSales}
        />
      </CustomSpacingGrid>
    </FormSection>
  );
}
