import { FilterFieldType } from "./FilterFieldType";
import { StringUtils } from "../../utils/StringUtils";

export enum FilterCondition {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  LIKE = "LIKE",
  IN = "IN",
  NOT_IN = "NOT_IN",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  BETWEEN = "BETWEEN",
  ALL_OF = "ALL_OF",
  ONE_OF = "ONE_OF",
}

export const getFilterConditions = (
  fieldType: FilterFieldType
): FilterCondition[] => {
  switch (fieldType) {
    case FilterFieldType.DATE:
    case FilterFieldType.NUMBER:
    case FilterFieldType.TIME_LENGTH:
    case FilterFieldType.CURRENCY:
      return [
        FilterCondition.EQUALS,
        FilterCondition.NOT_EQUALS,
        FilterCondition.GREATER_THAN,
        FilterCondition.LESS_THAN,
        FilterCondition.BETWEEN,
      ];
    case FilterFieldType.STRING:
      return [
        FilterCondition.EQUALS,
        FilterCondition.NOT_EQUALS,
        FilterCondition.LIKE,
        FilterCondition.IN,
        FilterCondition.NOT_IN,
      ];
    case FilterFieldType.DURATION:
      return [
        FilterCondition.EQUALS,
        FilterCondition.GREATER_THAN,
        FilterCondition.LESS_THAN,
        FilterCondition.BETWEEN,
      ];
    case FilterFieldType.LIST:
      return [
        FilterCondition.EQUALS,
        FilterCondition.ALL_OF,
        FilterCondition.ONE_OF,
      ];
    case FilterFieldType.BOOLEAN:
      return [FilterCondition.EQUALS];
  }
};

export const filterCondtionName: {
  [key in keyof typeof FilterCondition]: string;
} = {
  EQUALS: "Equals",
  NOT_EQUALS: "Not Equals",
  LIKE: "Like",
  IN: "In",
  NOT_IN: "Not In",
  GREATER_THAN: "Greater Than",
  LESS_THAN: "Less Than",
  BETWEEN: "Between",
  ALL_OF: "All of",
  ONE_OF: "One of",
};

export const filterDefaultCondition = (
  fieldType: FilterFieldType
): FilterCondition => {
  switch (fieldType) {
    case FilterFieldType.DATE:
    case FilterFieldType.DURATION:
    case FilterFieldType.TIME_LENGTH:
      return FilterCondition.BETWEEN;
    case FilterFieldType.CURRENCY:
    case FilterFieldType.NUMBER:
    case FilterFieldType.STRING:
    case FilterFieldType.LIST:
    case FilterFieldType.BOOLEAN:
      return FilterCondition.EQUALS;
  }
};

export const filterDefaultValue = (
  condition: FilterCondition,
  currentValue?: any
) => {
  let currentValues = Array.isArray(currentValue)
    ? currentValue
    : [StringUtils.formatFieldValue(currentValue)];
  currentValues = currentValues.filter((v) => !!v);
  switch (condition) {
    case FilterCondition.BETWEEN:
      return [currentValues[0] ?? "", currentValues[1] ?? ""];
    case FilterCondition.EQUALS:
    case FilterCondition.NOT_EQUALS:
    case FilterCondition.GREATER_THAN:
    case FilterCondition.LESS_THAN:
    case FilterCondition.LIKE:
      return currentValues[0] ?? "";
    case FilterCondition.IN:
    case FilterCondition.NOT_IN:
    case FilterCondition.ALL_OF:
    case FilterCondition.ONE_OF:
      return currentValues;
  }
};
