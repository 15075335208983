import {
  AgreementExpiryLabel,
  AgreementExpiryOptions,
  AgreementExpiryType,
} from "../../../dto/common/AgreementExpiryType";
import { Grid, TextField } from "@material-ui/core";

import { AgreementExpiryDto } from "../../../dto/common/AgreementExpirtyDto";
import { ChangeEvent } from "react";
import CustomAutocomplete from "./CustomAutocomplete";
import { StringUtils } from "../../../utils/StringUtils";

interface AgreementExpiryFieldProps {
  agreementExpiry?: AgreementExpiryDto;
  setAgreementExpiry: (agreementExpiry: AgreementExpiryDto) => void;
}

export default function AgreementExpiryField({
  agreementExpiry,
  setAgreementExpiry,
}: AgreementExpiryFieldProps) {
  const setAgreementExpiryType = (type: AgreementExpiryType) =>
    setAgreementExpiry({ ...agreementExpiry, type });
  const setExpiryDuration = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setAgreementExpiry({
      ...agreementExpiry,
      duration: StringUtils.formatNumberField(e.target.value),
    });

  const showDuration =
    agreementExpiry?.type === AgreementExpiryType.YEARS ||
    agreementExpiry?.type === AgreementExpiryType.MONTHS;

  return (
    <>
      <Grid item xs={12} md={6}>
        <CustomAutocomplete
          label="Agreement Expiry"
          options={AgreementExpiryOptions}
          getOptionLabel={AgreementExpiryLabel}
          value={agreementExpiry?.type}
          onChange={setAgreementExpiryType}
        />
      </Grid>
      {showDuration && (
        <Grid item xs={12} md={6}>
          <TextField
            label="Duration"
            type="number"
            fullWidth
            value={agreementExpiry?.duration ?? ""}
            onChange={setExpiryDuration}
            required
            inputProps={{ min: 1 }}
          />
        </Grid>
      )}
    </>
  );
}
