import { Route, Switch, useRouteMatch } from "react-router-dom";

import MovieReport from "./movieReport/MovieReport";
import OwnedRightsReport from "./ownedRightsReport/OwnedRightsReport";
import PurchaseReportPage from "./purchaseReport/PurchaseReportPage";
import ReportDashboard from "./reportDashboard/ReportDashboard";
import SalesReportPage from "./salesReport/SalesReportPage";

const Report = () => {
  const match = useRouteMatch();
  
  return (
    <Switch>
      <Route path={match.path} exact component={ReportDashboard} />
      <Route path={`${match.path}/movies`} component={MovieReport} />
      <Route path={`${match.path}/purchase`} component={PurchaseReportPage} />
      <Route path={`${match.path}/sales`} component={SalesReportPage} />
      <Route
            path={`${match.path}/owned-rights`}
            component={OwnedRightsReport}
          />
    </Switch>
  );
};

export default Report;
