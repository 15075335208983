import {
  CountrySetStringProperties,
  selectCountrySetValueByProperty,
} from "../../dto/countrySet/CountrySetDto";

import { RootState } from "..";
import { createSelector } from "reselect";
const selectCountrySets = (state: RootState) => state.countrySet.countrySets;
const selectProperty = (_: RootState, property: CountrySetStringProperties) =>
  property;

export const makeSelectCountrySetPropertyValues = () => {
  const selectCountrySetPropertyValues = createSelector(
    selectCountrySets,
    selectProperty,
    selectCountrySetValueByProperty
  );
  return selectCountrySetPropertyValues;
};
