import { Grid, GridProps } from "@material-ui/core";

import { AgreementExpiryDto } from "../../../../dto/common/AgreementExpirtyDto";
import AgreementExpiryField from "../../../common/form/AgreementExpiryField";
import { AgreementExpiryType } from "../../../../dto/common/AgreementExpiryType";
import CustomSpacingGrid from "../../../common/layout/CustomSpacingGrid";
import DateField from "../../../common/form/DateField";
import { DateUtils } from "../../../../utils/DateUtils";
import FormSection from "../../common/FormSection";
import { PlatformType } from "../../../../dto/sale/SaleDto";
import PriceField from "../../../common/form/PriceField";
import { PriceRequestDto } from "../../../../dto/common/PriceRequestDto";
import { SaleCostDetailsRequestDto } from "../../../../dto/sale/SaleRequestDto";

interface SaleCostDetailsFormProps {
  platform: PlatformType;
  sales: SaleCostDetailsRequestDto;
  setSales: (sales: SaleCostDetailsRequestDto) => void;
}

export default function SaleCostDetailsForm({
  platform,
  sales,
  setSales,
  ...props
}: SaleCostDetailsFormProps & GridProps) {
  const calculateValidTill = (s: SaleCostDetailsRequestDto) => {
    const validTill = DateUtils.calculateValidTill(
      s.validFrom,
      s.agreementExpiry
    );
    setSales({ ...s, validTill });
  };

  const setAgreementDate = (agreementDate?: Date) =>
    setSales({ ...sales, agreementDate });
  const setValidFrom = (validFrom?: Date) =>
    calculateValidTill({ ...sales, validFrom });
  const setValidTill = (validTill?: Date) => setSales({ ...sales, validTill });
  const setAgreementExpiry = (agreementExpiry: AgreementExpiryDto) =>
    calculateValidTill({ ...sales, agreementExpiry });
  const setSaleAmount = (saleAmount?: PriceRequestDto) =>
    setSales({ ...sales, saleAmount });

  const validTillDisabled = !(
    sales.agreementExpiry?.type === AgreementExpiryType.SPECIFIC
  );
  const showAgreementExpiry = !!sales.validFrom;

  const isAgreementDateRequired = false;
  const isPriceRequired = false;

  return (
    <FormSection label="Cost Details" {...props}>
      <CustomSpacingGrid container horizontalSpacing={2} verticalSpacing={1}>
        <Grid item xs={12} md={6}>
          <DateField
            label="Agreement Date"
            fullWidth
            required={isAgreementDateRequired}
            value={sales.agreementDate}
            onChange={setAgreementDate}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateField
            label="Valid From"
            fullWidth
            required={isAgreementDateRequired}
            value={sales.validFrom}
            onChange={setValidFrom}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateField
            label="Valid Till"
            fullWidth
            required={isAgreementDateRequired}
            value={sales.validTill}
            onChange={setValidTill}
            disabled={validTillDisabled}
          />
        </Grid>
        {showAgreementExpiry && (
          <AgreementExpiryField
            agreementExpiry={sales.agreementExpiry}
            setAgreementExpiry={setAgreementExpiry}
          />
        )}
        <Grid item xs={12}>
          <PriceField
            label="Sale Amount"
            required={isPriceRequired}
            value={sales.saleAmount}
            onChange={setSaleAmount}
          />
        </Grid>
      </CustomSpacingGrid>
    </FormSection>
  );
}
