import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  FilterCondition,
  filterCondtionName,
  getFilterConditions,
} from "../../../../dto/report/FilterCondition";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { FilterField } from "../../../../dto/report/FilterField";
import FilterUtils from "../../../../utils/FilterUtils";

const getCondtions = (fields: FilterField[], filterName: string) => {
  return getFilterConditions(FilterUtils.getFieldType(fields, filterName));
};

interface FilterFieldCondtionInputProps {
  condition?: FilterCondition;
  setCondition: (condition: FilterCondition) => void;
  fields: FilterField[];
  field: string;
}

export default function FilterFieldCondtionInput({
  condition,
  setCondition,
  fields,
  field,
}: FilterFieldCondtionInputProps) {
  const [conditions, setConditions] = useState<FilterCondition[]>([]);

  useEffect(() => {
    setConditions(getCondtions(fields, field));
  }, [fields, field]);

  const onChange = useCallback(
    (
      e: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      return setCondition(e.target.value as FilterCondition);
    },
    [setCondition]
  );

  return (
    <FormControl fullWidth required>
      <InputLabel id="filter-condition-label">Condition</InputLabel>
      <Select
        labelId="filter-condition-label"
        name="condition"
        value={condition ?? ""}
        onChange={onChange}
      >
        {conditions.map((c) => (
          <MenuItem key={c} value={c}>
            {filterCondtionName[c]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
