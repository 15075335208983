import { Grid, GridProps } from "@material-ui/core";

import { AgreementExpiryDto } from "../../../../dto/common/AgreementExpirtyDto";
import AgreementExpiryField from "../../../common/form/AgreementExpiryField";
import { AgreementExpiryType } from "../../../../dto/common/AgreementExpiryType";
import { ChangeEvent } from "react";
import { ChooseMovie } from "../../movie/chooseMovie/ChooseMovie";
import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import CustomCheckbox from "../../../common/form/CustomCheckbox";
import CustomSpacingGrid from "../../../common/layout/CustomSpacingGrid";
import DateField from "../../../common/form/DateField";
import { DateUtils } from "../../../../utils/DateUtils";
import FormSection from "../../common/FormSection";
import { PurchaseBasicDetailsRequestDto } from "../../../../dto/purchase/PurchaseRequestDto";
import usePurchaseAutocomplete from "../../../common/hooks/UsePurchaseAutocomplete";

interface PurchaseBasicDetailsFormProps {
  purchase: PurchaseBasicDetailsRequestDto;
  setPurchase: (purchase: PurchaseBasicDetailsRequestDto) => void;
}

export default function PurchaseBasicDetailsForm({
  purchase,
  setPurchase,
  ...props
}: PurchaseBasicDetailsFormProps & GridProps) {
  const calculateValidTill = (p: PurchaseBasicDetailsRequestDto) => {
    const validTill = DateUtils.calculateValidTill(
      p.validFrom,
      p.agreementExpiry
    );
    setPurchase({ ...p, validTill });
  };

  const calculateIsIntermediary = (p: PurchaseBasicDetailsRequestDto) => {
    const isIntermediary = p.seller !== p.productionBanner;
    setPurchase({ ...p, isIntermediary });
  };

  const setMovie = (movie: string) => setPurchase({ ...purchase, movie });

  const setAgreementDate = (agreementDate?: Date) =>
    setPurchase({ ...purchase, agreementDate });

  const setProductionBanner = (productionBanner: string) =>
    calculateIsIntermediary({ ...purchase, productionBanner });

  const setBuyer = (buyer: string) => setPurchase({ ...purchase, buyer });
  const setSeller = (seller: string) =>
    calculateIsIntermediary({ ...purchase, seller });
  const setValidFrom = (validFrom?: Date) =>
    calculateValidTill({ ...purchase, validFrom });
  const setValidTill = (validTill?: Date) =>
    setPurchase({ ...purchase, validTill });
  const setAgreementExpiry = (agreementExpiry: AgreementExpiryDto) =>
    calculateValidTill({ ...purchase, agreementExpiry });
  const setIsIntermediary = (e: ChangeEvent<HTMLInputElement>) =>
    setPurchase({ ...purchase, isIntermediary: e.target.checked });

  const purchaseOptions = usePurchaseAutocomplete();

  const showAgreementExpiry = !!purchase.validFrom;
  const disableValidTill = !(
    purchase.agreementExpiry?.type === AgreementExpiryType.SPECIFIC
  );

  return (
    <FormSection label="Purchase Details" {...props}>
      <CustomSpacingGrid container horizontalSpacing={2} verticalSpacing={1}>
        <Grid item xs={12}>
          <ChooseMovie movie={purchase.movie} setMovie={setMovie} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateField
            label="Agreement Date"
            fullWidth
            value={purchase.agreementDate}
            onChange={setAgreementDate}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Production Banner"
            type="text"
            options={purchaseOptions.productionBanner}
            freeSolo
            inputValue={purchase.productionBanner}
            onInputChange={setProductionBanner}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Seller"
            type="text"
            options={purchaseOptions.seller}
            freeSolo
            inputValue={purchase.seller}
            onInputChange={setSeller}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Buyer"
            type="text"
            options={purchaseOptions.buyer}
            freeSolo
            inputValue={purchase.buyer}
            onInputChange={setBuyer}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateField
            label="Valid From"
            fullWidth
            value={purchase.validFrom}
            onChange={setValidFrom}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateField
            label="Valid Till"
            fullWidth
            value={purchase.validTill}
            onChange={setValidTill}
            disabled={disableValidTill}
          />
        </Grid>
        {showAgreementExpiry && (
          <AgreementExpiryField
            agreementExpiry={purchase.agreementExpiry}
            setAgreementExpiry={setAgreementExpiry}
          />
        )}
        <Grid item xs={12} md={6}>
          <CustomCheckbox
            checked={purchase.isIntermediary}
            onChange={setIsIntermediary}
            name="isIntermediary"
            label="Intermediary Sale?"
          />
        </Grid>
      </CustomSpacingGrid>
    </FormSection>
  );
}
