import { Column } from "@material-table/core";
import { CrewRoles } from "../dto/movie/CrewRoles";
import { DateUtils } from "./DateUtils";
import { FileUtils } from "./FileUtils";
import { MediaDto } from "../dto/common/MediaDto";
import MovieUtils from "./MovieUtils";
import Papa from "papaparse";
import { RightDto } from "../dto/right/RightDto";
import { SongDto } from "../dto/movie/SongDto";
import { StringUtils } from "./StringUtils";
import TimeUtils from "./TimeUtils";
var get = require("lodash.get");

type AnyString = string & {};

export default class TableUtils {
  static customFilterAndSerachForCustomRender<RowData extends object>(
    filter: any,
    rowData: RowData,
    columnDef: Column<RowData>
  ) {
    return (
      columnDef.render !== undefined &&
      StringUtils.includes(columnDef.render(rowData, "row"), filter)
    );
  }

  static stringValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) => get(rowData, field);
  }

  static stringArrayValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) =>
      ((get(rowData, field) ?? []) as string[]).join(", ");
  }

  static booleanValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) =>
      StringUtils.getBooleanStringValue(get(rowData, field));
  }

  static dateValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) =>
      DateUtils.getFormattedDate(get(rowData, field));
  }

  static dateRangeValueRender<RowData>(
    field1: keyof RowData | AnyString,
    field2: keyof RowData | AnyString
  ) {
    return (rowData: RowData) =>
      DateUtils.getFormattedDuration(
        get(rowData, field1),
        get(rowData, field2)
      );
  }

  static dateValueCustomSort<RowData>(field: keyof RowData | AnyString) {
    return (r1: RowData, r2: RowData) =>
      DateUtils.sortDate(get(r1, field), get(r2, field));
  }

  static priceValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) =>
      StringUtils.formatPriceString(get(rowData, field));
  }

  static timeDurationValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) => TimeUtils.formatTime(get(rowData, field));
  }

  static percentageValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) =>
      StringUtils.formatPercentageValue(get(rowData, field));
  }

  static movieCrewValueRender<RowData>(
    field: keyof RowData | AnyString,
    crewRole: CrewRoles
  ) {
    return (rowData: RowData) =>
      MovieUtils.getCrewInfo(get(rowData, field), crewRole);
  }

  static songCrewValueRender<RowData>(
    field: keyof RowData | AnyString,
    crewRole: CrewRoles
  ) {
    return (rowData: RowData) =>
      MovieUtils.getCrewInfo(
        get(rowData, field)?.flatMap((song: SongDto) => song.crew),
        crewRole
      );
  }

  static rightTypeValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) =>
      (get(rowData, field) as RightDto[])
        ?.flatMap((right) => right.rightTypes)
        .map((rightType) => rightType.name)
        .join(", ");
  }

  static rightCountryValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) =>
      (get(rowData, field) as RightDto[])
        ?.flatMap((right) => right.countrySet.name)
        .join(", ");
  }

  static attachmentValueRender<RowData>(field: keyof RowData | AnyString) {
    return (rowData: RowData) =>
      (get(rowData, field) as MediaDto[])
        ?.flatMap((media, index) =>
          media.name ? media.name : `File ${index + 1}`
        )
        .join(", ");
  }

  static exportCsv<RowData extends {}>(
    rows: RowData[],
    columns: Column<RowData>[],
    fileName: string = "export.csv"
  ) {
    return () => {
      const csvData = Papa.unparse({
        fields: columns.map((column) => column.title),
        data: rows.map((row) =>
          columns.map((column) =>
            column.render
              ? column.render(row, "row")
              : get(row, column.field) ?? ""
          )
        ),
      });
      FileUtils.saveCsvFile(csvData, fileName);
    };
  }
}
