import { RootState } from "..";
import { SaleStringProperties } from "../../dto/sale/SaleDto";
import { makeSelectSalePropertyValues } from "./SaleSelector";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useSaleProperty(property: SaleStringProperties) {
  const propertySelector = useMemo(makeSelectSalePropertyValues, []);
  const values = useSelector((state: RootState) =>
    propertySelector(state, property)
  );
  return values;
}
