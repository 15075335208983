import { Route, Switch, useRouteMatch } from "react-router-dom";

import AddMovie from "./addMovie/AddMovie";
import EditMovie from "./editMovie/EditMovie";
import { GetPrevilage } from "../../../utils/Previlage";
import MovieDetails from "./movieDetails/MovieDetails";
import MoviesList from "./movieList/MoviesList";
import { PAGE_NOT_AUTHORIZED } from "../../../constants/constants";

const Movie = () => {
  const match = useRouteMatch();
  const viewPrevilage = GetPrevilage('Movies', 'View');
  
  return (
    <>
      {viewPrevilage ? (
        <Switch>
          <Route path={match.path} exact component={MoviesList} />
          <Route path={`${match.path}/add`} component={AddMovie} />
          <Route path={`${match.path}/:movieId`} exact component={MovieDetails} />
          <Route path={`${match.path}/:movieId/edit`} component={EditMovie} />
        </Switch>
      ) : (
        <span>{PAGE_NOT_AUTHORIZED}</span>
      )}
    </>
  );
};

export default Movie;
