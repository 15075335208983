import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

import DashboardCardType from "./DashboardCardType";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "block",
    height: "100%",
  },
  card: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.border.primary}`,
    boxShadow: "none",
    "&:hover": {
      background: `${theme.palette.text.primary}0A`,
      boxShadow:
        "0px 1px 2px 0px rgb(66 66 66 / 30%), 0px 2px 6px 2px rgb(66 66 66 / 15%)",
      borderColor: "transparent",
    },
    "& .MuiCardContent-root": {
      padding: theme.spacing(1.5, 2),
    },
  },
  iconContainer: {
    color: theme.palette.text.primary,
    opacity: 0.8,
  },
  bodyText: {
    marginLeft: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
  },
  description: {
    color: theme.palette.grey[600],
  },
}));

interface DashboardCardProps {
  card: DashboardCardType;
}

export default function DashboardCard({ card }: DashboardCardProps) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Link to={card.link} className={classes.link}>
        <Card className={classes.card}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <div className={classes.iconContainer}>{card.icon}</div>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                className={classes.bodyText}
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.title}
                >
                  {card.title}
                </Typography>
                <Typography variant="body2" className={classes.description}>
                  {card.description}
                </Typography>
              </Box>
              {/* <Chip label="5" color="primary" /> */}
            </Box>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}
