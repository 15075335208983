import { useEffect, useState } from "react";

import ColumnPicker from "../common/ColumnPicker";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { FilterField } from "../../../../dto/report/FilterField";
import FilterTableToolbar from "../common/FilterTableToolbar";
import { GetPrevilage } from "../../../../utils/Previlage";
import { PurchaseColumns } from "../../../../dto/report/purchase/PurchaseColumns";
import { PurchaseReportDto } from "../../../../dto/purchase/PurchaseDto";
import Table from "../../../common/layout/Table";
import usePersistedState from "../../../common/hooks/UsePersistedState";
import useRowClickListener from "../../../common/hooks/UseRowClickListener";

const PurchaseListColumns = Object.values(PurchaseColumns);

const flattenPurchase = (purchases: PurchaseReportDto[]) => {
  const expandedPurchases = purchases.flatMap((purchase) => {
    if (purchase.rights.length <= 1) {
      return purchase;
    }
    const flattenedPurchase: PurchaseReportDto[] = [
      { ...purchase, rights: [] },
      ...purchase.rights.map(
        (right, index) =>
          ({
            rights: [right],
            parentId: purchase.id,
            id: `${purchase.id}-${index}`,
          } as PurchaseReportDto)
      ),
    ];
    return flattenedPurchase;
  });
  return expandedPurchases;
};

interface PurchaseReportResultProps {
  purchases: PurchaseReportDto[];
  filters: FilterDto[];
  setFilters?: (filters: FilterDto[]) => void;
  fields: FilterField[];
  tableId?: string;
  title?: string;
}

export default function PurchaseReportResult({
  purchases,
  filters,
  setFilters,
  fields,
  tableId = "purchase-report",
  title = "Purchase List",
}: PurchaseReportResultProps) {
  const { state: columns, setState: setColumns } = usePersistedState<string[]>(
    `${tableId}-columns`,
    [
      PurchaseColumns.Name,
      PurchaseColumns.ReleaseDate,
      PurchaseColumns.Actor,
      PurchaseColumns.Buyer,
      PurchaseColumns.Seller,
      PurchaseColumns.Validity,
      PurchaseColumns.AgreementDate,
      PurchaseColumns.PurchaseType,
    ].map((column) => column.field)
  );

  const [purchasesInternal, setPurchaseInternal] = useState<
    PurchaseReportDto[]
  >([]);

  const [filteredColumns, setFilteredColumns] = useState(PurchaseListColumns);
  const viewPrevilage = GetPrevilage('Purchase', 'View');
  const purchaseReportPrevilage = GetPrevilage('Reports', 'Purchase');

  const goToPurchaseDetail = useRowClickListener(
    (purchase?: PurchaseReportDto) => {
      const purchaseId = purchase?.parentId ?? purchase?.id;
      if (purchaseId && viewPrevilage) {
        return `/portal/purchase/${purchaseId}`;
      }
    }
  );

  useEffect(() => {
    if (columns.some((column) => column.includes("rights."))) {
      setPurchaseInternal(flattenPurchase(purchases));
    } else {
      setPurchaseInternal(purchases);
    }
  }, [columns, purchases]);

  useEffect(() => {
    setFilteredColumns(
      PurchaseListColumns.filter((column) => columns.includes(column.field))
    );
  }, [columns]);

  return (
    <Table
      id={tableId}
      title={title}
      data={purchasesInternal}
      columns={filteredColumns}
      allColumns={PurchaseListColumns}
      reportPrevilage={purchaseReportPrevilage}
      parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
      components={{
        Toolbar: FilterTableToolbar(
          filters,
          setFilters,
          fields,
          <ColumnPicker
            allColumns={PurchaseListColumns}
            columns={columns}
            setColumns={setColumns}
          />
        ),
      }}
      onRowClick={goToPurchaseDetail}
    />
  );
}
