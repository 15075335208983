import DefaultTheme, { Colors } from "../../../../utils/DefaultTheme";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MuiThemeProvider,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import CustomForm from "../../../common/form/CustomForm";
import { FilterCondition } from "../../../../dto/report/FilterCondition";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { FilterField } from "../../../../dto/report/FilterField";
import FilterFieldCondtionInput from "./FilterFieldCondtionInput";
import FilterFieldInput from "./FilterFieldInput";
import FilterFieldValueInput from "./FilterFieldValueInput";
import { Notification } from "../../../../utils/Notification";
import SubmitButton from "../../../common/button/SubmitButton";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
  },
  titleContainer: {
    background: Colors.PRIMARY,
    color: Colors.BACKGROUND_WHITE,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 400,
    },
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
}));

const DefaultFilterValue = (filterCondition?: FilterCondition) => {
  switch (filterCondition) {
    case FilterCondition.ALL_OF:
    case FilterCondition.ONE_OF:
    case FilterCondition.IN:
    case FilterCondition.NOT_IN:
      return [];
    case FilterCondition.BETWEEN:
      return ["", ""];
    default:
      return "";
  }
};

interface FilterTablePopupFormProps {
  open: boolean;
  filter?: FilterDto;
  setFilter: (filters: FilterDto) => void;
  onCancel: () => void;
  fields: FilterField[];
}

export default function FilterTablePopupForm({
  open,
  filter,
  setFilter,
  onCancel,
  fields,
}: FilterTablePopupFormProps) {
  const classes = useStyles();

  const [field, setField] = useState<string>();
  const [condition, setCondition] = useState<FilterCondition>();
  const [value, setValue] = useState<any>("");

  useEffect(() => {
    setField(filter?.field);
  }, [filter]);

  useEffect(() => {
    if (filter?.field !== field) {
      setCondition(undefined);
    } else {
      setCondition(filter?.condition);
    }
  }, [field, filter]);

  useEffect(() => {
    const val = DefaultFilterValue(condition);
    if (filter?.field !== field || filter?.condition !== condition) {
      setValue(val);
    } else {
      setValue(filter?.value ?? val);
    }
  }, [field, condition, filter]);

  const onSubmit = useCallback(async () => {
    if (!field) {
      Notification.showError("Please select a valid field");
      return;
    }
    if (!condition) {
      Notification.showError("Please select a valid condtion");
      return;
    }
    setFilter({ field, condition, value });
  }, [field, condition, value, setFilter]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classes.root }}
      fullScreen={fullScreen}
    >
      <MuiThemeProvider theme={DefaultTheme}>
        <DialogTitle
          id="form-dialog-title"
          disableTypography
          className={classes.titleContainer}
        >
          <Typography variant="h6" color="inherit">
            {filter ? "Update" : "Add"} Filter
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <CustomForm onSubmit={onSubmit}>
          <DialogContent className={classes.content}>
            <FilterFieldInput
              field={field}
              setField={setField}
              fields={fields}
            />
            {field && (
              <>
                <FilterFieldCondtionInput
                  condition={condition}
                  setCondition={setCondition}
                  field={field}
                  fields={fields}
                />
                {condition && (
                  <FilterFieldValueInput
                    value={value}
                    setValue={setValue}
                    field={field}
                    condition={condition}
                    fields={fields}
                  />
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <SubmitButton
              disabled={!field || !condition}
              color="primary"
              variant="text"
              type="submit"
            >
              APPLY
            </SubmitButton>
          </DialogActions>
        </CustomForm>
      </MuiThemeProvider>
    </Dialog>
  );
}
