import { useEffect, useState } from "react";

import BackTo from "../../../common/button/BackTo";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import MovieForm from "../movieForm/MovieForm";
import { getMovieDetails } from "../../../../store/movie/MovieAction";
import { useParams } from "react-router-dom";

const EditMovie = () => {
  const { movieId } = useParams<{ movieId: string }>();
  const [movie, setMovie] = useState<MovieDto>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getMovieDetails(movieId)
      .then((movie) => setMovie(movie))
      .catch(() => setError(true));
  }, [movieId]);

  return (
    <div>
      <BackTo name="Movie Detail" />
      {movie ? <MovieForm movie={movie} /> : null}
      {error ? <p>Failed to load movie detail</p> : null}
    </div>
  );
};

export default EditMovie;
