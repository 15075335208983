import { Grid, TextField } from "@material-ui/core";

import { ChangeEvent } from "react";
import { StringUtils } from "../../../../../utils/StringUtils";
import { TelevisionType } from "../../../../../dto/sale/SaleDto";

interface TelevisionFormProps {
  television?: TelevisionType;
  setTelevision: (televison: TelevisionType) => void;
}

export default function TelevisionForm({
  television,
  setTelevision,
}: TelevisionFormProps) {
  const setPermittedRuns = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setTelevision({
      ...television,
      permittedRuns: StringUtils.formatNumberField(e.target.value),
    });

  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          label="Permitted Runs"
          type="number"
          fullWidth
          value={television?.permittedRuns ?? ""}
          onChange={setPermittedRuns}
        />
      </Grid>
    </>
  );
}
