import {
  JukeboxType,
  JukeboxTypeOptions,
} from "../../../../../dto/sale/SaleDto";

import CustomAutocomplete from "../../../../common/form/CustomAutocomplete";
import { OptionWithLabelType } from "../../../../../dto/report/FilterField";

interface ChooseJukeboxProps {
  jukebox?: JukeboxType;
  setJukebox: (jukebox: JukeboxType) => void;
  label?: string;
  disabled?: boolean;
}

export function ChooseJukebox({
  jukebox,
  setJukebox,
  label,
  disabled,
}: ChooseJukeboxProps) {
  const onJukeboxChange = (value: OptionWithLabelType) =>
    setJukebox(value.name as JukeboxType);
  return (
    <CustomAutocomplete
      value={JukeboxTypeOptions.find((j) => j.name === jukebox)}
      getOptionLabel={(v) => v.label}
      onChange={onJukeboxChange}
      options={JukeboxTypeOptions}
      disabled={disabled}
      label={label ?? "Jukebox Type"}
    />
  );
}
