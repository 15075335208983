import React, { useEffect } from 'react';
import Table, { Column } from '../../../common/layout/Table';
import { getAllUsers, updateUser } from '../../../../store/user/UserAction';
import { useDispatch, useSelector } from "react-redux";

import AddButtonRow from '../../common/AddButtonRow';
import { GetPrevilage } from '../../../../utils/Previlage';
import { RootState } from '../../../../store';
import { UserDto } from '../../../../dto/user/UserDto';
import useRowClickListener from '../../../common/hooks/UseRowClickListener';

const UsersList = () => {
  const columns: Column<UserDto>[] = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone Number", field: "phoneNumber" }
  ];
  const viewPrevilage = GetPrevilage('Users', 'View');
  const addPrevilage = GetPrevilage('Users', 'Add');
  const editPrevilage = GetPrevilage('Users', 'Edit');
  const deletePrevilage = GetPrevilage('Users', 'Delete');

  const users = useSelector((state: RootState) => state.user.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const goToUserDetail = useRowClickListener((user?: UserDto) => {
    if (user?.id && viewPrevilage) {
      return `/portal/users/${user.id}`;
    }
  });

  const deleteUser = async (user: any) => {
    if (user && user.id) {
      const prepareReqBody: UserDto = {
        phoneNumber: user.phoneNumber,
        email: user.email,
        name: user.name,
        role: user.role,
        isDeleted: true
      };
      await dispatch(updateUser(user.id, prepareReqBody, 'delete'));
      dispatch(getAllUsers());
    }
  }

  return (
    <div>
      <AddButtonRow route="/portal/users/add" label="User" addPrevilage={addPrevilage} />
      <Table 
        id="users-list" 
        title="Users List" 
        data={users} 
        columns={columns} 
        reportPrevilage={editPrevilage} 
        onRowClick={goToUserDetail} 
        options={{
          actionsColumnIndex: -1
        }}
        onRowDelete={deletePrevilage ? deleteUser : undefined}
      />
    </div>
  )
}

export default UsersList;
