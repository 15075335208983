import { useDispatch, useSelector } from "react-redux";

import BackTo from "../../../common/button/BackTo";
import { RightActionTypes } from "../../../../store/right/RightTypes";
import RightGroupForm from "../rightForm/RightGroupForm";
import { RootState } from "../../../../store";
import { ThunkDispatch } from "redux-thunk";
import { getAllRights } from "../../../../store/right/RightAction";
import { selectRightGroup } from "../../../../store/right/RightSelector";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function EditRight() {
  const { rightId } = useParams<{ rightId: string }>();
  const rightGroup = useSelector(selectRightGroup(rightId));

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, RightActionTypes>>();

  useEffect(() => {
    dispatch(getAllRights());
  }, [dispatch]);

  return (
    <div>
      <BackTo name="Right Detail" />
      {rightGroup && <RightGroupForm right={rightGroup} />}
      {!rightGroup && <p>Cannot find right with the given name</p>}
    </div>
  );
}
