import { useEffect, useMemo, useState } from "react";

import { Api } from "../../../../utils/Api";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { GetPrevilage } from "../../../../utils/Previlage";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import { MovieReportFields } from "./MovieReportField";
import MovieReportResult from "./MovieReportResult";
import { Notification } from "../../../../utils/Notification";
import { Typography } from "@material-ui/core";
import useMovieAutocomplete from "../../../common/hooks/UseMovieAutocomplete";
import usePersistedState from "../../../common/hooks/UsePersistedState";

const MovieReport = () => {
  const { state: filters, setState: setFilters } = usePersistedState<
    FilterDto[]
  >("movie-report-filters", []);
  const [movies, setMovies] = useState<MovieDto[]>([]);
  const movieReportPrevilage = GetPrevilage('Reports', 'Movie');

  useEffect(() => {
    if(movieReportPrevilage) {
      Api()
        .post<MovieDto[]>("/reports/movies", { filters })
        .then((res) => {
          setMovies(res.data);
        })
        .catch((err) => {
          Notification.showApiError(err, "Unable to get movies report");
        });
    }
  }, [filters, movieReportPrevilage]);

  const movieOptions = useMovieAutocomplete();

  const fields = useMemo(() => MovieReportFields(movieOptions), [movieOptions]);

  return (
    <>
      {movieReportPrevilage ? (
          <div>
            <Typography variant="h5">Movies Report</Typography>
            <MovieReportResult
              movies={movies}
              filters={filters}
              setFilters={setFilters}
              fields={fields}
            />
          </div>
        ) : (
          null
        )}
    </>
  );
};

export default MovieReport;
