export enum MovieFieldNames {
  MOVIE_ID = "MovieId",
  NAME = "Name",
  WORKING_TITLE = "WorkingTitle",
  RELEASE_TITLE = "ReleaseTitle",
  MOVIE_LANGUAGE = "MovieLanguage",
  MOVIE_GENRE = "MovieGenre",
  FORMAT = "Format",
  RELEASE_DATE = "ReleaseDate",
  SOURCE_AVAILABILITY = "SourceAvailability",
  DIRECTOR = "Director",
  ACTOR = "Actor",
  CINEMATOGRAPHER = "Cinematographer",
  LYRICIST = "Lyricist",
  MUSIC_DIRECTOR = "MusicDirector",
  PRODUCER = "Producer",
  SINGER = "Singer",
  POSTERS = "PostersCount",
  TRAILERS = "TrailersCount",
}
