import { RECEIVE_RIGHTS, REQUEST_RIGHTS, RightActionTypes } from "./RightTypes";

import { Reducer } from "redux";
import RightGroupDto from "../../dto/right/RightGroupDto";
import RightTypeDto from "../../dto/right/RightTypeDto";

export interface RightState {
  rights: RightTypeDto[];
  rightGroups: RightGroupDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: RightState = {
  rights: [],
  rightGroups: [],
  loading: false,
  loaded: false,
};

const RightReducer: Reducer<RightState, RightActionTypes> = (
  state: RightState = initialState,
  action: RightActionTypes
) => {
  switch (action.type) {
    case REQUEST_RIGHTS:
      return { ...state, loading: true };
    case RECEIVE_RIGHTS:
      return {
        rights: [...action.rights],
        rightGroups: [...action.rightGroups],
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
};

export default RightReducer;
