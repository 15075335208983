import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextareaAutosize,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useCallback, useContext, useEffect, useState } from "react";

import { AuthContext } from "../../auth/AuthProvider";
import CloseIcon from "@material-ui/icons/Close";
import { Colors } from "../../../utils/DefaultTheme";
import CustomForm from "../../common/form/CustomForm";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { RemarkDto } from "../../../dto/purchase/remarks/RemarkDto";
import SubmitButton from "../../common/button/SubmitButton";

const useStyle = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.main,
    color: Colors.BACKGROUND_WHITE,
  },
  title: {
    fontWeight: 500,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1.5),
    },
  },
}));

interface RemarkFormPopupProps {
  open: boolean;
  onClose?: () => void;
  label: string;
  onAddRemark?: (remark: RemarkDto) => Promise<void>;
  remark?: RemarkDto;
}

export default function MediaFormPopup({
  open,
  onClose,
  label,
  onAddRemark,
  remark,
}: RemarkFormPopupProps) {
  const classes = useStyle();
  const authDetails = useContext(AuthContext);
  const [comment, setComment] = useState<string>("");

  const clearInput = useCallback(() => {
    setComment("");
  }, []);

  useEffect(() => {
    setComment(remark?.remark ?? "");
  }, [remark]);

  const remarkSubmit = async () => {
    if (!remark) {
      await onAddRemark?.({
        id: "",
        remark: comment,
        createdBy: {
          id: authDetails.auth.user?.id ?? "",
          name: authDetails.auth.user?.name ?? "",
          email: authDetails.auth.user?.email ?? "",
          phoneNumber: authDetails.auth.user?.phoneNumber ?? "",
          role: authDetails.auth.user?.role ?? "",
          isDeleted: authDetails.auth.user?.isDeleted ?? false,
        },
        timeCreated: new Date(Date.now()),
        timeUpdated: new Date(Date.now()),
      });
    } else {
      await onAddRemark?.(
        {
          id: remark.id,
          remark: comment,
          createdBy: remark.createdBy,
          timeCreated: remark.timeCreated,
          timeUpdated: new Date(Date.now()),
        });
    }
    clearInput();
  };

  const onUpdateComment = useCallback((e) => setComment(e.target.value), []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="add-remark-dialog-title"
    >
      <MuiDialogTitle
        disableTypography
        id="add-remark-dialog-title"
        className={classes.header}
      >
        <Typography variant="h6" color="inherit" className={classes.title}>
          {remark ? "Update" : "Add new"} {label}
        </Typography>
        <IconButton
          color="inherit"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <CustomForm onSubmit={remarkSubmit} className={classes.form}>
          <TextareaAutosize
            minRows={5}
            maxRows={8}
            aria-label="Remark"
            placeholder="You can add your remark."
            value={comment}
            onChange={onUpdateComment}
            style={{ width: 400, resize: "none" }}
          />
          <Box className={classes.buttonContainer}>
            <SubmitButton
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
            >
              SUBMIT
            </SubmitButton>
            <Button variant="text" color="primary" size="medium" onClick={onClose}>
              CANCEL
            </Button>
          </Box>
        </CustomForm>
      </DialogContent>
    </Dialog>
  );
}
