import {
  getMovieDetails,
  updateMovie,
} from "../../../../store/movie/MovieAction";
import { useCallback, useEffect, useState } from "react";

import EditItemRow from "../../common/EditItemRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { Grid } from "@material-ui/core";
import { MediaDto } from "../../../../dto/common/MediaDto";
import MediaListAndUpdate from "../../media/MediaListAndUpdate";
import { MovieActionTypes } from "../../../../store/movie/MovieTypes";
import MovieBasicDetails from "./MovieBasicDetails";
import MovieCrewDetails from "./MovieCrewDetails";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import MoviePurchaseReport from "../../report/purchaseReport/MoviePurchaseReport";
import MovieSalesReport from "../../report/salesReport/MovieSalesReport";
import MovieSongDetails from "./MovieSongDetails";
import { RootState } from "../../../../store";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

type MovieMediaKeys = "posters" | "trailers";

const MovieDetails = () => {
  const editPrevilage = GetPrevilage('Movies', 'Edit');
  const purchaseViewPrevilage = GetPrevilage('Purchase', 'View');
  const salesViewPrevilage = GetPrevilage('Sales', 'View');
    
  const { movieId } = useParams<{ movieId: string }>();

  const [movie, setMovie] = useState<MovieDto>();
  const [error, setError] = useState<boolean>(false);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, MovieActionTypes>>();

  const getMovie = useCallback(() => {
    getMovieDetails(movieId)
      .then(setMovie)
      .catch(() => setError(true));
  }, [movieId]);

  useEffect(getMovie, [getMovie]);

  const onUpdateMedia = async (key: MovieMediaKeys, medias: MediaDto[]) => {
    if (movie) {
      const newMovie: MovieDto = { ...movie };
      newMovie[key] = medias;
      delete newMovie["id"];

      await dispatch(updateMovie(movieId, newMovie));
      getMovie();
    }
  };

  const onUploadFiles = async (key: MovieMediaKeys, media: MediaDto) => {
    if (movie) {
      let medias = movie[key] ?? [];
      medias = medias.concat(media);
      await onUpdateMedia(key, medias);
    }
  };

  return (
    <div>
      <EditItemRow
        backLabel="Movies"
        backRoute="/portal/movies"
        editLabel="Movie"
        editPrevilage={editPrevilage}
        editRoute={movie && `/portal/movies/${movie?.id}/edit`}
      />
      {error ? <p>Failed to fetch movie details</p> : null}
      {movie ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MovieBasicDetails movie={movie} />
          </Grid>
          <Grid item xs={12}>
            <MovieCrewDetails movie={movie} />
          </Grid>
          <Grid item xs={12}>
            <MovieSongDetails movie={movie} />
          </Grid>
          <Grid item xs={12}>
            <MediaListAndUpdate
              name="posters"
              key="Poster"
              label="Poster"
              medias={movie.posters}
              onUpdateMedias={onUpdateMedia}
              onUploadMedia={onUploadFiles}
              editPrevilage={editPrevilage ? editPrevilage : false}
            />
          </Grid>
          <Grid item xs={12}>
            <MediaListAndUpdate
              name="trailers"
              key="Trailers"
              label="Trailers"
              medias={movie.trailers}
              onUpdateMedias={onUpdateMedia}
              onUploadMedia={onUploadFiles}
              editPrevilage={editPrevilage ? editPrevilage : false}
            />
          </Grid>
          {purchaseViewPrevilage ? (
            <Grid item xs={12}>
              <MoviePurchaseReport movie={movie} />
            </Grid>
          ) : null}
          {salesViewPrevilage ? (
            <Grid item xs={12}>
              <MovieSalesReport movie={movie} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </div>
  );
};

export default MovieDetails;
