import { FilterField, OptionType } from "../../../../dto/report/FilterField";
import {
  InternetMediaOptions,
  JukeboxSongTypeOptions,
  JukeboxTypeOptions,
  PlatformOptions,
} from "../../../../dto/sale/SaleDto";

import { CountrySetOptions } from "../../../common/hooks/UseCountrySetAutocomplete";
import { CurrencyOptions } from "../../../../dto/common/Currency";
import { FilterFieldType } from "../../../../dto/report/FilterFieldType";
import FilterUtils from "../../../../utils/FilterUtils";
import { MovieFieldNames } from "../../../../dto/report/movie/MovieFieldNames";
import { MovieOptions } from "../../../common/hooks/UseMovieAutocomplete";
import { RightFieldNames } from "../../../../dto/report/right/RightFieldNames";
import { SaleOptions } from "../../../common/hooks/UseSaleAutocomplete";
import { SalesFieldNames } from "../../../../dto/report/sales/SalesFieldNames";

export const SalesReportFields = (
  movieOptions: MovieOptions,
  saleOptions: SaleOptions,
  rightOptions: OptionType[],
  countrySetOptions: CountrySetOptions
): FilterField[] => [
  { name: "Movie", type: FilterFieldType.STRING, isParent: true },
  {
    name: MovieFieldNames.NAME,
    type: FilterFieldType.STRING,
    options: movieOptions.name,
  },
  {
    name: MovieFieldNames.WORKING_TITLE,
    type: FilterFieldType.STRING,
    options: movieOptions.workingTitle,
  },
  {
    name: MovieFieldNames.RELEASE_TITLE,
    type: FilterFieldType.STRING,
    options: movieOptions.releaseTitle,
  },
  {
    name: MovieFieldNames.MOVIE_LANGUAGE,
    type: FilterFieldType.STRING,
    options: movieOptions.language,
  },
  {
    name: MovieFieldNames.MOVIE_GENRE,
    type: FilterFieldType.STRING,
    options: movieOptions.genre,
  },
  {
    name: MovieFieldNames.FORMAT,
    type: FilterFieldType.STRING,
    options: movieOptions.format,
  },
  { name: MovieFieldNames.RELEASE_DATE, type: FilterFieldType.DATE },
  {
    name: MovieFieldNames.SOURCE_AVAILABILITY,
    type: FilterFieldType.BOOLEAN,
    options: FilterUtils.BooleanFilterOptions,
  },
  {
    name: MovieFieldNames.DIRECTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.ACTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.CINEMATOGRAPHER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.LYRICIST,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.MUSIC_DIRECTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.PRODUCER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.SINGER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  { name: "Sales", type: FilterFieldType.STRING, isParent: true },
  {
    name: SalesFieldNames.SALE_LANGUAGE,
    type: FilterFieldType.STRING,
    options: saleOptions.language,
  },
  {
    name: SalesFieldNames.SALE_PLATFORM,
    type: FilterFieldType.STRING,
    options: PlatformOptions,
  },
  {
    name: SalesFieldNames.SALE_CUSTOMER_NAME,
    type: FilterFieldType.STRING,
    options: saleOptions.customerName,
  },
  { name: SalesFieldNames.SALE_DATE_OF_PUBLISH, type: FilterFieldType.DATE },
  { name: SalesFieldNames.PERMITTED_RUNS, type: FilterFieldType.NUMBER },
  {
    name: SalesFieldNames.INTERNET_MEDIA,
    type: FilterFieldType.STRING,
    options: InternetMediaOptions,
  },
  {
    name: SalesFieldNames.INTERNET_CHANNEL_NAME,
    type: FilterFieldType.STRING,
    options: saleOptions.channelName,
  },
  {
    name: SalesFieldNames.JUKEBOX_TYPE,
    type: FilterFieldType.STRING,
    options: JukeboxTypeOptions,
  },
  {
    name: SalesFieldNames.JUKEBOX_SONG_TYPE,
    type: FilterFieldType.STRING,
    options: JukeboxSongTypeOptions,
  },
  {
    name: SalesFieldNames.JUKEBOX_NAME,
    type: FilterFieldType.STRING,
    options: saleOptions.jukeboxName,
  },
  {
    name: SalesFieldNames.JUKEBOX_VIDEO_ID,
    type: FilterFieldType.STRING,
    options: saleOptions.jukeboxVideoId,
  },
  {
    name: SalesFieldNames.JUKEBOX_ITEM_DURATION,
    type: FilterFieldType.TIME_LENGTH,
  },
  { name: SalesFieldNames.JUKEBOX_DURATION, type: FilterFieldType.TIME_LENGTH },
  { name: SalesFieldNames.AGREEMENT_DATE, type: FilterFieldType.DATE },
  { name: SalesFieldNames.VALIDITY, type: FilterFieldType.DURATION },
  { name: SalesFieldNames.SALE_AMOUNT, type: FilterFieldType.CURRENCY },
  {
    name: SalesFieldNames.SALE_AMOUNT_CURRENCY,
    type: FilterFieldType.STRING,
    options: CurrencyOptions,
  },
  {
    name: SalesFieldNames.SALE_AGREEMENTS,
    type: FilterFieldType.NUMBER,
  },
  {
    name: SalesFieldNames.SALE_INVOICES,
    type: FilterFieldType.NUMBER,
  },
  { name: "Rights", type: FilterFieldType.STRING, isParent: true },
  {
    name: RightFieldNames.RIGHT_TYPE,
    type: FilterFieldType.LIST,
    options: rightOptions,
  },
  {
    name: RightFieldNames.RIGHT_COUNTRY,
    type: FilterFieldType.LIST,
    options: countrySetOptions.country,
  },
];
