import { BoxProps, Grid } from "@material-ui/core";

import { DateUtils } from "../../../../utils/DateUtils";
import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import { PurchaseDto } from "../../../../dto/purchase/PurchaseDto";
import { ReactNode } from "react";
import { StringUtils } from "../../../../utils/StringUtils";

const getDetailsMap = (
  purchase: PurchaseDto
): Array<{ label: string; value: ReactNode; link?: string }> => {
  return [
    {
      label: "Movie",
      value: purchase.movie.name,
      link: `/portal/movies/${purchase.movie.id}`,
    },
    { label: "Movie Language", value: purchase.movie.language },
    { label: "Production Banner", value: purchase.productionBanner },
    { label: "Seller", value: purchase.seller },
    { label: "Buyer", value: purchase.buyer },
    {
      label: "Agreeement Date",
      value: DateUtils.getFormattedDate(purchase.agreementDate),
    },
    {
      label: "Agreement Expiry",
      value: StringUtils.formatAgreementExpiry(purchase.agreementExpiry),
    },
    {
      label: "Validity",
      value: DateUtils.getFormattedDuration(
        purchase.validFrom,
        purchase.validTill
      ),
    },
  ];
};

interface PurchaseBasicDetailsProps {
  purchase: PurchaseDto;
}

export default function PurchaseBasicDetails({
  purchase,
  ...props
}: PurchaseBasicDetailsProps & BoxProps) {
  const purchaseData = getDetailsMap(purchase);

  return (
    <DetailCard title="Purchase information" {...props}>
      <Grid container spacing={2}>
        {purchaseData.map((data, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <DetailItem label={data.label} link={data.link}>
              {data.value}
            </DetailItem>
          </Grid>
        ))}
      </Grid>
    </DetailCard>
  );
}
