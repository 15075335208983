import {
  JukeboxSongType,
  JukeboxSongTypeOptions,
} from "../../../../../dto/sale/SaleDto";

import CustomAutocomplete from "../../../../common/form/CustomAutocomplete";

interface ChooseJukeboxSongTypeProps {
  jukeboxSong?: JukeboxSongType;
  setJukeboxSong: (jukeboxSong: JukeboxSongType) => void;
  label?: string;
  disabled?: boolean;
}

export function ChooseJukeboxSongType(props: ChooseJukeboxSongTypeProps) {
  return (
    <CustomAutocomplete
      value={props.jukeboxSong ?? null}
      onChange={props.setJukeboxSong}
      label={props.label ?? "Jukebox Song Type"}
      options={JukeboxSongTypeOptions}
      disabled={props.disabled}
    />
  );
}
