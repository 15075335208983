import React, { useEffect, useState } from 'react'

import BackTo from '../../../common/button/BackTo';
import { RoleDto } from '../../../../dto/role/RoleDto';
import RoleForm from '../roleForm/RoleForm';
import { getRoleDetails } from '../../../../store/role/RoleAction';
import { useParams } from 'react-router-dom';

const EditRole = () => {
  const { roleId } = useParams<{ roleId: string }>();
  const [role, setRole] = useState<RoleDto>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getRoleDetails(roleId)
      .then((role) => setRole(role))
      .catch(() => setError(true));
  }, [roleId]);

  return (
    <div>
      <BackTo name="Role Detail" />
      {role ? <RoleForm role={role} /> : null}
      {error ? <p>Failed to load role detail</p> : null}
    </div>
  )
}

export default EditRole
