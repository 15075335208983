import RightGroupDto from "../../dto/right/RightGroupDto";
import RightTypeDto from "../../dto/right/RightTypeDto";

export const REQUEST_RIGHTS = "REQUEST_RIGHTS";
export const RECEIVE_RIGHTS = "RECEIVE_RIGHTS";

interface RequestRightsAction {
  type: typeof REQUEST_RIGHTS;
}

interface ReceiveRightsAction {
  type: typeof RECEIVE_RIGHTS;
  rights: RightTypeDto[];
  rightGroups: RightGroupDto[];
}

export type RightActionTypes = RequestRightsAction | ReceiveRightsAction;
