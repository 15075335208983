import { RECEIVE_REMARK, REQUEST_REMARK, RemarkActionTypes } from "./RemarkTypes";

import { Reducer } from "redux";
import { RemarkDto } from "../../dto/purchase/remarks/RemarkDto";

export interface RemarkState {
  remarks: RemarkDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: RemarkState = {
  remarks: [],
  loading: false,
  loaded: false,
};

const RemarkReducer: Reducer<RemarkState, RemarkActionTypes> = (
  state: RemarkState = initialState,
  action: RemarkActionTypes
) => {
  switch (action.type) {
    case REQUEST_REMARK:
      return { ...state, loading: true };
    case RECEIVE_REMARK:
      const remarks = action.payload;
      return {
        ...state,
        remarks: [...remarks],
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
};

export default RemarkReducer;
