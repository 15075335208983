import { SaleDto, SaleReportDto } from "../../../../dto/sale/SaleDto";
import { useEffect, useState } from "react";

import ColumnPicker from "../common/ColumnPicker";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { FilterField } from "../../../../dto/report/FilterField";
import FilterTableToolbar from "../common/FilterTableToolbar";
import { GetPrevilage } from "../../../../utils/Previlage";
import { SalesColumns } from "../../../../dto/report/sales/SalesColumns";
import Table from "../../../common/layout/Table";
import usePersistedState from "../../../common/hooks/UsePersistedState";
import useRowClickListener from "../../../common/hooks/UseRowClickListener";

const SaleListColumns = Object.values(SalesColumns);

const flattenSale = (sales: SaleReportDto[]) => {
  const expandedSales = sales.flatMap((sale) => {
    if (sale.rights.length <= 1) {
      return sale;
    }
    const flattenedSale: SaleReportDto[] = [
      { ...sale, rights: [] },
      ...sale.rights.map(
        (right, index) =>
          ({
            rights: [right],
            parentId: sale.id,
            id: `${sale.id}-${index}`,
          } as SaleReportDto)
      ),
    ];
    return flattenedSale;
  });
  return expandedSales;
};

interface SalesReportResultProps {
  sales: SaleDto[];
  filters: FilterDto[];
  setFilters?: (filters: FilterDto[]) => void;
  fields: FilterField[];
  tableId?: string;
  title?: string;
}

export default function SalesReportResult({
  sales,
  filters,
  setFilters,
  fields,
  tableId = "sales-report",
  title = "Sales List",
}: SalesReportResultProps) {
  const { state: columns, setState: setColumns } = usePersistedState<string[]>(
    `${tableId}-columns`,
    [
      SalesColumns.Name,
      SalesColumns.ReleaseDate,
      SalesColumns.Actor,
      SalesColumns.SalePlatform,
      SalesColumns.SaleCustomerName,
      SalesColumns.SaleDateOfPublish,
      SalesColumns.Validity,
      SalesColumns.AgreementDate,
    ].map((column) => column.field)
  );

  const [filteredColumns, setFilteredColumns] = useState(SaleListColumns);
  const [salesInternal, setSalesInternal] = useState<SaleReportDto[]>([]);
  const viewPrevilage = GetPrevilage('Sales', 'View');
  const salesReportPrevilage = GetPrevilage('Reports', 'Sales');

  const goToSaleDetail = useRowClickListener((sale?: SaleDto) => {
    if (sale?.id && viewPrevilage) {
      return `/portal/sales/${sale.id}`;
    }
  });

  useEffect(() => {
    if (columns.filter((column) => column.includes("rights.")).length > 0) {
      setSalesInternal(flattenSale(sales));
    } else {
      setSalesInternal(sales);
    }
  }, [columns, sales]);

  useEffect(() => {
    setFilteredColumns(
      SaleListColumns.filter((column) => columns.includes(column.field))
    );
  }, [columns]);

  return (
    <Table
      id={tableId}
      title={title}
      data={salesInternal}
      columns={filteredColumns}
      allColumns={SaleListColumns}
      reportPrevilage={salesReportPrevilage}
      parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
      components={{
        Toolbar: FilterTableToolbar(
          filters,
          setFilters,
          fields,
          <ColumnPicker
            allColumns={SaleListColumns}
            columns={columns}
            setColumns={setColumns}
          />
        ),
      }}
      onRowClick={goToSaleDetail}
    />
  );
}
