export enum SalesFieldNames {
  SALE_LANGUAGE = "SaleLanguage",
  SALE_PLATFORM = "SalePlatform",
  SALE_CUSTOMER_NAME = "SaleCustomerName",
  SALE_DATE_OF_PUBLISH = "SaleDateOfPublish",
  PERMITTED_RUNS = "PermittedRuns",
  INTERNET_MEDIA = "InternetMedia",
  INTERNET_CHANNEL_NAME = "InternetChannelName",
  JUKEBOX_TYPE = "JukeboxType",
  JUKEBOX_SONG_TYPE = "JukeboxSongType",
  JUKEBOX_NAME = "JukeboxName",
  JUKEBOX_VIDEO_ID = "JukeboxVideoId",
  JUKEBOX_ITEM_DURATION = "JukeboxItemDuration",
  JUKEBOX_DURATION = "JukeboxDuration",
  AGREEMENT_DATE = "AgreementDate",
  VALIDITY = "Validity",
  SALE_AMOUNT = "SaleAmount",
  SALE_AMOUNT_CURRENCY = "SaleAmountCurrency",
  SALE_AMOUNT_VALUE = "SaleAmountValue",
  SALE_AGREEMENTS = "SaleAgreementCount",
  SALE_INVOICES = "SaleInvoicesCount",
}
