import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";
import { ChangeEvent, useCallback } from "react";
import { Chip, TextField, TextFieldProps } from "@material-ui/core";

import FilterUtils from "../../../utils/FilterUtils";
import { OptionType } from "../../../dto/report/FilterField";
import { withStyles } from "@material-ui/core";

const AutocompleteChip = withStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    border: "none",
  },
}))(Chip);

const RenderTags =
  (options: OptionType[]) =>
  (value: any[], getTagProps: AutocompleteGetTagProps) =>
    (Array.isArray(value) ? value : [value]).map((option, index) => (
      <AutocompleteChip
        variant="outlined"
        label={FilterUtils.findOptionLabel(options)(option)}
        {...getTagProps({ index })}
      />
    ));

const AutocompleteInput =
  (value: any[], required: boolean, props: TextFieldProps) =>
  (params: AutocompleteRenderInputParams) => {
    const hasError = required && value.length === 0;
    return (
      <TextField
        {...props}
        {...params}
        helperText={
          props.helperText ?? `Please press enter after each ${props.label}`
        }
        required={hasError}
        variant={props.variant ?? "standard"}
      />
    );
  };
interface TextFieldArrayProps {
  value: any[];
  onUpdate: (value: any[]) => void;
  options?: OptionType[];
  freeSolo?: boolean;
}

export default function TextFieldArray({
  value,
  onUpdate,
  options = [],
  freeSolo = true,
  required = false,
  ...props
}: TextFieldArrayProps & TextFieldProps) {
  const onChange = useCallback(
    (event: ChangeEvent<{}>, v: string[]) => (event ? onUpdate?.(v) : null),
    [onUpdate]
  );

  return (
    <Autocomplete
      multiple
      autoHighlight={true}
      options={FilterUtils.flattenOptions(options)}
      getOptionLabel={FilterUtils.findOptionLabel(options)}
      freeSolo={freeSolo}
      value={value}
      onChange={onChange}
      renderTags={RenderTags(options)}
      renderInput={AutocompleteInput(value, required, props)}
    />
  );
}
