import { BoxProps, Grid } from "@material-ui/core";
import {
  InternetMediaType,
  JukeboxType,
  PlatformType,
  SaleDto,
} from "../../../../dto/sale/SaleDto";

import { DateUtils } from "../../../../utils/DateUtils";
import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import { ReactNode } from "react";
import TimeUtils from "../../../../utils/TimeUtils";

type DetailMapType = Array<{ label: string; value: ReactNode }>;

const getInternetMediaSpecificMap = (sale: SaleDto): DetailMapType => {
  switch (sale.internet?.media) {
    case InternetMediaType.Jukebox:
      return [
        { label: "Jukebox Type", value: sale.internet.jukebox?.type },
        {
          label: "Duration of Item",
          value: TimeUtils.formatTime(sale.internet.jukebox?.itemDuration),
        },
        {
          label: "Duration of Jukebox",
          value: TimeUtils.formatTime(sale.internet.jukebox?.jukeboxDuration),
        },
        {
          label: "Jukebox Name",
          value: sale.internet.jukebox?.name,
        },
        {
          label: "Jukebox VideoId",
          value: sale.internet.jukebox?.videoId,
        },
        ...(sale.internet.jukebox?.type === JukeboxType.Song
          ? [
              {
                label: "Jukebox song type",
                value: sale.internet.jukebox?.songType,
              },
            ]
          : []),
      ];
    default:
      return [];
  }
};

const getPlatformSpecificMap = (sale: SaleDto): DetailMapType => {
  switch (sale.platform) {
    case PlatformType.Television:
      return [
        { label: "Permitted Runs", value: sale.television?.permittedRuns },
      ];
    case PlatformType.Internet:
      return [
        { label: "Media", value: sale.internet?.media },
        {
          label: "Channel Name",
          value: sale.internet?.channelName,
        },
        ...getInternetMediaSpecificMap(sale),
      ];
    default:
      return [];
  }
};

const getDetailsMap = (sale: SaleDto): DetailMapType => [
  { label: "Platform", value: sale.platform },
  { label: "Customer Name", value: sale.customerName },
  ...(sale.platform === PlatformType.Internet ||
  sale.platform === PlatformType.PayPerUse
    ? [
        {
          label: "Date of Publishing",
          value: DateUtils.getFormattedDate(sale.dateOfPublishing),
        },
      ]
    : []),
  ...getPlatformSpecificMap(sale),
];

interface SalePlatformDetailsProps {
  sale: SaleDto;
}

export default function SalesPlatformDetails({
  sale,
  ...props
}: SalePlatformDetailsProps & BoxProps) {
  const saleData = getDetailsMap(sale);

  return (
    <DetailCard title="Platform information" {...props}>
      <Grid container spacing={2}>
        {saleData.map((data, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <DetailItem label={data.label}>{data.value}</DetailItem>
          </Grid>
        ))}
      </Grid>
    </DetailCard>
  );
}
