import Table, { Column } from "../../../common/layout/Table";
import { deleteSale, getAllSales } from "../../../../store/sale/SaleAction";
import { useDispatch, useSelector } from "react-redux";

import AddButtonRow from "../../common/AddButtonRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { RootState } from "../../../../store";
import { SaleDto } from "../../../../dto/sale/SaleDto";
import { SalesColumns } from "../../../../dto/report/sales/SalesColumns";
import { useEffect } from "react";
import useRowClickListener from "../../../common/hooks/UseRowClickListener";

const SaleListColumns = Object.values(SalesColumns);

const SalesList = () => {
  const columns: Column<SaleDto>[] = [
    SalesColumns.Name,
    SalesColumns.SaleLanguage,
    SalesColumns.SalePlatform,
    SalesColumns.SaleCustomerName,
    SalesColumns.AgreementDate,
    SalesColumns.Validity,
  ];

  const addPrevilage = GetPrevilage('Sales', 'Add');
  const viewPrevilage = GetPrevilage('Sales', 'View');
  const salesReportPrevilage = GetPrevilage('Reports', 'Sales');
  const deletePrevilage = GetPrevilage('Sales', 'Delete');

  const sales = useSelector((state: RootState) => state.sale.sales);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSales(true));
  }, [dispatch]);

  const goToSaleDetail = useRowClickListener((sale?: SaleDto) => {
    if (sale?.id && viewPrevilage) {
      return `/portal/sales/${sale.id}`;
    }
  });

  const deletePurchaseFn = async (sale: any) => {
    if (sale && sale.id) {
      await dispatch(deleteSale(sale.id));
      dispatch(getAllSales());
    }
  }

  return (
    <div>
      <AddButtonRow route="/portal/sales/add" label="Sale" addPrevilage={addPrevilage} />
      <Table
        id="sales-list"
        title="Sales List"
        data={sales}
        columns={columns}
        allColumns={SaleListColumns}
        reportPrevilage={salesReportPrevilage}
        onRowClick={goToSaleDetail}
        options={{
          actionsColumnIndex: -1
        }}
        onRowDelete={deletePrevilage ? deletePurchaseFn : undefined}
      />
    </div>
  );
};

export default SalesList;
