import { Route, Switch, useRouteMatch } from "react-router-dom";

import { GetPrevilage } from "../../../utils/Previlage";
import { PAGE_NOT_AUTHORIZED } from "../../../constants/constants";
import SaleAdd from "./salesAdd/SalesAdd";
import SaleDetails from "./salesDetails/SalesDetails";
import SaleEdit from "./salesEdit/SalesEdit";
import SalesList from "./salesList/SalesList";

const Sales = () => {
  const match = useRouteMatch();
  const viewPrevilage = GetPrevilage('Sales', 'View');

  return (
    <>
      {viewPrevilage ? (
        <Switch>
          <Route path={match.path} exact component={SalesList} />
          <Route path={`${match.path}/add`} component={SaleAdd} />
          <Route path={`${match.path}/:saleId`} exact component={SaleDetails} />
          <Route path={`${match.path}/:saleId/edit`} component={SaleEdit} />
        </Switch>
      ) : (
        <span>{PAGE_NOT_AUTHORIZED}</span>
      )}
    </>
  );
};

export default Sales;
