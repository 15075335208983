import { ChangeEvent, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";

import { FilterField } from "../../../../dto/report/FilterField";
import { StringUtils } from "../../../../utils/StringUtils";

const CustomListSubHeader = withStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
  },
}))(ListSubheader);

interface FilterFieldInputProps {
  field?: string;
  setField: (field: string) => void;
  fields: FilterField[];
}

export default function FilterFieldInput({
  field,
  setField,
  fields,
}: FilterFieldInputProps) {
  const onChange = useCallback(
    (
      e: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setField(e.target.value as string);
    },
    [setField]
  );

  return (
    <FormControl fullWidth required>
      <InputLabel id="field-name-label">Field</InputLabel>
      <Select
        labelId="field-name-label"
        name="field"
        value={field ?? ""}
        onChange={onChange}
      >
        {fields.map((field) =>
          field.isParent ? (
            <CustomListSubHeader color="primary" key={field.name}>
              {StringUtils.formatFieldName(field.name)}
            </CustomListSubHeader>
          ) : (
            <MenuItem key={field.name} value={field.name}>
              {StringUtils.formatFieldName(field.name)}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
