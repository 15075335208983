import { Grid, GridProps, Typography, makeStyles } from "@material-ui/core";

import { Colors } from "../../../utils/DefaultTheme";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginTop: theme.spacing(1.5),
    color: Colors.GREY_0,
  },
}));

interface FormSectionProps {
  label: string;
  wrapSection?: boolean;
}

export default function FormSection({
  label,
  wrapSection = false,
  children,
  ...props
}: FormSectionProps & GridProps) {
  const classes = useStyles();
  return (
    <Grid {...props} container>
      <Grid
        item
        xs={12}
        md={wrapSection ? 12 : 3}
        className={classes.titleContainer}
      >
        <Typography variant="body1" color="inherit">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} md={wrapSection ? 12 : 9}>
        {children}
      </Grid>
    </Grid>
  );
}
