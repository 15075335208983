import { FilterField } from "../../../../dto/report/FilterField";
import { FilterFieldType } from "../../../../dto/report/FilterFieldType";
import FilterUtils from "../../../../utils/FilterUtils";
import { MovieFieldNames } from "../../../../dto/report/movie/MovieFieldNames";
import { MovieOptions } from "../../../common/hooks/UseMovieAutocomplete";

export const MovieReportFields = (
  movieOptions: MovieOptions
): FilterField[] => [
  {
    name: MovieFieldNames.NAME,
    type: FilterFieldType.STRING,
    options: movieOptions.name,
  },
  {
    name: MovieFieldNames.WORKING_TITLE,
    type: FilterFieldType.STRING,
    options: movieOptions.workingTitle,
  },
  {
    name: MovieFieldNames.RELEASE_TITLE,
    type: FilterFieldType.STRING,
    options: movieOptions.releaseTitle,
  },
  {
    name: MovieFieldNames.MOVIE_LANGUAGE,
    type: FilterFieldType.STRING,
    options: movieOptions.language,
  },
  {
    name: MovieFieldNames.MOVIE_GENRE,
    type: FilterFieldType.STRING,
    options: movieOptions.genre,
  },
  {
    name: MovieFieldNames.FORMAT,
    type: FilterFieldType.STRING,
    options: movieOptions.format,
  },
  { name: MovieFieldNames.RELEASE_DATE, type: FilterFieldType.DATE },
  {
    name: MovieFieldNames.SOURCE_AVAILABILITY,
    type: FilterFieldType.BOOLEAN,
    options: FilterUtils.BooleanFilterOptions,
  },
  {
    name: MovieFieldNames.DIRECTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.ACTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.CINEMATOGRAPHER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.LYRICIST,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.MUSIC_DIRECTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.PRODUCER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.SINGER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.POSTERS,
    type: FilterFieldType.NUMBER,
  },
  {
    name: MovieFieldNames.TRAILERS,
    type: FilterFieldType.NUMBER,
  },
];
