import ResponsiveDrawer, {
  TabType,
} from "../../common/layout/ResponsiveDrawer";

import Admin from "../admin/Admin";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CountrySet from "../countrySet/CountrySet";
import Dashboard from "../dashboard/Dashboard";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { GetPrevilage } from "../../../utils/Previlage";
import LockIcon from "@material-ui/icons/Lock";
import Logout from "../../auth/logout/Logout";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Movie from "../movie/Movie";
import MovieIcon from "@material-ui/icons/Movie";
import PeopleIcon from "@material-ui/icons/People";
import PolicyIcon from "@material-ui/icons/Policy";
import PublicIcon from "@material-ui/icons/Public";
import Purchase from "../purchase/Purchase";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Report from "../report/Report";
import RightPage from "../right/RightPage";
import Roles from "../roles/Roles";
import Sales from "../sales/Sales";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Users from "../user/User";

export default function PortalLayout() {
  const PortalTabs: TabType[] = [
    {
      id: "dashboard",
      icon: DashboardIcon,
      label: "Dashboard",
      component: Dashboard,
      isVisible: true,
    },
    {
      id: "users",
      icon: PeopleIcon,
      label: "Users",
      component: Users,
      isVisible: GetPrevilage('Users', 'View'),
    },
    {
      id: "movies",
      icon: MovieIcon,
      label: "Movies",
      component: Movie,
      isVisible: GetPrevilage('Movies', 'View'),
    },
    {
      id: "rights",
      icon: PolicyIcon,
      label: "Rights",
      component: RightPage,
      isVisible: GetPrevilage('Rights', 'View'),
    },
    {
      id: "country-set",
      icon: PublicIcon,
      label: "Territory Set",
      component: CountrySet,
      isVisible: GetPrevilage('CountrySet', 'View'),
    },
    {
      id: "purchase",
      icon: ShoppingCartIcon,
      label: "Purchase",
      component: Purchase,
      isVisible: GetPrevilage('Purchase', 'View'),
    },
    {
      id: "sales",
      icon: ReceiptIcon,
      label: "Sales",
      component: Sales,
      isVisible: GetPrevilage('Sales', 'View'),
    },
    {
      id: "report",
      icon: AssessmentIcon,
      label: "Report",
      component: Report,
      isVisible: true,
    },
    {
      id: "roles",
      icon: ManageAccountsIcon,
      label: "Roles",
      component: Roles,
      isVisible: GetPrevilage('Roles', 'View'),
    },
    {
      id: "admin",
      icon: LockIcon,
      label: "Admin",
      component: Admin,
      isVisible: true,
    },
    {
      id: "logout",
      icon: ExitToAppIcon,
      label: "Logout",
      component: Logout,
      isVisible: true,
    },
  ];
  
  return (
    <ResponsiveDrawer
      tabs={PortalTabs}
      routePrefix="/portal/"
      defaultRoute="dashboard"
    >
      Rights Management Portal
    </ResponsiveDrawer>
  );
}
