import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { Colors } from "../../../utils/DefaultTheme";
import CustomForm from "../../common/form/CustomForm";
import { MediaDto } from "../../../dto/common/MediaDto";
import MediaPreview from "./MediaPreview";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import NewMediaInput from "./NewMediaInput";
import { Notification } from "../../../utils/Notification";
import SubmitButton from "../../common/button/SubmitButton";

const useStyle = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.main,
    color: Colors.BACKGROUND_WHITE,
  },
  title: {
    fontWeight: 500,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1.5),
    },
  },
}));

interface MediaFormPopupProps {
  open: boolean;
  onClose?: () => void;
  label: string;
  onAddMedia?: (media: MediaDto) => Promise<void>;
  media?: MediaDto;
  onUpdateMedia?: (media: MediaDto) => Promise<void>;
}

export default function MediaFormPopup({
  open,
  onClose,
  label,
  onAddMedia,
  media,
  onUpdateMedia,
}: MediaFormPopupProps) {
  const classes = useStyle();
  const [url, setUrl] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [name, setName] = useState<string>("");

  const clearInput = useCallback(() => {
    setUrl("");
    setComment("");
    setName("");
  }, []);

  useEffect(() => {
    setUrl(media?.url ?? "");
    setName(media?.name ?? "");
    setComment(media?.comment ?? "");
  }, [media]);

  const mediaSubmit = async () => {
    if (!media && !url) {
      Notification.showError("Please upload a file");
      return;
    }
    if (!media) {
      await onAddMedia?.({
        url,
        name,
        comment,
        date: new Date(),
      });
    } else {
      await onUpdateMedia?.({ ...media, name, comment, date: new Date() });
    }
    clearInput();
  };

  const onUpdateName = useCallback((e) => setName(e.target.value), []);

  const onUpdateComment = useCallback((e) => setComment(e.target.value), []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="add-media-dialog-title"
    >
      <MuiDialogTitle
        disableTypography
        id="add-media-dialog-title"
        className={classes.header}
      >
        <Typography variant="h6" color="inherit" className={classes.title}>
          {media ? "Update" : "Add new"} {label}
        </Typography>
        <IconButton
          color="inherit"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <CustomForm onSubmit={mediaSubmit} className={classes.form}>
          <div>
            {media ? (
              <MediaPreview url={media.url} name={media.name} />
            ) : (
              <NewMediaInput url={url} setUrl={setUrl} disabled={!!media} />
            )}
          </div>
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={onUpdateName}
          />
          <TextField
            multiline
            label="Comment"
            fullWidth
            value={comment}
            onChange={onUpdateComment}
          />
          <Box className={classes.buttonContainer}>
            <SubmitButton
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
            >
              SUBMIT
            </SubmitButton>
            <Button variant="text" color="primary" size="medium">
              CANCEL
            </Button>
          </Box>
        </CustomForm>
      </DialogContent>
    </Dialog>
  );
}
