import { Box, TextField, Typography, makeStyles } from "@material-ui/core";

import Upload from "../../common/form/Upload";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    "& > * ": {
      marginRight: theme.spacing(1.5),
      "&:last-child": {
        marginRight: 0,
      },
    },
    "& > button": {
      minWidth: 100,
    },
  },
}));

interface NewMediaInputProps {
  url: string;
  setUrl: (url: string) => void;
  disabled: boolean;
}

export default function NewMediaInput({
  url,
  setUrl,
  disabled,
}: NewMediaInputProps) {
  const classes = useStyles();

  const onUploadFile = useCallback(
    (urls) => {
      setUrl(urls[0]);
    },
    [setUrl]
  );

  const onSetUrl = useCallback((e) => setUrl(e.target.value), [setUrl]);

  return (
    <Box className={classes.container}>
      <Upload
        url={url}
        label="Add file"
        onUpload={onUploadFile}
        disabled={disabled}
      />
      <Typography variant="body1">or</Typography>
      <TextField label="URL" fullWidth value={url} onChange={onSetUrl} />
    </Box>
  );
}
