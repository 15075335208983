import { FilterField, OptionType } from "../dto/report/FilterField";

import { FilterFieldType } from "../dto/report/FilterFieldType";

export default class FilterUtils {
  static BooleanFilterOptions = ["Yes", "No"];

  static getField = (fields: FilterField[], fieldName: string) => {
    return fields.find((field) => field.name === fieldName);
  };

  static getFieldType = (fields: FilterField[], fieldName: string) => {
    return (
      FilterUtils.getField(fields, fieldName)?.type ?? FilterFieldType.STRING
    );
  };

  static getOptionName = (option: OptionType) =>
    typeof option === "string" ? option : option.name;

  static getOptionLabel = (option?: OptionType) =>
    typeof option === "string" ? option : option?.label;

  static flattenOptions = (options: OptionType[]) =>
    options.map(FilterUtils.getOptionName);

  static findOptionLabel = (options: OptionType[]) => (option: string) => {
    return (
      FilterUtils.getOptionLabel(
        options.find((o) => FilterUtils.getOptionName(o) === option)
      ) ?? option
    );
  };
}
