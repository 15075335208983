import { AgreementExpiryDto } from "../dto/common/AgreementExpirtyDto";
import { AgreementExpiryType } from "../dto/common/AgreementExpiryType";
import { add } from "date-fns";

export class DateUtils {
  public static getFormattedDate(date?: string | Date): string {
    if (date) {
      return new Date(date).toDateString();
    } else {
      return "";
    }
  }

  public static getFormattedDuration(
    date1?: string | Date,
    date2?: string | Date
  ) {
    return [
      DateUtils.getFormattedDate(date1),
      DateUtils.getFormattedDate(date2),
    ]
      .filter((s) => !!s)
      .join(" - ");
  }

  public static addMonths(date?: string | Date, months?: number) {
    return date !== undefined ? add(new Date(date), { months }) : undefined;
  }

  public static addYears(date?: string | Date, years?: number) {
    return date !== undefined ? add(new Date(date), { years }) : undefined;
  }

  public static calculateValidTill = (
    validFrom?: Date,
    agreementExpiry?: AgreementExpiryDto
  ) => {
    if (!validFrom || !agreementExpiry) {
      return undefined;
    }
    switch (agreementExpiry.type) {
      case AgreementExpiryType.PERPETUAL:
        return DateUtils.addYears(validFrom, 99);
      case AgreementExpiryType.MONTHS:
        return DateUtils.addMonths(validFrom, agreementExpiry.duration);
      case AgreementExpiryType.YEARS:
        return DateUtils.addYears(validFrom, agreementExpiry.duration);
      default:
        return undefined;
    }
  };

  public static sortDate(date1?: string | Date, date2?: string | Date) {
    if (!date1 && !date2) {
      return 0;
    }
    if (!date1 || !date2) {
      return 1;
    }
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getTime() - d2.getTime();
  }
}
