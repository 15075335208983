import { RightDto } from "./RightDto";

export interface RightRequestDto {
  id?: string;
  rightTypes: string[];
  countrySet: string;
  exclusive: boolean;
}

export function mapToRightRequestDto(right: RightDto): RightRequestDto {
  return {
    rightTypes: right.rightTypes.map((rt) => rt.id),
    countrySet: right.countrySet.id ?? "",
    exclusive: right.exclusive,
  };
}
