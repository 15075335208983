import { ToastOptions, toast } from "react-toastify";

import AppErrorDto from "../dto/common/AppErrorDto";
import { AxiosError } from "axios";

export type NotificationOptions = {
  autoClose: boolean;
};
export class Notification {
  public static showApiError(err?: any, defaultMessage?: string) {
    const error = err as AxiosError<AppErrorDto>;
    const response = error.response;
    const data = response?.data;
    console.error(data ?? response ?? err);
    const message =
      data?.message ?? defaultMessage ?? "Unexpected error occurred";
    Notification.showError(message);
    return null;
  }

  public static showError(message: string, options?: NotificationOptions) {
    toast.error(message, this.convertToToastOptions(options));
  }

  public static showSuccess(message: string, options?: NotificationOptions) {
    toast.success(message, this.convertToToastOptions(options));
  }

  public static showInfo(message: string, options?: NotificationOptions) {
    toast.success(message, this.convertToToastOptions(options));
  }

  private static convertToToastOptions(options?: NotificationOptions) {
    const toastOptions: ToastOptions = {
      theme: "colored",
    };
    if (options?.autoClose === false) {
      toastOptions.autoClose = false;
    }
    return toastOptions;
  }
}
