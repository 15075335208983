import { BoxProps, Grid } from "@material-ui/core";

import { DateUtils } from "../../../../utils/DateUtils";
import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import { ReactNode } from "react";
import { StringUtils } from "../../../../utils/StringUtils";

const getDetailsMap = (
  movie: MovieDto
): Array<{ label: string; value: ReactNode }> => {
  return [
    { label: "Name", value: movie.name },
    { label: "Working Title", value: movie.workingTitle },
    { label: "Release Title", value: movie.releaseTitle },
    {
      label: "Release Date",
      value: DateUtils.getFormattedDate(movie.releaseDate),
    },
    { label: "Language", value: movie.language },
    { label: "Genre", value: movie.genre },
    { label: "Format", value: movie.format?.join(", ") },
    {
      label: "Source Availability",
      value: StringUtils.getBooleanStringValue(movie.sourceAvailability),
    },
  ];
};

interface MovieBasicDetailsProps {
  movie: MovieDto;
}

export default function MovieBasicDetails({
  movie,
  ...props
}: MovieBasicDetailsProps & BoxProps) {
  const movieData = getDetailsMap(movie);

  return (
    <DetailCard title="Movie information" {...props}>
      <Grid container spacing={2}>
        {movieData.map((data, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <DetailItem label={data.label}>{data.value}</DetailItem>
          </Grid>
        ))}
        <Grid item xs={12}>
          <DetailItem label="Synopsis">{movie.synopsis}</DetailItem>
        </Grid>
      </Grid>
    </DetailCard>
  );
}
