import { MovieStringProperties } from "../../dto/movie/MovieDto";
import { RootState } from "..";
import { makeSelectMoviePropertyValues } from "./MovieSelector";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useMovieProperty(property: MovieStringProperties) {
  const propertySelector = useMemo(makeSelectMoviePropertyValues, []);
  const values = useSelector((state: RootState) =>
    propertySelector(state, property)
  );
  return values;
}
