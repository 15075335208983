import DefaultTheme, { Colors } from "../../../../utils/DefaultTheme";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MuiThemeProvider,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { FormEvent, useCallback } from "react";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
  },
  titleContainer: {
    background: Colors.PRIMARY,
    color: Colors.BACKGROUND_WHITE,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      minWidth: 400,
    },
  },
}));

interface ColumnPickerProps {
  form: JSX.Element;
  open: boolean;
  onClose: () => void;
}

export default function ColumnPickerPopup({
  form,
  open,
  onClose,
}: ColumnPickerProps) {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classes.root }}
      fullScreen={fullScreen}
    >
      <MuiThemeProvider theme={DefaultTheme}>
        <DialogTitle
          id="form-dialog-title"
          disableTypography
          className={classes.titleContainer}
        >
          <Typography variant="h6" color="inherit">
            Manage columns
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={classes.content}
          onSubmit={onSubmit}
          onReset={onClose}
        >
          {form}
        </DialogContent>
      </MuiThemeProvider>
    </Dialog>
  );
}
