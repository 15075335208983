import BackTo from "../../../common/button/BackTo";
import RightGroupForm from "../rightForm/RightGroupForm";

export default function AddRight() {
  return (
    <div>
      <BackTo name="Rights" path="/portal/rights" />
      <RightGroupForm />
    </div>
  );
}
