import React, { useCallback, useEffect, useState } from 'react'

import DetailCard from '../../common/DetailCard';
import DetailItem from '../../common/DetailItem';
import EditItemRow from '../../common/EditItemRow';
import { GetPrevilage } from '../../../../utils/Previlage';
import { Grid } from '@material-ui/core';
import { UserDto } from '../../../../dto/user/UserDto';
import { getUserDetails } from '../../../../store/user/UserAction';
import { useParams } from 'react-router-dom';

const UserDetails = ({ ...props }) => {
  const editPrevilage = GetPrevilage('Users', 'Edit');
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<UserDto>();
  const [error, setError] = useState<boolean>(false);

  const getUser = useCallback(() => {
    getUserDetails(userId)
      .then(setUser)
      .catch(() => setError(true));
  }, [userId]);

  useEffect(getUser, [getUser]);

  return (
    <div>
      <EditItemRow
        backLabel="Users"
        backRoute="/portal/users"
        editLabel="User"
        editPrevilage={editPrevilage}
        editRoute={user && `/portal/users/${user?.id}/edit`}
      />
      {error ? <p>Failed to fetch user details</p> : null}
      {user ? (
        <DetailCard title="User information">
            <Grid item xs={12} sm={6} md={4} >
              <DetailItem label='Name'>{user.name}</DetailItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <DetailItem label='Email'>{user.email}</DetailItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <DetailItem label='Phone Number'>{user.phoneNumber}</DetailItem>
            </Grid>
        </DetailCard>
      ) : null}
    </div>
  )
}

export default UserDetails;
