import NumberFormat, { NumberFormatProps } from "react-number-format";

import { TextField } from "@material-ui/core";

interface PriceAmountFieldProps {
  value: string;
  setValue: (value: string) => void;
}

export default function PriceAmountField({
  value,
  setValue,
  ...props
}: PriceAmountFieldProps & NumberFormatProps) {
  return (
    <NumberFormat
      {...props}
      customInput={TextField}
      isNumericString
      thousandSeparator
      thousandsGroupStyle="lakh"
      label="Amount"
      value={value}
      onValueChange={(e) => setValue(e.value)}
      inputProps={{ step: ".01" }}
      fullWidth
    />
  );
}
