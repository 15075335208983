import { useEffect, useMemo } from "react";

import { PurchaseStringProperties } from "../../../dto/purchase/PurchaseDto";
import { getAllPurchases } from "../../../store/purchase/PurchaseAction";
import { useDispatch } from "react-redux";
import usePurchaseProperty from "../../../store/purchase/usePurchaseProperty";

export type PurchaseOptions = { [key in PurchaseStringProperties]: string[] };
export default function usePurchaseAutocomplete() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPurchases());
  }, [dispatch]);

  const productionBannerOptions = usePurchaseProperty("productionBanner");
  const sellerOptions = usePurchaseProperty("seller");
  const buyerOptions = usePurchaseProperty("buyer");

  return useMemo(
    (): PurchaseOptions => ({
      productionBanner: productionBannerOptions,
      seller: sellerOptions,
      buyer: buyerOptions,
    }),
    [productionBannerOptions, sellerOptions, buyerOptions]
  );
}
