import {
  Box,
  BoxProps,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ChangeEvent, useCallback, useContext } from "react";

import AddButton from "../../../common/button/AddButton";
import { Colors } from "../../../../utils/DefaultTheme";
import CustomSpacingGrid from "../../../common/layout/CustomSpacingGrid";
import DeleteIcon from "@material-ui/icons/Delete";
import { ModalContext } from "../../../providers/ModalProvider";
import RightTypeMoveForm from "./RightTypeMoveForm";
import { RightTypeWithoutGroup } from "../../../../dto/right/RightTypeDto";
import SwapCallsIcon from "@material-ui/icons/SwapCalls";

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
    color: Colors.GREY_0,
  },
  rightInput: {
    margin: theme.spacing(0, 3),
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
}));

interface RightTypeFormProps {
  index: number;
  right: RightTypeWithoutGroup;
  setRight: (index: number, right: RightTypeWithoutGroup) => void;
  removeRight: (index: number) => void;
}

function RightTypeForm({
  index,
  right,
  setRight,
  removeRight,
  ...props
}: RightTypeFormProps & BoxProps) {
  const classes = useStyles();
  const modal = useContext(ModalContext);

  const updateRightName = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRight(index, { ...right, name: e.target.value });
    },
    [index, right, setRight]
  );

  const deleteRightType = useCallback(() => {
    removeRight(index);
  }, [index, removeRight]);

  const moveRightType = useCallback(() => {
    modal.open(
      <RightTypeMoveForm
        rightType={right}
        onMoveRight={deleteRightType}
        onClose={modal.close}
      />
    );
  }, [modal, right, deleteRightType]);

  return (
    <Box display="flex" alignItems="flex-end" {...props}>
      <Typography variant="body2">{index + 1}</Typography>
      <TextField
        label="Right"
        type="text"
        name="right"
        variant="standard"
        fullWidth
        required
        value={right.name}
        onChange={updateRightName}
        className={classes.rightInput}
      />
      {right.id && (
        <IconButton size="small" color="primary" onClick={moveRightType}>
          <SwapCallsIcon />
        </IconButton>
      )}
      {!right.id && (
        <IconButton size="small" color="primary" onClick={deleteRightType}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
}

interface RightTypesFormProps {
  rights: RightTypeWithoutGroup[];
  setRights: (rights: RightTypeWithoutGroup[]) => void;
}

export default function RightTypesForm({
  rights,
  setRights,
}: RightTypesFormProps) {
  const classes = useStyles();

  const onAddRight = useCallback(() => {
    setRights([...rights, { id: "", name: "" }]);
  }, [rights, setRights]);

  const onUpdateRight = useCallback(
    (index: number, right: RightTypeWithoutGroup) => {
      const newRights = [...rights];
      if (index < 0 || index >= newRights.length) {
        return;
      }
      newRights[index] = right;
      setRights(newRights);
    },
    [rights, setRights]
  );

  const onRemoveRight = useCallback(
    (index: number) => {
      const newRights = [...rights];
      if (index < 0 || index >= newRights.length) {
        return;
      }
      newRights.splice(index, 1);
      setRights(newRights);
    },
    [rights, setRights]
  );

  return (
    <div>
      <InputLabel className={classes.label}>
        <Typography variant="body1" color="inherit">
          Rights
        </Typography>
      </InputLabel>
      <div>
        <CustomSpacingGrid container horizontalSpacing={3} verticalSpacing={1}>
          {rights.map((right, index) => (
            <Grid item xs={12} md={6} key={index}>
              <RightTypeForm
                index={index}
                right={right}
                setRight={onUpdateRight}
                removeRight={onRemoveRight}
              />
            </Grid>
          ))}
          {rights.length === 0 && (
            <Typography variant="body1" color="textSecondary">
              No secondary rights have been added so far
            </Typography>
          )}
        </CustomSpacingGrid>
        <AddButton
          className={classes.addButton}
          label="Right"
          onClick={onAddRight}
        />
      </div>
    </div>
  );
}
