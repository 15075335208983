import { BoxProps, Grid } from "@material-ui/core";

import { CrewRoles } from "../../../../dto/movie/CrewRoles";
import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import MovieUtils from "../../../../utils/MovieUtils";
import { ReactNode } from "react";

const getDetailsMap = (
  movie: MovieDto
): Array<{ label: string; value: ReactNode }> => {
  return [
    {
      label: "Director",
      value: MovieUtils.getCrewInfo(movie.crew, CrewRoles.DIRECTOR),
    },
    {
      label: "Actor",
      value: MovieUtils.getCrewInfo(movie.crew, CrewRoles.ACTOR),
    },
    {
      label: "Cinematographer",
      value: MovieUtils.getCrewInfo(movie.crew, CrewRoles.CINEMATOGRAPHER),
    },
    {
      label: "Lyricist",
      value: MovieUtils.getCrewInfo(movie.crew, CrewRoles.LYRICIST),
    },
    {
      label: "Music Director",
      value: MovieUtils.getCrewInfo(movie.crew, CrewRoles.MUSIC_DIRECTOR),
    },
    {
      label: "Producer",
      value: MovieUtils.getCrewInfo(movie.crew, CrewRoles.PRODUCER),
    },
  ];
};

interface MovieCrewDetailsProps {
  movie: MovieDto;
}

export default function MovieCrewDetails({
  movie,
  ...props
}: MovieCrewDetailsProps & BoxProps) {
  const movieData = getDetailsMap(movie);

  return (
    <DetailCard title="Cast information" {...props}>
      <Grid container spacing={2}>
        {movieData.map((data, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <DetailItem label={data.label}>{data.value}</DetailItem>
          </Grid>
        ))}
      </Grid>
    </DetailCard>
  );
}
