import { Column } from "../../../components/common/layout/Table";
import { CrewRoles } from "../../movie/CrewRoles";
import { MovieDto } from "../../movie/MovieDto";
import { MovieFieldNames } from "./MovieFieldNames";
import TableUtils from "../../../utils/TableUtils";

export const MovieColumns: { [key in MovieFieldNames]: Column<MovieDto> } = {
  MovieId: { title: "ID", field: "id" },
  Name: { title: "Name", field: "name" },
  WorkingTitle: { title: "Working Title", field: "workingTitle" },
  ReleaseTitle: { title: "Release Title", field: "releaseTitle" },
  MovieLanguage: { title: "Language", field: "language" },
  MovieGenre: { title: "Genre", field: "genre" },
  ReleaseDate: {
    title: "Release Date",
    field: "releaseDate",
    render: TableUtils.dateValueRender("releaseDate"),
    customSort: TableUtils.dateValueCustomSort("releaseDate"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Format: {
    title: "Format",
    field: "format",
    render: TableUtils.stringArrayValueRender("format"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SourceAvailability: {
    title: "Source Availability",
    field: "sourceAvailability",
    render: TableUtils.booleanValueRender("sourceAvailability"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Actor: {
    title: "Actors",
    field: "crew.actor",
    render: TableUtils.movieCrewValueRender("crew", CrewRoles.ACTOR),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Director: {
    title: "Directors",
    field: "crew.director",
    render: TableUtils.movieCrewValueRender("crew", CrewRoles.DIRECTOR),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Cinematographer: {
    title: "Cinematographer",
    field: "crew.cinematographer",
    render: TableUtils.movieCrewValueRender("crew", CrewRoles.CINEMATOGRAPHER),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Lyricist: {
    title: "Lyricist",
    field: "crew.lyricist",
    render: TableUtils.movieCrewValueRender("crew", CrewRoles.LYRICIST),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MusicDirector: {
    title: "Music Director",
    field: "crew.musicDirector",
    render: TableUtils.movieCrewValueRender("crew", CrewRoles.MUSIC_DIRECTOR),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Producer: {
    title: "Producer",
    field: "crew.producer",
    render: TableUtils.movieCrewValueRender("crew", CrewRoles.PRODUCER),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Singer: {
    title: "Singer",
    field: "songs.crew.singer",
    render: TableUtils.songCrewValueRender("songs", CrewRoles.SINGER),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  PostersCount: {
    title: "Posters",
    field: "posters",
    render: TableUtils.attachmentValueRender("posters"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  TrailersCount: {
    title: "Trailers",
    field: "trailers",
    render: TableUtils.attachmentValueRender("trailers"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
};
