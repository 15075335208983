import { Button, Paper, Typography, makeStyles } from "@material-ui/core";
import {
  PurchaseBasicDetailsRequestDto,
  PurchaseCostDetailsRequestDto,
  PurchaseRequestDto,
} from "../../../../dto/purchase/PurchaseRequestDto";
import {
  RightRequestDto,
  mapToRightRequestDto,
} from "../../../../dto/right/RightsRequestDto";
import {
  addNewPurchase,
  updatePurchase,
} from "../../../../store/purchase/PurchaseAction";
import { useCallback, useEffect, useState } from "react";

import CustomForm from "../../../common/form/CustomForm";
import FormButtonContainer from "../../common/FormButtonContainer";
import { PriceRequestDto } from "../../../../dto/common/PriceRequestDto";
import { PurchaseActionTypes } from "../../../../store/purchase/PurchaseTypes";
import PurchaseBasicDetailsForm from "./PurchaseBasicDetailsForm";
import PurchaseCostDetailsForm from "./PurchaseCostDetailsForm";
import { PurchaseDto } from "../../../../dto/purchase/PurchaseDto";
import RightsForm from "../../right/RightsForm";
import { RootState } from "../../../../store";
import { StringUtils } from "../../../../utils/StringUtils";
import SubmitButton from "../../../common/button/SubmitButton";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3),
  },
  costDetailsForm: {
    margin: theme.spacing(5, 0),
  },
}));

const DefaultPurchaseBasicDetails = (
  purchase?: PurchaseDto
): PurchaseBasicDetailsRequestDto => ({
  movie: purchase?.movie.id ?? "",
  agreementDate: purchase?.agreementDate
    ? new Date(purchase?.agreementDate)
    : undefined,
  agreementExpiry: purchase?.agreementExpiry,
  buyer: purchase?.buyer ?? "",
  seller: purchase?.seller ?? "",
  productionBanner: purchase?.productionBanner ?? "",
  validFrom: purchase?.validFrom,
  validTill: purchase?.validTill,
  isIntermediary: purchase?.isIntermediary ?? false,
  isDeleted: purchase?.isDeleted ?? false,
});

const DefaultPurchaseCostDetails = (
  purchase?: PurchaseDto
): PurchaseCostDetailsRequestDto => ({
  purchaseType: purchase?.purchaseType,
  purchaseAmount:
    purchase?.purchaseAmount && new PriceRequestDto(purchase?.purchaseAmount),
  purchasePercentage: purchase?.purchasePercentage,
  minimumGuarantee: purchase?.minimumGuarantee ?? false,
  advancePaid: purchase?.advancePaid ?? false,
  advanceAmount:
    purchase?.advanceAmount && new PriceRequestDto(purchase?.advanceAmount),
});

interface PurchaseFormProps {
  purchase?: PurchaseDto;
}

const PurchaseForm = ({ purchase }: PurchaseFormProps) => {
  const classes = useStyles();

  const [purchaseBasicDetails, setPurchaseBasicDetails] = useState(
    DefaultPurchaseBasicDetails()
  );
  const [purchaseCostDetails, setPurchaseCostDetails] = useState(
    DefaultPurchaseCostDetails()
  );
  const [rights, setRights] = useState<RightRequestDto[]>([]);
  const [intermediaryRights, setIntermediaryRights] = useState<
    RightRequestDto[]
  >([]);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, PurchaseActionTypes>>();
  const history = useHistory();

  const submitPurchase = async () => {
    const newPurchase: PurchaseRequestDto = {
      ...purchaseBasicDetails,
      productionBanner: StringUtils.formatFieldValue(
        purchaseBasicDetails.productionBanner
      ),
      seller: StringUtils.formatFieldValue(purchaseBasicDetails.seller),
      buyer: StringUtils.formatFieldValue(purchaseBasicDetails.buyer),
      ...purchaseCostDetails,
      rights: rights,
    };

    if (purchaseBasicDetails.isIntermediary) {
      newPurchase.intermediaryRights = intermediaryRights;
    }

    if (purchase && purchase.id) {
      await dispatch(updatePurchase(purchase.id, newPurchase));
      history.push(`/portal/purchase/${purchase.id}`);
    } else {
      await dispatch(addNewPurchase(newPurchase));
      history.push("/portal/purchase");
    }
  };

  const resetForm = useCallback(() => {
    setPurchaseBasicDetails(DefaultPurchaseBasicDetails(purchase));
    setPurchaseCostDetails(DefaultPurchaseCostDetails(purchase));
    setRights(purchase?.rights.map(mapToRightRequestDto) ?? []);
    setIntermediaryRights(
      purchase?.intermediaryRights.map(mapToRightRequestDto) ?? []
    );
  }, [purchase]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Paper elevation={2} className={classes.container}>
      <CustomForm onSubmit={submitPurchase}>
        <Typography variant="h5">
          {purchase ? "Update Purchase" : "Add Purchase"}
        </Typography>
        <PurchaseBasicDetailsForm
          purchase={purchaseBasicDetails}
          setPurchase={setPurchaseBasicDetails}
        />
        <PurchaseCostDetailsForm
          purchase={purchaseCostDetails}
          setPurchase={setPurchaseCostDetails}
          className={classes.costDetailsForm}
        />
        {purchaseBasicDetails.isIntermediary ? (
          <RightsForm
            label="Intermediary Rights (Primary Rights)"
            rights={intermediaryRights}
            onUpdate={setIntermediaryRights}
          />
        ) : null}
        <RightsForm
          label="Rights (Purchase Rights)"
          rights={rights}
          onUpdate={setRights}
        />
        <FormButtonContainer>
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {purchase ? "Update purchase" : "Add purchase"}
          </SubmitButton>
          <Button
            type="button"
            variant="text"
            color="secondary"
            size="large"
            onClick={resetForm}
          >
            RESET
          </Button>
        </FormButtonContainer>
      </CustomForm>
    </Paper>
  );
};

export default PurchaseForm;
