import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Button } from "@material-ui/core";
import { FileUtils } from "../../../utils/FileUtils";
import MediaPreview from "../../portal/media/MediaPreview";
import { uploadFile } from "../../../store/upload/UploadAction";
import { useCallback } from "react";

interface UploadProps {
  label: string;
  url: string;
  disabled?: boolean;
  onUpload: (urls: string[]) => void;
}

export default function Upload(props: UploadProps) {
  const { label, onUpload } = props;

  const fileDialogChanged = useCallback(
    async (event) => {
      const files = event.target.files;
      const urls = [];
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);
        const response = await uploadFile(formData);
        if (response) {
          urls.push(response.url);
        }
      }
      onUpload(urls);
    },
    [onUpload]
  );

  const onAddingNewImage = useCallback(() => {
    FileUtils.openFileDialog(fileDialogChanged);
  }, [fileDialogChanged]);

  if (props.url) {
    return <MediaPreview url={props.url} />;
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      startIcon={<AddCircleIcon />}
      onClick={onAddingNewImage}
      disabled={props.disabled}
    >
      {label}
    </Button>
  );
}
