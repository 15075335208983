import {
  InternetMediaType,
  InternetType,
  Jukebox,
} from "../../../../dto/sale/SaleDto";

import { ChooseMedia } from "./ChooseMedia";
import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import { Grid } from "@material-ui/core";
import JukeboxForm from "./platform/JukeboxForm";
import useSaleProperty from "../../../../store/sale/useSaleProperty";

interface InternetFormProps {
  internet?: InternetType;
  setInternet: (val: InternetType) => void;
}

export default function InternetForm({
  internet,
  setInternet,
}: InternetFormProps) {
  const onchangeMedia = (media: InternetMediaType) =>
    setInternet({
      ...internet,
      media,
    });

  const onChangeChannelName = (channelName: string) =>
    setInternet({
      ...internet,
      channelName,
    });

  const onChangeJukebox = (jukebox: Jukebox) =>
    setInternet({ ...internet, jukebox });

  const channelNameOptions = useSaleProperty("channelName");

  return (
    <>
      <Grid item xs={12} md={6}>
        <CustomAutocomplete
          label="Channel Name"
          type="text"
          options={channelNameOptions}
          freeSolo
          required={false}
          inputValue={internet?.channelName ?? ""}
          onInputChange={onChangeChannelName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ChooseMedia media={internet?.media} setMedia={onchangeMedia} />
      </Grid>
      {internet?.media === InternetMediaType.Jukebox && (
        <JukeboxForm jukebox={internet?.jukebox} setJukebox={onChangeJukebox} />
      )}
    </>
  );
}
