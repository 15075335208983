import { CountrySetStringProperties } from "../../dto/countrySet/CountrySetDto";
import { RootState } from "..";
import { makeSelectCountrySetPropertyValues } from "./CountrySetSelector";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useCountrySetProperty(
  property: CountrySetStringProperties
) {
  const propertySelector = useMemo(makeSelectCountrySetPropertyValues, []);
  const values = useSelector((state: RootState) =>
    propertySelector(state, property)
  );
  return values;
}
