import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  RightGroupRequestDto,
  RightGroupWithRights,
} from "../../../../dto/right/RightGroupDto";
import {
  RightTypeRequestDto,
  RightTypeWithoutGroup,
} from "../../../../dto/right/RightTypeDto";
import {
  addRightGroup,
  addRightTypes,
  getAllRights,
  updateRightGroup,
  updateRightTypes,
} from "../../../../store/right/RightAction";

import CustomForm from "../../../common/form/CustomForm";
import FormButtonContainer from "../../common/FormButtonContainer";
import FormSection from "../../common/FormSection";
import { Notification } from "../../../../utils/Notification";
import { RightActionTypes } from "../../../../store/right/RightTypes";
import RightTypesForm from "./RightTypesForm";
import { RootState } from "../../../../store";
import SubmitButton from "../../../common/button/SubmitButton";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3),
  },
}));

interface RightGroupFormProps {
  right?: RightGroupWithRights;
}

export default function RightGroupForm({ right }: RightGroupFormProps) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [rights, setRights] = useState<RightTypeWithoutGroup[]>([]);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, RightActionTypes>>();

  const history = useHistory();

  const submitRights = async () => {
    if (rights.length === 0) {
      Notification.showError("Please provide atleast one secondary rights");
      return;
    }

    const newRight: RightGroupRequestDto = { name };
    let rightGroupId = right?.id;
    if (rightGroupId) {
      await dispatch(updateRightGroup(rightGroupId, newRight, false));
    } else {
      rightGroupId = (await addRightGroup(newRight)).id;
    }

    const oldRights: RightTypeWithoutGroup[] = rights.filter((r) => r.id);
    const newRights: RightTypeRequestDto[] = rights
      .filter((r) => !r.id)
      .map((r) => ({ group: rightGroupId ?? "", name: r.name }));

    await dispatch(addRightTypes(newRights, false));
    await dispatch(updateRightTypes(rightGroupId, oldRights));

    if (oldRights.length > 0) {
      Notification.showSuccess("Updated primary rights");
    }

    history.push(`/portal/rights/${rightGroupId}`);
  };

  const updateRightName = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setName(e.target.value),
    []
  );

  const resetForm = useCallback(() => {
    setName(right?.name ?? "");
    setRights(right?.rights ?? []);
  }, [right]);

  useEffect(() => {
    dispatch(getAllRights());
  }, [dispatch]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const label = right ? "Update Primary Right" : "Add Primary Right";

  return (
    <Paper elevation={2} className={classes.container}>
      <Typography variant="h5">{label}</Typography>
      <CustomForm onSubmit={submitRights}>
        <FormSection label="Right Details">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                type="text"
                variant="standard"
                fullWidth
                required
                value={name}
                onChange={updateRightName}
              />
            </Grid>
            <Grid item xs={12}>
              <RightTypesForm rights={rights} setRights={setRights} />
            </Grid>
          </Grid>
        </FormSection>
        <FormButtonContainer>
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {label}
          </SubmitButton>
          <Button
            type="button"
            variant="text"
            color="secondary"
            size="large"
            onClick={resetForm}
          >
            RESET
          </Button>
        </FormButtonContainer>
      </CustomForm>
    </Paper>
  );
}
