import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import EditItemRow from "../../common/EditItemRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { RightActionTypes } from "../../../../store/right/RightTypes";
import { RootState } from "../../../../store";
import { ThunkDispatch } from "redux-thunk";
import { getAllRights } from "../../../../store/right/RightAction";
import { selectRightGroup } from "../../../../store/right/RightSelector";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function RightDetails() {
  const editPrevilage = GetPrevilage('Rights', 'Edit');
    
  const { rightId } = useParams<{ rightId: string }>();
  const rightGroup = useSelector(selectRightGroup(rightId));

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, RightActionTypes>>();

  useEffect(() => {
    dispatch(getAllRights());
  }, [dispatch]);

  return (
    <div>
      <EditItemRow
        backLabel="Rights"
        backRoute="/portal/rights"
        editLabel="Right"
        editPrevilage={editPrevilage}
        editRoute={rightGroup && `/portal/rights/${rightGroup.id}/edit`}
      />
      {!rightGroup && (
        <Typography color="secondary" variant="body1">
          Right with the given name does not exist
        </Typography>
      )}
      {rightGroup && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailCard title="Right Details">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DetailItem label="Primary Right">
                    {rightGroup.name}
                  </DetailItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DetailItem label="Secondary Rights">
                    {rightGroup.rights.map((r) => r.name).join(", ")}
                  </DetailItem>
                </Grid>
              </Grid>
            </DetailCard>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
