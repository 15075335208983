import { Grid, GridProps, InputAdornment, TextField } from "@material-ui/core";
import {
  PurchaseType,
  PurchaseTypeLabels,
  PurchaseTypeOptions,
} from "../../../../dto/purchase/PurchaseType";

import { ChangeEvent } from "react";
import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import CustomCheckbox from "../../../common/form/CustomCheckbox";
import CustomSpacingGrid from "../../../common/layout/CustomSpacingGrid";
import FormSection from "../../common/FormSection";
import PriceField from "../../../common/form/PriceField";
import { PriceRequestDto } from "../../../../dto/common/PriceRequestDto";
import { PurchaseCostDetailsRequestDto } from "../../../../dto/purchase/PurchaseRequestDto";
import { StringUtils } from "../../../../utils/StringUtils";

interface PurchaseCostDetailsFormProps {
  purchase: PurchaseCostDetailsRequestDto;
  setPurchase: (purchase: PurchaseCostDetailsRequestDto) => void;
}

export default function PurchaseCostDetailsForm({
  purchase,
  setPurchase,
  ...props
}: PurchaseCostDetailsFormProps & GridProps) {
  const setPurchaseType = (purchaseType: PurchaseType) =>
    setPurchase({ ...purchase, purchaseType });
  const setPurchaseAmount = (purchaseAmount?: PriceRequestDto) =>
    setPurchase({ ...purchase, purchaseAmount });
  const setMinimumGuarantee = (e: ChangeEvent<HTMLInputElement>) =>
    setPurchase({ ...purchase, minimumGuarantee: e.target.checked });
  const setPurchasePercentage = (e: ChangeEvent<HTMLInputElement>) =>
    setPurchase({
      ...purchase,
      purchasePercentage: StringUtils.formatNumberField(e.target.value),
    });

  const setIsAdvancePaid = (e: ChangeEvent<HTMLInputElement>) =>
    setPurchase({ ...purchase, advancePaid: e.target.checked });
  const setAdvanceAmount = (advanceAmount?: PriceRequestDto) =>
    setPurchase({ ...purchase, advanceAmount });

  const showPurchaseAmount = purchase.purchaseType !== PurchaseType.COMMISSION;
  const showPurchasePercentage =
    purchase.purchaseType === PurchaseType.PERCENTAGE ||
    purchase.purchaseType === PurchaseType.COMMISSION;

  const showAdvanceAmount = purchase.advancePaid;

  return (
    <FormSection label="Cost Details" {...props}>
      <CustomSpacingGrid
        container
        horizontalSpacing={2}
        verticalSpacing={1}
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Purchase Type"
            options={PurchaseTypeOptions}
            getOptionLabel={PurchaseTypeLabels}
            value={purchase.purchaseType}
            onChange={setPurchaseType}
          />
        </Grid>
        {showPurchaseAmount && (
          <Grid item xs={12}>
            <PriceField
              label="Purchase Amount"
              value={purchase.purchaseAmount}
              onChange={setPurchaseAmount}
            />
          </Grid>
        )}

        {showPurchasePercentage && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label="Purchase Percentage"
                type="number"
                fullWidth
                value={purchase.purchasePercentage ?? ""}
                onChange={setPurchasePercentage}
                required
                inputProps={{
                  max: 100,
                  min: 0,
                  step: "0.001",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCheckbox
                checked={purchase.minimumGuarantee}
                onChange={setMinimumGuarantee}
                name="minimumGuarantee"
                label="Minimum Guarantee"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <CustomCheckbox
            checked={purchase.advancePaid}
            onChange={setIsAdvancePaid}
            name="advancePaid"
            label="Advance Paid?"
          />
        </Grid>
        {showAdvanceAmount && (
          <Grid item xs={12}>
            <PriceField
              label="Advance Amount"
              value={purchase.advanceAmount}
              onChange={setAdvanceAmount}
            />
          </Grid>
        )}
      </CustomSpacingGrid>
    </FormSection>
  );
}
