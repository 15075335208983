import "./CountrySetForm.scss";

import { Button, Paper, Typography } from "@material-ui/core";
import {
  addCountrySet,
  updateCountrySet,
} from "../../../../store/countrySet/CountrySetAction";
import { useCallback, useEffect, useState } from "react";

import { CountrySetActionTypes } from "../../../../store/countrySet/CountrySetTypes";
import { CountrySetDto } from "../../../../dto/countrySet/CountrySetDto";
import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import CustomCheckbox from "../../../common/form/CustomCheckbox";
import CustomForm from "../../../common/form/CustomForm";
import { RootState } from "../../../../store";
import SubmitButton from "../../../common/button/SubmitButton";
import TextFieldArray from "../../../common/form/TextFieldArray";
import { ThunkDispatch } from "redux-thunk";
import useCountrySetAutocomplete from "../../../common/hooks/UseCountrySetAutocomplete";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

interface CountrySetFormProps {
  countrySet?: CountrySetDto;
}

const CountrySetForm = ({ countrySet }: CountrySetFormProps) => {
  const [name, setName] = useState("");
  const [countries, setCountries] = useState<string[]>([]);
  const [included, setIncluded] = useState(true);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, CountrySetActionTypes>>();

  const history = useHistory();

  const countrySetSubmit = async () => {
    const newCountrySet: CountrySetDto = {
      name,
      countries,
      included,
    };
    if (countrySet?.id) {
      await dispatch(updateCountrySet(countrySet.id, newCountrySet));
    } else {
      await dispatch(addCountrySet(newCountrySet));
    }
    history.push("/portal/country-set");
  };

  const resetForm = useCallback(() => {
    setName(countrySet?.name ?? "");
    setCountries(countrySet?.countries ?? []);
    setIncluded(countrySet?.included ?? false);
  }, [countrySet]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const countrySetOptions = useCountrySetAutocomplete();

  return (
    <Paper elevation={2} className="country-set-form-container">
      <Typography variant="h5">
        {countrySet ? "Update Territory Set" : "Add Territory Set"}
      </Typography>
      {countrySet ? (
        <Typography variant="body1" color="error" className="warning">
          * Please be careful while updating a territory set. <br />* It will be
          updated in all the purchase and sales documents where the territory
          set is used
        </Typography>
      ) : null}
      <CustomForm onSubmit={countrySetSubmit}>
        <CustomAutocomplete
          label="Name"
          options={countrySetOptions.name}
          freeSolo
          inputValue={name}
          onInputChange={setName}
          type="text"
        />
        <TextFieldArray
          label="Territories"
          type="text"
          name="country"
          options={countrySetOptions.country}
          required
          fullWidth
          value={countries}
          onUpdate={setCountries}
          // helperText="Please press enter after each country name"
        />
        <CustomCheckbox
          checked={included}
          onChange={(e) => setIncluded(e.target.checked)}
          name="included"
          label="Included"
        />
        <div className="buttons">
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {countrySet ? "Update Territory Set" : "Add Territory Set"}
          </SubmitButton>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="large"
            onClick={resetForm}
          >
            Reset
          </Button>
        </div>
      </CustomForm>
    </Paper>
  );
};

export default CountrySetForm;
