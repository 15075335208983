import { Grid, GridProps } from "@material-ui/core";

import { CrewDto } from "../../../../dto/movie/CrewDto";
import { CrewRoles } from "../../../../dto/movie/CrewRoles";
import FormSection from "../../common/FormSection";
import MovieUtils from "../../../../utils/MovieUtils";
import TextFieldArray from "../../../common/form/TextFieldArray";
import { useCallback } from "react";
import useMovieAutocomplete from "../../../common/hooks/UseMovieAutocomplete";

interface MovieCrewFormProps {
  crew: CrewDto[];
  setCrew: (crew: CrewDto[]) => void;
}

export default function MovieCrewForm({
  crew,
  setCrew,
  ...props
}: MovieCrewFormProps & GridProps) {
  const optionsGenerator = useMovieAutocomplete();
  const peopleOptions = optionsGenerator.people;

  const setPeople = useCallback(
    (role: CrewRoles, people: string[]) => {
      let newCrew = [...crew];
      newCrew = newCrew.filter((c) => c.role !== role);
      newCrew.push({ role, people });
      setCrew(newCrew);
    },
    [crew, setCrew]
  );

  const directors = MovieUtils.getCrew(crew, CrewRoles.DIRECTOR);

  const setDirectors = useCallback(
    (people: string[]) => {
      setPeople(CrewRoles.DIRECTOR, people);
    },
    [setPeople]
  );

  const actors = MovieUtils.getCrew(crew, CrewRoles.ACTOR);

  const setActors = useCallback(
    (people: string[]) => {
      setPeople(CrewRoles.ACTOR, people);
    },
    [setPeople]
  );

  const cinematographers = MovieUtils.getCrew(crew, CrewRoles.CINEMATOGRAPHER);
  const setCinematographers = useCallback(
    (people: string[]) => {
      setPeople(CrewRoles.CINEMATOGRAPHER, people);
    },
    [setPeople]
  );
  const lyricists = MovieUtils.getCrew(crew, CrewRoles.LYRICIST);
  const setLyricists = useCallback(
    (people: string[]) => {
      setPeople(CrewRoles.LYRICIST, people);
    },
    [setPeople]
  );
  const musicDirectors = MovieUtils.getCrew(crew, CrewRoles.MUSIC_DIRECTOR);
  const setMusicDirectors = useCallback(
    (people: string[]) => {
      setPeople(CrewRoles.MUSIC_DIRECTOR, people);
    },
    [setPeople]
  );
  const producers = MovieUtils.getCrew(crew, CrewRoles.PRODUCER);
  const setProducers = useCallback(
    (people: string[]) => {
      setPeople(CrewRoles.PRODUCER, people);
    },
    [setPeople]
  );

  return (
    <FormSection label="Crew Details" {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldArray
            label="Directors"
            type="text"
            name="director"
            required
            fullWidth
            value={directors}
            onUpdate={setDirectors}
            options={peopleOptions}
            freeSolo
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldArray
            label="Actors"
            type="text"
            name="actor"
            required
            fullWidth
            value={actors}
            onUpdate={setActors}
            options={peopleOptions}
            freeSolo
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldArray
            label="Cinematographers"
            type="text"
            name="cinematographer"
            fullWidth
            value={cinematographers}
            onUpdate={setCinematographers}
            options={peopleOptions}
            freeSolo
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldArray
            label="Lyricists"
            type="text"
            name="lyricist"
            fullWidth
            value={lyricists}
            onUpdate={setLyricists}
            options={peopleOptions}
            freeSolo
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldArray
            label="Music Directors"
            type="text"
            name="music director"
            required
            fullWidth
            value={musicDirectors}
            onUpdate={setMusicDirectors}
            options={peopleOptions}
            freeSolo
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldArray
            label="Producers"
            type="text"
            name="producers"
            required
            fullWidth
            value={producers}
            onUpdate={setProducers}
            options={peopleOptions}
            freeSolo
          />
        </Grid>
      </Grid>
    </FormSection>
  );
}
