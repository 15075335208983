import { Api } from "../../utils/Api";
import { Notification } from "../../utils/Notification";
import { UploadDto } from "../../dto/upload/UploadDto";

export const uploadFile = async (data: FormData): Promise<UploadDto | null> => {
  try {
    const purchases = await Api().post<UploadDto>("/upload", data);
    return purchases.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to upload files");
    return null;
  }
};
