import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { addNewUser, updateUser } from "../../../../store/user/UserAction";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../../auth/AuthProvider";
import CustomForm from '../../../common/form/CustomForm';
import { RootState } from "../../../../store";
import SubmitButton from '../../../common/button/SubmitButton';
import { ThunkDispatch } from "redux-thunk";
import { UserActionTypes } from "../../../../store/user/UserTypes";
import { UserDto } from "../../../../dto/user/UserDto";
import { getAllRoles } from "../../../../store/role/RoleAction";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  formInputContainer: {
    width: `calc(100% + ${theme.spacing(6)}px)`,
    margin: theme.spacing(0, -3),
    "& > *": {
      padding: theme.spacing(1, 3),
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(1.5),
    "& > *": {
      margin: theme.spacing(1, 3, 1, 0),
    },
  },
}));

interface UserFormProps {
  user?: UserDto;
}

const UserForm = ({ user }: UserFormProps) => {
  const classes = useStyles();
  const history = useHistory();
  const authDetails = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState(user ? user.phoneNumber : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [name, setName] = useState(user ? user.name : "");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(user ? user.role : "");
  const [isDeleted, setIsDeleted] = useState(user && user.isDeleted ? user.isDeleted : false);
  const roles = useSelector((state: RootState) => state.role.roles);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, UserActionTypes>>();
  
  const resetForm = useCallback(() => {
    setPhoneNumber("");
    setEmail("");
    setName("");
    setPassword("");
    setRole("");
    setIsDeleted(false);
  }, []);

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  const userSubmitFn = async () => {
    const prepareReqBody: UserDto = {
      phoneNumber: phoneNumber,
      email: email,
      name: name,
      role: role,
      isDeleted: isDeleted
    };
    if (user && user.id) {
      await dispatch(updateUser(user.id, prepareReqBody, 'update'));
      history.push(`/portal/users/${user.id}`);
      authDetails.updateAuth();
    } else {
      await dispatch(addNewUser({ phoneNumber, email, name, password, role, isDeleted }));
      history.push("/portal/users");
      authDetails.updateAuth();
    }
  }

  
  return (
    <div>
      <Paper elevation={2} className={classes.container}>
        <Typography variant="h6">{user ? "Update User" : "Add User"}</Typography>
        <CustomForm onSubmit={userSubmitFn}>
          <Grid container className={classes.formInputContainer}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone number"
                type="text"
                name="phone"
                fullWidth
                required
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                helperText="This will be used while logging in"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                type="email"
                name="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                type="text"
                name="name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  name="discountType"
                  value={role}
                  onChange={(e) => setRole(e.target.value as string)}
                >
                  {roles && roles.length > 0 && (
                    roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
            {!user && (
              <Grid item xs={12} md={6}>
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  fullWidth
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText="Password must have atleast 8 characters"
                />
              </Grid>
            )}
          </Grid>

          <div className={classes.buttonContainer}>
            <SubmitButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {!user ? 'Add' : 'Update'} User
            </SubmitButton>
            <Button
              type="button"
              variant="text"
              color="secondary"
              size="large"
              onClick={resetForm}
            >
              RESET
            </Button>
          </div>
        </CustomForm>
      </Paper>
    </div>
  )
}

export default UserForm;
