import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { createTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    border: {
      primary?: CSSProperties["color"];
      secondary?: CSSProperties["color"];
    };
  }
  interface PaletteOptions {
    border: {
      primary?: CSSProperties["color"];
      secondary?: CSSProperties["color"];
    };
  }
}

export const Colors = {
  PRIMARY: "#E91850",
  PRIMARY_LIGHT: "#fce7ed",
  SECONDARY: "#f0222b",
  GREY_0: "#000",
  GREY_200: "#202124",
  GREY_600: "#616161",
  GREY_900: "#f5f5f5",
  BACKGROUND_LIGHT: "#ededed",
  BACKGROUND_WHITE: "#fff",
  TEXT_PRIMARY: "#424242",
  TEXT_SECONDARY: "#5f6368",
  BORDER_GREY: "#e0e0e0",
  BORDER_BLACK: "#000000",
};

const DefaultTheme = createTheme({
  palette: {
    primary: {
      main: Colors.PRIMARY,
      light: Colors.PRIMARY_LIGHT,
    },
    secondary: {
      main: Colors.SECONDARY,
    },
    grey: {
      200: Colors.GREY_200,
      600: Colors.GREY_600,
      900: Colors.GREY_900,
    },
    background: {
      default: Colors.BACKGROUND_LIGHT,
      paper: Colors.BACKGROUND_WHITE,
    },
    text: {
      primary: Colors.TEXT_PRIMARY,
      secondary: Colors.TEXT_SECONDARY,
    },
    border: {
      primary: Colors.BORDER_GREY,
      secondary: Colors.BORDER_BLACK,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h5: {
      fontWeight: 500,
    },
    allVariants: {
      letterSpacing: 0.2,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        minWidth: "auto",
      },
      label: {
        fontSize: "1rem",
      },
    },
    MuiListSubheader: {
      root: {
        fontSize: "1rem",
        fontWeight: "bold",
      },
    },
    MuiFormLabel: {
      root: {
        letterSpacing: "0px",
      },
    },
  },
});

export default DefaultTheme;
