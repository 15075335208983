import { CrewDto } from "./CrewDto";
import { MediaDto } from "../common/MediaDto";
import { SongDto } from "./SongDto";

export interface MovieBasicDetailsDto {
  name: string;
  workingTitle?: string;
  releaseTitle?: string;
  synopsis?: string;
  language?: string;
  genre?: string;
  releaseDate?: Date;
  format?: string[];
  sourceAvailability?: boolean;
  isDeleted?: boolean;
}

export interface MovieDto extends MovieBasicDetailsDto {
  id?: string;
  crew: CrewDto[];
  songs: SongDto[];
  // Attachemnts
  posters?: MediaDto[];
  trailers?: MediaDto[];
}

export type MovieStringProperties =
  | "name"
  | "workingTitle"
  | "releaseTitle"
  | "language"
  | "genre"
  | "format"
  | "people";

export const selectMovieValuesByProperty = (
  movies: MovieDto[],
  key: MovieStringProperties
) => {
  const values = movies
    .flatMap((movie) => {
      switch (key) {
        case "people":
          const songCrew = movie.songs.flatMap((s) =>
            s.crew.flatMap((c) => c.people)
          );
          const movieCrew = movie.crew.flatMap((c) => c.people);
          return songCrew.concat(movieCrew);
        case "genre":
          const songGenre = movie.songs.flatMap((s) => s.genre);
          const movieGenre = movie.genre ?? "";
          return songGenre.concat(movieGenre);
        default:
          return movie[key] ?? "";
      }
    })
    .filter((value) => value !== "");
  return Array.from(new Set(values));
};
