import { useEffect, useState } from "react";

import BackTo from "../../../common/button/BackTo";
import { CountrySetDto } from "../../../../dto/countrySet/CountrySetDto";
import CountrySetForm from "../countrySetForm/CountrySetForm";
import { getCountrySetDetails } from "../../../../store/countrySet/CountrySetAction";
import { useParams } from "react-router-dom";

const CountrySetEdit = () => {
  const { countrySetId } = useParams<{ countrySetId: string }>();

  const [countrySet, setCountrySet] = useState<CountrySetDto>();
  const [error, setError] = useState(false);

  useEffect(() => {
    getCountrySetDetails(countrySetId)
      .then((res) => setCountrySet(res))
      .catch(() => setError(true));
  }, [countrySetId]);
  return (
    <div>
      <BackTo name="Territory Set Detail" />
      {countrySet ? <CountrySetForm countrySet={countrySet} /> : null}
      {error ? <p>Failed to load territory set detail</p> : null}
    </div>
  );
};

export default CountrySetEdit;
