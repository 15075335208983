export enum FilterFieldType {
  STRING = "STRING",
  DATE = "DATE",
  NUMBER = "NUMBER",
  DURATION = "DURATION",
  LIST = "LIST",
  BOOLEAN = "BOOLEAN",
  CURRENCY = "CURRENCY",
  TIME_LENGTH = "TIME_LENGTH",
}
