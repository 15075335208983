import { RECEIVE_ROLES, REQUEST_ROLES, RoleActionTypes } from "./RoleTypes";

import { Reducer } from "redux";
import { RoleDto } from "../../dto/role/RoleDto";

export interface RoleState {
  roles: RoleDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: RoleState = {
  roles: [],
  loading: false,
  loaded: false,
};

const RoleReducer: Reducer<RoleState, RoleActionTypes> = (
  state: RoleState = initialState,
  action: RoleActionTypes
) => {
  switch (action.type) {
    case REQUEST_ROLES:
      return { ...state, loading: true };
    case RECEIVE_ROLES:
      const roles = action.payload;
      return { roles: [...roles], loaded: true, loading: false };
    default:
      return state;
  }
};

export default RoleReducer;
