import { useEffect, useState } from "react";

import BackTo from "../../../common/button/BackTo";
import { PurchaseDto } from "../../../../dto/purchase/PurchaseDto";
import PurchaseForm from "../purchaseForm/PurchaseForm";
import { getPurchaseDetailsApi } from "../../../../store/purchase/PurchaseAction";
import { useParams } from "react-router-dom";

const PurchaseEdit = () => {
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const [purchase, setPurchase] = useState<PurchaseDto>();
  const [error, setError] = useState(false);

  useEffect(() => {
    getPurchaseDetailsApi(purchaseId)
      .then((purchase) => setPurchase(purchase))
      .catch(() => setError(true));
  }, [purchaseId]);

  return (
    <div>
      <BackTo name="Purchase Detail" />
      {purchase ? <PurchaseForm purchase={purchase} /> : null}
      {error ? <p>Failed to load purchase</p> : null}
    </div>
  );
};

export default PurchaseEdit;
