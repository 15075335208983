import { RightGroupWithRights } from "../../dto/right/RightGroupDto";
import { RootState } from "..";

export const selectRightGroups = (state: RootState): RightGroupWithRights[] => {
  const rightGroups: RightGroupWithRights[] = state.right.rightGroups.map(
    (rg) => ({ ...rg, rights: [] })
  );
  state.right.rights.forEach((right) =>
    rightGroups
      .find((rg) => rg.id === right.group.id)
      ?.rights.push({ id: right.id, name: right.name })
  );
  return rightGroups;
};

export const selectRightGroup =
  (rightGroupId: string) =>
  (state: RootState): RightGroupWithRights | undefined => {
    const rights = state.right.rights.filter(
      (r) => r.group.id === rightGroupId
    );
    const rightGroup = state.right.rightGroups.find(
      (rg) => rg.id === rightGroupId
    );
    return (
      rightGroup && {
        ...rightGroup,
        rights,
      }
    );
  };
