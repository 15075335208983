import {
  PurchaseActionTypes,
  RECEIVE_PURCHASES,
  REQUEST_PURCHASES,
} from "./PurchaseTypes";

import { PurchaseDto } from "../../dto/purchase/PurchaseDto";
import { Reducer } from "redux";

export interface PurchaseState {
  purchases: PurchaseDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: PurchaseState = {
  purchases: [],
  loading: false,
  loaded: false,
};

const PurchaseReducer: Reducer<PurchaseState, PurchaseActionTypes> = (
  state: PurchaseState = initialState,
  action: PurchaseActionTypes
) => {
  switch (action.type) {
    case REQUEST_PURCHASES:
      return { ...state, loading: true };
    case RECEIVE_PURCHASES:
      const purchases = action.payload;
      return {
        ...state,
        purchases: [...purchases],
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
};

export default PurchaseReducer;
