import { Route, Switch, useRouteMatch } from "react-router-dom";

import AddUser from "./addUser/AddUser";
import EditUser from "./editUser/EditUser";
import { GetPrevilage } from "../../../utils/Previlage";
import { PAGE_NOT_AUTHORIZED } from "../../../constants/constants";
import UserDetails from "./userDetails/UserDetails";
import UsersList from "../user/usersList/UsersList";

const Users = () => {
  const match = useRouteMatch();
  const viewPrevilage = GetPrevilage('Users', 'View');
  
  return (
    <>
      {viewPrevilage ? (
        <Switch>
          <Route path={match.path} exact component={UsersList} />
          <Route path={`${match.path}/add`} component={AddUser} />
          <Route path={`${match.path}/:userId`} exact component={UserDetails} />
          <Route path={`${match.path}/:userId/edit`} component={EditUser} />
        </Switch>
      ) : (
        <span>{PAGE_NOT_AUTHORIZED}</span>
      )}
    </>
  );
};

export default Users;
