import {
  CSSProperties,
  ForwardRefExoticComponent,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefAttributes,
  forwardRef,
} from "react";
import DefaultTheme, { Colors } from "../../../utils/DefaultTheme";
import MaterialTable, {
  CellStyle,
  Components,
  Column as MaterialTableColumn,
} from "@material-table/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import TableUtils from "../../../utils/TableUtils";
import ViewColumn from "@material-ui/icons/ViewColumn";
import usePersistedState from "../hooks/UsePersistedState";

export type Icon = ForwardRefExoticComponent<RefAttributes<SVGSVGElement>>;

export interface Icons {
  Add: Icon;
  Check: Icon;
  Clear: Icon;
  Delete: Icon;
  DetailPanel: Icon;
  Edit: Icon;
  Export: Icon;
  Filter: Icon;
  FirstPage: Icon;
  SortArrow: Icon;
  LastPage: Icon;
  NextPage: Icon;
  PreviousPage: Icon;
  ResetSearch: Icon;
  Search: Icon;
  ThirdStateCheck: Icon;
  ViewColumn: Icon;
}

export const TableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export interface Column<RowData extends object> {
  cellStyle?: CellStyle<RowData>;
  field: keyof RowData | (string & {});
  lookup?: object;
  title: string;
  render?: (data: RowData, type: "row" | "group") => ReactNode;
  sorting?: boolean;
  customSort?: (
    data1: RowData,
    data2: RowData,
    type: "row" | "group"
  ) => number;
  customFilterAndSearch?: MaterialTableColumn<RowData>["customFilterAndSearch"];
  width?: string | number;
}

export type TableEvent<RowData extends object> = (
  event: any,
  data: RowData | RowData[]
) => void;

export interface Action<RowData extends object> {
  icon: () => ReactElement<Icon>;
  tooltip: string;
  onClick: TableEvent<RowData>;
  isFreeAction?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export interface Option {
  actionsColumnIndex?: number;
  rowStyle?:
    | CSSProperties
    | ((data: any, index: number, level: number) => CSSProperties);

  exportAllData?: boolean;
  exportButton?: boolean;
  pageSize?: number;
  pageSizeOptions?: number[];
  headerStyle?: React.CSSProperties;
  searchFieldStyle?: React.CSSProperties;
}

export interface Localization {
  body?: {
    editRow?: {
      saveTooltip?: string;
      cancelTooltip?: string;
      deleteText?: string;
    };
    addTooltip?: string;
    deleteTooltip?: string;
    editTooltip?: string;
  };
}

export interface TableProps<RowData extends object> {
  id?: string;
  title: string;
  columns: Column<RowData>[];
  allColumns?: Column<RowData>[];
  reportPrevilage: boolean;
  components?: Components;
  data: RowData[];
  actions?: (Action<RowData> | ((rowData: RowData) => Action<RowData>))[];
  isLoading?: boolean;
  options?: Option;
  onRowsPerPageChange?: (pageSize: number) => void;
  onRowClick?: (
    event?: MouseEvent,
    rowData?: RowData,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => void;
  onRowDelete?: (oldData: RowData) => Promise<any>;
  parentChildData?: (row: RowData, rows: RowData[]) => RowData | undefined;
  localization?: Localization;
}

const border = `1px solid ${Colors.BORDER_GREY}`;

const theme = createTheme({
  overrides: {
    MuiToolbar: {
      root: {
        borderBottom: border,
      },
      regular: {
        minHeight: "unset !important",
        padding: 12,
      },
    },
    MuiPaper: {
      root: {
        border: border,
        borderRadius: 6,
        overflow: "hidden",
      },
      elevation2: {
        boxShadow: "none",
        width: "100%",
      },
    },
    MuiTableBody: {
      root: {
        "& > tr:last-child > *": {
          borderBottom: "none",
        },
      },
    },
    MuiTableCell: {
      root: {
        width: "auto !important",
      },
    },
    MuiTableRow: {
      head: {
        "& > *": {
          background: `${Colors.GREY_900} !important`,
        },
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
        width: "100% !important",
      },
      toolbar: {
        borderTop: border,
        borderBottom: "none",
      },
      spacer: {
        flex: "none",
      },
      input: {
        marginRight: "auto !important",
      },
      caption: {
        [DefaultTheme.breakpoints.up("md")]: {
          display: "block !important",
          paddingLeft: 24,
        },
      },
    },
  },
});

export default function Table<RowData extends object>(
  props: TableProps<RowData>
) {
  const { state: pageSize, setState: setPageSize } = usePersistedState(
    `${props.id ?? props.title}-pagesize`,
    5
  );

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        icons={TableIcons}
        {...props}
        options={props.reportPrevilage ? {
          exportMenu: [
            {
              label: "Export CSV",
              exportFunc: TableUtils.exportCsv(
                props.data,
                props.allColumns ?? props.columns,
                props.title + ".csv"
              ),
            },
          ],
          exportAllData: true,
          pageSize: pageSize,
          tableLayout: "auto",
          ...props.options,
        } : undefined}
        onRowsPerPageChange={setPageSize}
        editable={{ onRowDelete: props.onRowDelete }}
      />
    </MuiThemeProvider>
  );
}
