import { Route, Switch, useRouteMatch } from "react-router-dom";

import AddRole from "./addRole/AddRole";
import EditRole from "./editRole/EditRole";
import { GetPrevilage } from "../../../utils/Previlage";
import { PAGE_NOT_AUTHORIZED } from "../../../constants/constants";
import RoleDetails from "./roleDetails/RoleDetails";
import RolesList from "./rolesList/RolesList";

const Roles = () => {
  const match = useRouteMatch();
  const viewPrevilage = GetPrevilage('Roles', 'View');
  
  return (
    <>
      {viewPrevilage ? (
        <Switch>
          <Route path={match.path} exact component={RolesList} />
          <Route path={`${match.path}/add`} component={AddRole} />
          <Route path={`${match.path}/:roleId`} exact component={RoleDetails} />
          <Route path={`${match.path}/:roleId/edit`} component={EditRole} />
        </Switch>
      ) : (
        <span>{PAGE_NOT_AUTHORIZED}</span>
      )}
    </>
  );
};

export default Roles;
