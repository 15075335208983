import React, { useEffect } from 'react';
import Table, { Column } from '../../../common/layout/Table';
import { useDispatch, useSelector } from "react-redux";

import AddButtonRow from '../../common/AddButtonRow';
import { GetPrevilage } from '../../../../utils/Previlage';
import { RoleDto } from '../../../../dto/role/RoleDto';
import { RootState } from '../../../../store';
import { getAllRoles } from '../../../../store/role/RoleAction';
import useRowClickListener from '../../../common/hooks/UseRowClickListener';

const RolesList = () => {
  const columns: Column<RoleDto>[] = [
    { title: "Role", field: "name" },
    { title: "Previlages", field: "" }
  ];
  const viewPrevilage = GetPrevilage('Roles', 'View');
  const addPrevilage = GetPrevilage('Roles', 'Add');
  const editPrevilage = GetPrevilage('Roles', 'Edit');

  const roles = useSelector((state: RootState) => state.role.roles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  const goToRoleDetail = useRowClickListener((role?: RoleDto) => {
    if (role?.id && viewPrevilage) {
      return `/portal/roles/${role.id}`;
    }
  });

  return (
    <div>
      <AddButtonRow route="/portal/roles/add" label="Role" addPrevilage={addPrevilage} />
      <Table id="roles-list" title="Roles List" data={roles} columns={columns} reportPrevilage={editPrevilage} onRowClick={goToRoleDetail} />
    </div>
  )
}

export default RolesList
