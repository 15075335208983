import { useEffect, useState } from "react";

import ColumnPicker from "../common/ColumnPicker";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { FilterField } from "../../../../dto/report/FilterField";
import FilterTableToolbar from "../common/FilterTableToolbar";
import { GetPrevilage } from "../../../../utils/Previlage";
import { MovieColumns } from "../../../../dto/report/movie/MovieColumns";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import Table from "../../../common/layout/Table";
import usePersistedState from "../../../common/hooks/UsePersistedState";
import useRowClickListener from "../../../common/hooks/UseRowClickListener";

const MovieListColumns = Object.values(MovieColumns);

const FilteredMovieListColumns = (columns: string[]) =>
  MovieListColumns.filter((column) => columns.includes(column.field));

interface MovieReportResultProps {
  movies: MovieDto[];
  filters: FilterDto[];
  setFilters: (filters: FilterDto[]) => void;
  fields: FilterField[];
}

export default function MovieReportResult({
  movies,
  filters,
  setFilters,
  fields,
}: MovieReportResultProps) {
  const { state: columns, setState: setColumns } = usePersistedState<string[]>(
    "movie-report-columns",
    MovieListColumns.slice(1, 7).map((column) => column.field)
  );

  const [filteredColumns, setFilteredColumns] = useState(
    FilteredMovieListColumns(columns)
  );
  const viewPrevilage = GetPrevilage('Movies', 'View');
  const movieReportPrevilage = GetPrevilage('Reports', 'Movie');

  useEffect(() => {
    setFilteredColumns(FilteredMovieListColumns(columns));
  }, [columns]);

  const goToMovieDetail = useRowClickListener((movie?: MovieDto) => {
    if (movie?.id && viewPrevilage) {
      return `/portal/movies/${movie.id}`;
    }
  });

  return (
    <Table
      id="movie-report"
      title="Movies List"
      data={movies}
      columns={filteredColumns}
      allColumns={MovieListColumns}
      reportPrevilage={movieReportPrevilage}
      components={{
        Toolbar: FilterTableToolbar(
          filters,
          setFilters,
          fields,
          <ColumnPicker
            allColumns={MovieListColumns}
            columns={columns}
            setColumns={setColumns}
          />
        ),
      }}
      onRowClick={goToMovieDetail}
    />
  );
}
