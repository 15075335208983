import { ReactNode, useEffect, useState } from "react";

import { CountrySetDto } from "../../../../dto/countrySet/CountrySetDto";
import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import EditItemRow from "../../common/EditItemRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { Grid } from "@material-ui/core";
import { getCountrySetDetails } from "../../../../store/countrySet/CountrySetAction";
import { useParams } from "react-router-dom";

const getDetailsMap = (
  countrySet: CountrySetDto
): Array<{ label: string; value: ReactNode }> => {
  return [
    { label: "Name", value: countrySet.name },
    { label: "Territories", value: countrySet.countries.join(", ") },
    {
      label: "Include/Exclude",
      value: countrySet.included ? "Including" : "Excluding",
    },
  ];
};

const CountrySetDetails = () => {
  const editPrevilage = GetPrevilage('CountrySet', 'Edit');
    
  const { countrySetId } = useParams<{ countrySetId: string }>();

  const [countrySet, setCountrySet] = useState<CountrySetDto>();
  const [error, setError] = useState(false);

  useEffect(() => {
    getCountrySetDetails(countrySetId)
      .then((res) => setCountrySet(res))
      .catch(() => setError(true));
  }, [countrySetId]);

  return (
    <div>
      <EditItemRow
        backLabel="Territory Set"
        backRoute="/portal/country-set"
        editLabel="Territory Set"
        editPrevilage={editPrevilage}
        editRoute={`/portal/country-set/${countrySetId}/edit`}
      />
      {error ? <p>Failed to fetch territory set details</p> : null}
      {countrySet ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailCard title="Details">
              <Grid container spacing={2}>
                {getDetailsMap(countrySet).map((data, i) => (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <DetailItem label={data.label}>{data.value}</DetailItem>
                  </Grid>
                ))}
              </Grid>
            </DetailCard>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default CountrySetDetails;
