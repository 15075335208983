import BackTo from "../../../common/button/BackTo";
import CountrySetForm from "../countrySetForm/CountrySetForm";

const CountrySetAdd = () => {
  return (
    <div>
      <BackTo name="Territory Set" path="/portal/country-set" />
      <CountrySetForm />
    </div>
  );
};

export default CountrySetAdd;
