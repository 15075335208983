import { createContext, useEffect, useState } from "react";

import { Api } from "../../utils/Api";
import { AuthDto } from "./AuthDto";
import { AxiosError } from "axios";
import { RouteProps } from "react-router-dom";

export type AuthType = { loaded: boolean; user?: AuthDto };

export type AuthContextType = {
  auth: AuthType;
  updateAuth: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>({
  auth: { loaded: false },
  updateAuth: async () => {},
});

export default function AuthProvider(props: RouteProps) {
  const [auth, setAuth] = useState<AuthType>({ loaded: false });

  const getLoggedInUser = async () => {
    try {
      const res = await Api().get<AuthDto>("/auth/me");
      setAuth({ loaded: true, user: res.data });
    } catch (err) {
      console.error((err as AxiosError<AuthDto>).response?.data);
      setAuth({ loaded: true });
    }
  };

  // componentDidMount
  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, updateAuth: getLoggedInUser }}>
      {auth.loaded && props.children}
    </AuthContext.Provider>
  );
}
