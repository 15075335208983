import { FilterField, OptionType } from "../../../../dto/report/FilterField";
import {
  PurchaseTypeLabels,
  PurchaseTypeOptions,
} from "../../../../dto/purchase/PurchaseType";

import { CountrySetOptions } from "../../../common/hooks/UseCountrySetAutocomplete";
import { CurrencyOptions } from "../../../../dto/common/Currency";
import { FilterFieldType } from "../../../../dto/report/FilterFieldType";
import FilterUtils from "../../../../utils/FilterUtils";
import { MovieFieldNames } from "../../../../dto/report/movie/MovieFieldNames";
import { MovieOptions } from "../../../common/hooks/UseMovieAutocomplete";
import { PurchaseFieldNames } from "../../../../dto/report/purchase/PurchaseFieldNames";
import { PurchaseOptions } from "../../../common/hooks/UsePurchaseAutocomplete";
import { RightFieldNames } from "../../../../dto/report/right/RightFieldNames";

export const PurchaseReportFields = (
  movieOptions: MovieOptions,
  purchaseOptions: PurchaseOptions,
  rightOptions: OptionType[],
  countrySetOptions: CountrySetOptions
): FilterField[] => [
  { name: "Movie", type: FilterFieldType.STRING, isParent: true },
  {
    name: MovieFieldNames.NAME,
    type: FilterFieldType.STRING,
    options: movieOptions.name,
  },
  {
    name: MovieFieldNames.WORKING_TITLE,
    type: FilterFieldType.STRING,
    options: movieOptions.workingTitle,
  },
  {
    name: MovieFieldNames.RELEASE_TITLE,
    type: FilterFieldType.STRING,
    options: movieOptions.releaseTitle,
  },
  {
    name: MovieFieldNames.MOVIE_LANGUAGE,
    type: FilterFieldType.STRING,
    options: movieOptions.language,
  },
  {
    name: MovieFieldNames.MOVIE_GENRE,
    type: FilterFieldType.STRING,
    options: movieOptions.genre,
  },
  {
    name: MovieFieldNames.FORMAT,
    type: FilterFieldType.STRING,
    options: movieOptions.format,
  },
  { name: MovieFieldNames.RELEASE_DATE, type: FilterFieldType.DATE },
  {
    name: MovieFieldNames.SOURCE_AVAILABILITY,
    type: FilterFieldType.BOOLEAN,
    options: FilterUtils.BooleanFilterOptions,
  },
  {
    name: MovieFieldNames.DIRECTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.ACTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.CINEMATOGRAPHER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.LYRICIST,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.MUSIC_DIRECTOR,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.PRODUCER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.SINGER,
    type: FilterFieldType.STRING,
    options: movieOptions.people,
  },
  {
    name: MovieFieldNames.POSTERS,
    type: FilterFieldType.NUMBER,
  },
  {
    name: MovieFieldNames.TRAILERS,
    type: FilterFieldType.NUMBER,
  },
  { name: "Purchase", type: FilterFieldType.STRING, isParent: true },
  { name: PurchaseFieldNames.AGREEMENT_DATE, type: FilterFieldType.DATE },
  {
    name: PurchaseFieldNames.PRODUCTION_BANNER,
    type: FilterFieldType.STRING,
    options: purchaseOptions.productionBanner,
  },
  {
    name: PurchaseFieldNames.BUYER,
    type: FilterFieldType.STRING,
    options: purchaseOptions.buyer,
  },
  {
    name: PurchaseFieldNames.SELLER,
    type: FilterFieldType.STRING,
    options: purchaseOptions.seller,
  },
  { name: PurchaseFieldNames.VALIDITY, type: FilterFieldType.DURATION },
  { name: PurchaseFieldNames.PURCHASE_AMOUNT, type: FilterFieldType.CURRENCY },
  {
    name: PurchaseFieldNames.PURCHASE_AMOUNT_CURRENCY,
    type: FilterFieldType.STRING,
    options: CurrencyOptions,
  },
  {
    name: PurchaseFieldNames.PURCHASE_TYPE,
    type: FilterFieldType.STRING,
    options: PurchaseTypeOptions.map((option) => ({
      label: PurchaseTypeLabels(option),
      name: option,
    })),
  },
  {
    name: PurchaseFieldNames.PURCHASE_PERCENTAGE,
    type: FilterFieldType.NUMBER,
  },
  {
    name: PurchaseFieldNames.MINIMUM_GUARANTEE,
    type: FilterFieldType.BOOLEAN,
    options: FilterUtils.BooleanFilterOptions,
  },
  {
    name: PurchaseFieldNames.IS_INTERMEDIARY,
    type: FilterFieldType.BOOLEAN,
    options: FilterUtils.BooleanFilterOptions,
  },
  {
    name: PurchaseFieldNames.ADVANCE_PAID,
    type: FilterFieldType.BOOLEAN,
    options: FilterUtils.BooleanFilterOptions,
  },
  { name: PurchaseFieldNames.ADVANCE_AMOUNT, type: FilterFieldType.CURRENCY },
  {
    name: PurchaseFieldNames.MAIN_AGREEMENT,
    type: FilterFieldType.NUMBER,
  },
  {
    name: PurchaseFieldNames.LINK_AGREEMENT,
    type: FilterFieldType.NUMBER,
  },
  {
    name: PurchaseFieldNames.CENSOR_CERTIFICATE,
    type: FilterFieldType.NUMBER,
  },
  {
    name: PurchaseFieldNames.LAB_CERTIFICATE,
    type: FilterFieldType.NUMBER,
  },
  {
    name: PurchaseFieldNames.REAL_IMAGE_LETTER,
    type: FilterFieldType.NUMBER,
  },
  { name: "Rights", type: FilterFieldType.STRING, isParent: true },
  {
    name: RightFieldNames.RIGHT_TYPE,
    type: FilterFieldType.LIST,
    options: rightOptions,
  },
  {
    name: RightFieldNames.RIGHT_COUNTRY,
    type: FilterFieldType.LIST,
    options: countrySetOptions.country,
  },
];
