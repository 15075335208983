import FilterInputField, { getTextFieldType } from "./FilterInputField";

import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import { FilterCondition } from "../../../../dto/report/FilterCondition";
import { FilterFieldType } from "../../../../dto/report/FilterFieldType";
import FilterUtils from "../../../../utils/FilterUtils";
import { OptionType } from "../../../../dto/report/FilterField";
import TextFieldArray from "../../../common/form/TextFieldArray";

interface FilterValueProps {
  condition: FilterCondition;
  value: any;
  options?: OptionType[];
  fieldType: FilterFieldType;
  setValue: (value: any) => void;
}

export default function FilterValue({
  condition,
  value,
  options,
  fieldType,
  setValue,
}: FilterValueProps) {
  switch (condition) {
    case FilterCondition.BETWEEN:
      return (
        <>
          <FilterInputField
            label="From"
            fieldType={fieldType}
            name="from"
            required
            fullWidth
            value={value[0]}
            onChange={(val) => setValue([val, value[1]])}
          />
          <FilterInputField
            label="To"
            fieldType={fieldType}
            name="to"
            required
            fullWidth
            value={value[1]}
            onChange={(val) => setValue([value[0], val])}
          />
        </>
      );
    case FilterCondition.EQUALS:
    case FilterCondition.NOT_EQUALS:
    case FilterCondition.GREATER_THAN:
    case FilterCondition.LESS_THAN:
    case FilterCondition.LIKE:
      if (options === null || options === undefined) {
        return (
          <FilterInputField
            label="Value"
            fieldType={fieldType}
            name="value"
            required
            fullWidth
            value={value}
            onChange={(val) => setValue(val)}
          />
        );
      } else {
        return (
          <CustomAutocomplete
            inputValue={value ?? ""}
            onInputChange={setValue}
            fullWidth
            options={FilterUtils.flattenOptions(options)}
            getOptionLabel={FilterUtils.findOptionLabel(options)}
            freeSolo
            label="Value"
            type={getTextFieldType(fieldType)}
          />
        );
      }
    case FilterCondition.IN:
    case FilterCondition.NOT_IN:
    case FilterCondition.ALL_OF:
    case FilterCondition.ONE_OF:
      return (
        <TextFieldArray
          label="Values"
          type="text"
          name="values"
          required
          fullWidth
          value={value}
          onUpdate={setValue}
          options={options ?? []}
          freeSolo
        />
      );
    default:
      return <div></div>;
  }
}
