import Table, { Column } from "../../../common/layout/Table";

import AddButtonRow from "../../common/AddButtonRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { RightGroupWithRights } from "../../../../dto/right/RightGroupDto";
import TableUtils from "../../../../utils/TableUtils";
import useRightGroupsAutocomplete from "../../../common/hooks/UseRightGroupsAutocomplete";
import useRowClickListener from "../../../common/hooks/UseRowClickListener";

const columns: Column<RightGroupWithRights>[] = [
  { title: "Primary Rights", field: "name" },
  {
    title: "Secondary Rights",
    field: "rights",
    render: (rowData: RightGroupWithRights) =>
      rowData.rights.map((r) => r.name).join(", "),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
];

export default function RightsList() {
  const addPrevilage = GetPrevilage('Rights', 'Add');
  const viewPrevilage = GetPrevilage('Rights', 'View');
  const rightsReportPrevilage = GetPrevilage('Reports', 'Owned Rights');
    
  const rightGroups = useRightGroupsAutocomplete().rightGroups;

  const goToRightDetail = useRowClickListener(
    (rightGroup?: RightGroupWithRights) => {
      if (rightGroup && viewPrevilage) {
        return `/portal/rights/${rightGroup.id}`;
      }
    }
  );

  return (
    <>
      <AddButtonRow route="/portal/rights/add" label="Rights" addPrevilage={addPrevilage} />
      <Table
        id="rights-list"
        title="Rights List"
        data={rightGroups}
        columns={columns}
        reportPrevilage={rightsReportPrevilage}
        onRowClick={goToRightDetail}
      />
    </>
  );
}
