export enum AgreementExpiryType {
  PERPETUAL = "PERPETUAL",
  YEARS = "YEARS",
  MONTHS = "MONTHS",
  SPECIFIC = "SPECIFIC",
}

export const AgreementExpiryOptions = [
  AgreementExpiryType.PERPETUAL,
  AgreementExpiryType.MONTHS,
  AgreementExpiryType.YEARS,
  AgreementExpiryType.SPECIFIC,
];

export const AgreementExpiryLabel = (option: AgreementExpiryType) => {
  switch (option) {
    case AgreementExpiryType.PERPETUAL:
      return "Perpetual";
    case AgreementExpiryType.MONTHS:
      return "Months";
    case AgreementExpiryType.YEARS:
      return "Years";
    case AgreementExpiryType.SPECIFIC:
    default:
      return "Specific";
  }
};
