import { FilterCondition } from "../../../../dto/report/FilterCondition";
import { FilterField } from "../../../../dto/report/FilterField";
import FilterUtils from "../../../../utils/FilterUtils";
import FilterValue from "./FilterValue";

interface FilterFieldValueInputProps {
  value: any;
  setValue: (value: any) => void;
  field: string;
  condition: FilterCondition;
  fields: FilterField[];
}

export default function FilterFieldValueInput({
  value,
  setValue,
  field,
  condition,
  fields,
}: FilterFieldValueInputProps) {
  return (
    <FilterValue
      condition={condition}
      value={value}
      options={FilterUtils.getField(fields, field)?.options}
      fieldType={FilterUtils.getFieldType(fields, field)}
      setValue={setValue}
    />
  );
}
