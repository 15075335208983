import { AuthContextProps, withAuthContext } from "../WithAppContext";
import { Paper, TextField, Typography, makeStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";

import { Api } from "../../../utils/Api";
import CustomForm from "../../common/form/CustomForm";
import { Notification } from "../../../utils/Notification";
import SubmitButton from "../../common/button/SubmitButton";
import { UrlUtils } from "../../../utils/UrlUtils";

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    maxWidth: 400,
    width: "90%",
    boxSizing: "border-box",
    textAlign: "center",
    margin: "120px auto",
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: theme.spacing(1),
  },
  description: {
    color: theme.palette.grey[200],
    padding: theme.spacing(1),
  },
  form: {
    margin: theme.spacing(-2, 0),
    marginTop: theme.spacing(2),
    "& > *": {
      margin: theme.spacing(2, 0),
    },
  },
}));

const Login = withRouter(
  withAuthContext((props: AuthContextProps & RouteComponentProps) => {
    const classes = useStyles();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");

    const login = async () => {
      try {
        await Api().post(`/auth/login`, { phoneNumber, password });
        await props.updateAuth();
      } catch (err) {
        Notification.showApiError(err, "Unable to login");
      }
    };

    useEffect(() => {
      if (props.auth?.user?.role) {
        // Successfully Logged in
        props.history.push(UrlUtils.getRedirectUrl(props, "/portal"));
      }
    }, [props, props.auth]);

    return (
      <Paper elevation={0} className={classes.loginContainer}>
        <Typography variant="h5">Sign in</Typography>
        <Typography
          variant="body1"
          color="inherit"
          className={classes.description}
        >
          Use your admin account
        </Typography>
        <CustomForm onSubmit={login} className={classes.form}>
          <TextField
            label="Phone Number"
            type="text"
            name="phone"
            variant="outlined"
            fullWidth
            required
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            variant="outlined"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Log in
          </SubmitButton>
        </CustomForm>
      </Paper>
    );
  })
);

export default Login;
