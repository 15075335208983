import { applyMiddleware, createStore } from "redux";

import AppRouter from "./router/AppRouter";
import AuthProvider from "./components/auth/AuthProvider";
import DateFnsUtils from "@date-io/date-fns";
import DefaultTheme from "./utils/DefaultTheme";
import ModalProvider from "./components/providers/ModalProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import { ThemeProvider } from "@material-ui/core";
import rootReducer from "./store";
import { toast } from "react-toastify";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  draggable: false,
  closeOnClick: false,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <AuthProvider>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ModalProvider>
              <AppRouter />
            </ModalProvider>
          </MuiPickersUtilsProvider>
        </Provider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
