export default class TimeUtils {
  static splitTime = (timeInSeconds: number) => {
    if (timeInSeconds <= 0) {
      timeInSeconds = 0;
    }
    let seconds: number | undefined = timeInSeconds % 60;
    timeInSeconds = Math.round((timeInSeconds - seconds) / 60);
    let minutes: number | undefined = timeInSeconds % 60;
    let hours: number | undefined = Math.round((timeInSeconds - minutes) / 60);
    if (hours === 0) {
      hours = undefined;
      if (minutes === 0) {
        minutes = undefined;
        if (seconds === 0) {
          seconds = undefined;
        }
      }
    }
    return {
      hours,
      minutes,
      seconds,
    };
  };

  static formatTime = (timeInSeconds?: number) => {
    if (timeInSeconds === undefined) {
      return "";
    }
    const time = TimeUtils.splitTime(timeInSeconds);
    let parts = [time.hours, time.minutes, time.seconds].filter(
      (t) => t !== undefined
    );
    if (parts.length <= 1) {
      parts = [0, ...parts];
    }
    return parts
      .map((t, index) =>
        index === 0
          ? t
          : t?.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })
      )
      .join(":");
  };
}
