import { Button, Typography, makeStyles } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { MouseEvent, useCallback } from "react";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold",
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));
interface BackToProps {
  name: string;
  path?: string;
}

const BackTo = (props: BackToProps) => {
  const classes = useStyles();

  let history = useHistory();
  const goBack = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      history.goBack();
    },
    [history]
  );

  return (
    <Link to={props.path ?? "#"} onClick={props.path ? undefined : goBack}>
      <Button size="medium" color="primary">
        <ArrowBackIcon className={classes.icon} />
        <Typography variant="body1" className={classes.label}>
          Back to {props.name}
        </Typography>
      </Button>
    </Link>
  );
};

export default BackTo;
