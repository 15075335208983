import { BoxProps, Grid } from "@material-ui/core";

import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import { PurchaseDto } from "../../../../dto/purchase/PurchaseDto";
import { ReactNode } from "react";
import { StringUtils } from "../../../../utils/StringUtils";

const getDetailsMap = (
  purchase: PurchaseDto
): Array<{ label: string; value: ReactNode }> => {
  return [
    { label: "Purchase Type", value: purchase.purchaseType },
    {
      label: "Purchase Amount",
      value: StringUtils.formatPriceString(purchase.purchaseAmount),
    },
    {
      label: "Purchase Percentage",
      value: StringUtils.formatPercentageValue(purchase.purchasePercentage),
    },
    {
      label: "Minimum Guarantee",
      value: StringUtils.getBooleanStringValue(purchase.minimumGuarantee),
    },
    {
      label: "Intermediary Sale",
      value: StringUtils.getBooleanStringValue(purchase.isIntermediary),
    },
    {
      label: "Advance Paid",
      value: StringUtils.getBooleanStringValue(purchase.advancePaid),
    },
    ...(purchase.advancePaid
      ? [
          {
            label: "Advance Amount",
            value: StringUtils.formatPriceString(purchase.advanceAmount),
          },
        ]
      : []),
  ];
};

interface PurchaseCostDetailsProps {
  purchase: PurchaseDto;
}

export default function PurchaseCostDetails({
  purchase,
  ...props
}: PurchaseCostDetailsProps & BoxProps) {
  const movieData = getDetailsMap(purchase);

  return (
    <DetailCard title="Cost information" {...props}>
      <Grid container spacing={2}>
        {movieData.map((data, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <DetailItem label={data.label}>{data.value}</DetailItem>
          </Grid>
        ))}
      </Grid>
    </DetailCard>
  );
}
