import { Box, BoxProps, Grid, IconButton, makeStyles } from "@material-ui/core";
import { memo, useCallback } from "react";

import { CountrySetChoose } from "../countrySet/countrySetChoose/CountrySetChoose";
import CustomCheckbox from "../../common/form/CustomCheckbox";
import CustomSpacingGrid from "../../common/layout/CustomSpacingGrid";
import DeleteIcon from "@material-ui/icons/Delete";
import { RightRequestDto } from "../../../dto/right/RightsRequestDto";
import RightTypesInputForm from "./RightTypesInputForm";

const useStyles = makeStyles((theme) => ({
  rightInputContainer: {
    padding: theme.spacing(0, 3),
  },
}));

interface RightFormProps {
  index: number;
  right: RightRequestDto;
  onElementUpdate: (
    index: number,
    key: "rightTypes" | "countrySet" | "exclusive",
    newValue: any
  ) => void;
  onElementDelete: (index: number) => void;
}

function RightForm({
  index,
  right,
  onElementUpdate,
  onElementDelete,
  ...props
}: RightFormProps & BoxProps) {
  const classes = useStyles();

  const onRightTypeUpdate = useCallback(
    (newValue: string[]) => {
      onElementUpdate(index, "rightTypes", newValue);
    },
    [index, onElementUpdate]
  );

  const onCountrySetUpdate = useCallback(
    (newCountrySet: string) => {
      onElementUpdate(index, "countrySet", newCountrySet);
    },
    [index, onElementUpdate]
  );

  const onExclusiveUpdate = useCallback(
    (e) => onElementUpdate(index, "exclusive", e.target.checked),
    [index, onElementUpdate]
  );

  const onDelete = useCallback(() => {
    onElementDelete(index);
  }, [index, onElementDelete]);

  return (
    <Box display="flex" alignItems="center" {...props}>
      <div>#{index + 1}</div>
      <CustomSpacingGrid
        className={classes.rightInputContainer}
        container
        horizontalSpacing={2}
        verticalSpacing={1}
        alignItems="flex-end"
      >
        <Grid item xs={12} md={6}>
          <CountrySetChoose
            countrySet={right.countrySet}
            setCountrySet={onCountrySetUpdate}
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <CustomCheckbox
            checked={right.exclusive}
            onChange={onExclusiveUpdate}
            name="exclusive"
            label="Exclusive"
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <RightTypesInputForm
            value={right.rightTypes}
            setValue={onRightTypeUpdate}
          />
        </Grid>
      </CustomSpacingGrid>
    </Box>
  );
}

export default memo(RightForm);
