import { FormEvent, HTMLProps, createContext } from "react";

import useSingleInvoke from "../hooks/UseSingleInvoke";

export const { Provider: FormStateProvider, Consumer: FormStateConsumer } =
  createContext({ disabled: false });

interface CustomFormProps {
  onSubmit?: (e?: FormEvent) => Promise<void>;
}

export default function CustomForm({
  onSubmit,
  ...props
}: CustomFormProps & Omit<HTMLProps<HTMLFormElement>, "onSubmit">) {
  const [singleInvokeOnSubmit, submitButtonDisabled] =
    useSingleInvoke(onSubmit);

  const customOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    singleInvokeOnSubmit(e);
  };

  return (
    <form {...props} onSubmit={customOnSubmit}>
      <FormStateProvider value={{ disabled: submitButtonDisabled }}>
        {props.children}
      </FormStateProvider>
    </form>
  );
}
