import { RECEIVE_ROLES, REQUEST_ROLES, RoleActionTypes } from "./RoleTypes";

import { Api } from "../../utils/Api";
import { Notification } from "../../utils/Notification";
import { RoleDto } from "../../dto/role/RoleDto";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";

const requestRoles = (): RoleActionTypes => ({
  type: REQUEST_ROLES,
});

const recieveRoles = (roles: RoleDto[]): RoleActionTypes => ({
  type: RECEIVE_ROLES,
  payload: roles,
});

const getAllRolesFromApi = async (): Promise<RoleDto[]> => {
  try {
    const roles = await Api().get<RoleDto[]>("/role");
    return roles.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get all roles");
    return [];
  }
};

const addNewRoleApi = async (roleDto: RoleDto): Promise<void> => {
  try {
    await Api().post<RoleDto>("/role", roleDto);
    Notification.showSuccess("Role added successfully");
  } catch (err) {
    Notification.showApiError(err, "Cannot add new user");
  }
};

const updateRoleDetailsApi = async (roleId: string, roleDto: RoleDto) => {
  try {
    const role = await Api().put<RoleDto>(`/role/${roleId}`, roleDto);
    Notification.showSuccess("Updated role successfully");
    return role.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot update role info");
    throw err;
  }
};

export const getAllRoles =
  (
    force = false
  ): ThunkAction<Promise<void>, RootState, unknown, RoleActionTypes> =>
  async (dispatch, getState) => {
    const { role } = getState();
    if (role.loading || (!force && role.loaded)) {
      return;
    }
    dispatch(requestRoles());
    const roles: RoleDto[] = await getAllRolesFromApi();
    dispatch(recieveRoles(roles));
  };

export const addNewRole =
  (
    newRole: RoleDto
  ): ThunkAction<Promise<void>, RootState, unknown, RoleActionTypes> =>
  async (dispatch) => {
    await addNewRoleApi(newRole);
    await dispatch(getAllRoles(true));
  };

export const updateRole =
  (
    roleId: string,
    roleDto: RoleDto
  ): ThunkAction<Promise<RoleDto>, RootState, unknown, RoleActionTypes> =>
  async (dispatch) => {
    const role = await updateRoleDetailsApi(roleId, roleDto);
    dispatch(getAllRoles(true));
    return Promise.resolve(role);
  };

export const getRoleDetails = async (roleId: string): Promise<RoleDto> => {
  try {
    const role = await Api().get<RoleDto>(`/role/${roleId}`);
    return role.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get role info");
    throw err;
  }
};