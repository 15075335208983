import { CountrySetDto } from "../../dto/countrySet/CountrySetDto";

export const REQUEST_COUNTRY_SETS = "REQUEST_COUNTRY_SETS";
export const RECEIVE_COUNTRY_SETS = "RECEIVE_COUNTRY_SETS";

interface RequestCountrySetsAction {
  type: typeof REQUEST_COUNTRY_SETS;
}

interface ReceiveCountrySetsAction {
  type: typeof RECEIVE_COUNTRY_SETS;
  payload: CountrySetDto[];
}

export type CountrySetActionTypes =
  | RequestCountrySetsAction
  | ReceiveCountrySetsAction;
