import { BoxProps, Grid } from "@material-ui/core";

import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import { ReactNode } from "react";
import { SaleDto } from "../../../../dto/sale/SaleDto";

const getDetailsMap = (
  sale: SaleDto
): Array<{ label: string; value: ReactNode; link?: string }> => {
  return [
    {
      label: "Movie",
      value: sale.movie.name,
      link: `/portal/movies/${sale.movie.id}`,
    },
    { label: "Language", value: sale.language },
  ];
};

interface SaleBasicDetailsProps {
  sale: SaleDto;
}

export default function SalesBasicDetails({
  sale,
  ...props
}: SaleBasicDetailsProps & BoxProps) {
  const saleData = getDetailsMap(sale);

  return (
    <DetailCard title="Sale information" {...props}>
      <Grid container spacing={2}>
        {saleData.map((data, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <DetailItem label={data.label} link={data.link}>
              {data.value}
            </DetailItem>
          </Grid>
        ))}
      </Grid>
    </DetailCard>
  );
}
