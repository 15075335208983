import { Button, ButtonProps } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

interface AddButtonProps {
  label: string;
}

export default function AddButton({
  label,
  ...props
}: AddButtonProps & ButtonProps) {
  return (
    <Button
      {...props}
      variant={props.variant ?? "contained"}
      size={props.size ?? "medium"}
      color={props.color ?? "primary"}
      startIcon={props.startIcon ?? <AddIcon />}
    >
      Add {label}
    </Button>
  );
}
