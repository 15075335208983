import { SaleDto } from "../../dto/sale/SaleDto";

export const REQUEST_SALES = "REQUEST_SALES";
export const RECEIVE_SALES = "RECEIVE_SALES";

interface RequestSaleAction {
  type: typeof REQUEST_SALES;
}

interface ReceiveSaleAction {
  type: typeof RECEIVE_SALES;
  payload: SaleDto[];
}

export type SaleActionTypes = RequestSaleAction | ReceiveSaleAction;
