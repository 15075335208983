import { renderToStaticMarkup } from "react-dom/server";
import NumberFormat from "react-number-format";
import { AgreementExpiryDto } from "../dto/common/AgreementExpirtyDto";
import { PriceOptionalCurrencyDto } from "../dto/common/PriceDto";

export class StringUtils {
  public static formatFieldValue(value?: string): string | undefined {
    return value === undefined || value === "" ? undefined : value;
  }

  public static getBooleanStringValue(value?: boolean | string) {
    if (value === undefined) return "";
    return (typeof value === "boolean" && value) || value === "Yes"
      ? "Yes"
      : "No";
  }

  public static formatNumberField(value: string): number | undefined {
    return value === "" ? undefined : +value;
  }

  public static formatAgreementExpiry(value?: AgreementExpiryDto): string {
    let ans = "";
    if (value?.type) {
      ans += value.type;
    }
    if (value?.duration) {
      ans += ` (${value.duration})`;
    }
    return ans;
  }

  public static formatPriceString(price?: PriceOptionalCurrencyDto): string {
    return price
      ? renderToStaticMarkup(
          <NumberFormat
            displayType="text"
            thousandSeparator={true}
            thousandsGroupStyle="lakh"
            prefix={price.currency}
            value={price.amount}
          />
        ).replace(/<(.|\n)*?>/g, "") // Remove tags in the rendered text
      : "";
  }

  public static formatPercentageValue(value?: string | number) {
    return value ? value + "%" : "";
  }

  public static isNotNull(element?: string) {
    return !!element;
  }

  public static includes(source: string, target: string) {
    return source.toLowerCase().includes(target.toLowerCase());
  }

  public static includesBoolean(source?: boolean, target?: string) {
    return (
      target !== undefined &&
      StringUtils.getBooleanStringValue(source)
        .toLowerCase()
        .includes(target.toLowerCase())
    );
  }

  // Based on https://stackoverflow.com/a/15343790
  public static formatFieldName = (fieldName?: string) =>
    fieldName?.replace(/([a-zA-Z])([A-Z])([a-z])/g, "$1 $2$3");
}
