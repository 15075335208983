import { RECEIVE_USERS, REQUEST_USERS, UserActionTypes } from "./UserTypes";

import { Reducer } from "redux";
import { UserDto } from "../../dto/user/UserDto";

export interface UserState {
  users: UserDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: UserState = {
  users: [],
  loading: false,
  loaded: false,
};

const UserReducer: Reducer<UserState, UserActionTypes> = (
  state: UserState = initialState,
  action: UserActionTypes
) => {
  switch (action.type) {
    case REQUEST_USERS:
      return { ...state, loading: true };
    case RECEIVE_USERS:
      const users = action.payload;
      return { users: [...users], loaded: true, loading: false };
    default:
      return state;
  }
};

export default UserReducer;
