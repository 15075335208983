import {
  Currency,
  CurrencyLabel,
  CurrencyOptions,
} from "../../../dto/common/Currency";
import { Grid, InputLabel } from "@material-ui/core";

import CustomAutocomplete from "./CustomAutocomplete";
import { HTMLProps } from "react";
import PriceAmountField from "./PriceAmountField";
import { PriceRequestDto } from "../../../dto/common/PriceRequestDto";

interface PriceFieldProps {
  label: string;
  value?: PriceRequestDto;
  onChange: (value?: PriceRequestDto) => void;
}

const PriceField = ({
  label,
  value,
  onChange,
  required = false,
  ...props
}: PriceFieldProps & Omit<HTMLProps<HTMLDivElement>, "value" | "onChange">) => {
  return (
    <div {...props}>
      <InputLabel>{label}</InputLabel>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CustomAutocomplete
            label="Currency"
            options={CurrencyOptions}
            getOptionLabel={CurrencyLabel}
            value={value?.currency ?? null}
            required={required}
            onChange={(newValue) =>
              onChange({
                amount: value?.amount ?? "",
                currency: newValue,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <PriceAmountField
            required={required}
            value={value?.amount ?? ""}
            setValue={(amount) =>
              onChange({
                amount,
                currency: value?.currency as Currency,
              })
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PriceField;
