import { Chip, ChipProps, withStyles } from "@material-ui/core";
import { ReactNode, useCallback } from "react";

import { DateUtils } from "../../../../utils/DateUtils";
import { FilterCondition } from "../../../../dto/report/FilterCondition";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { FilterField } from "../../../../dto/report/FilterField";
import { FilterFieldType } from "../../../../dto/report/FilterFieldType";
import FilterUtils from "../../../../utils/FilterUtils";
import { StringUtils } from "../../../../utils/StringUtils";
import TimeUtils from "../../../../utils/TimeUtils";

const formatValue = (
  fields: FilterField[],
  fieldName: string,
  value: any
): ReactNode => {
  const field = FilterUtils.getField(fields, fieldName);
  switch (field?.type) {
    case FilterFieldType.BOOLEAN:
      return StringUtils.getBooleanStringValue(value);
    case FilterFieldType.DATE:
    case FilterFieldType.DURATION:
      return DateUtils.getFormattedDate(value);
    case FilterFieldType.CURRENCY:
      return StringUtils.formatPriceString({ amount: value });
    case FilterFieldType.TIME_LENGTH:
      return TimeUtils.formatTime(value);
    default:
      const options = field?.options ?? [];
      return Array.isArray(value)
        ? value.map(FilterUtils.findOptionLabel(options)).join(", ")
        : `${FilterUtils.findOptionLabel(options)(value)}`;
  }
};

const filterConditionMap = (
  fields: FilterField[],
  filter: FilterDto
): ReactNode => {
  const value = formatValue(fields, filter.field, filter.value);
  switch (filter.condition) {
    case FilterCondition.ALL_OF:
      return <>All of [{value}]</>;
    case FilterCondition.BETWEEN:
      const value1 = formatValue(fields, filter.field, filter.value[0]);
      const value2 = formatValue(fields, filter.field, filter.value[1]);
      return (
        <>
          Between {value1} and {value2}
        </>
      );
    case FilterCondition.EQUALS:
      return <>{value}</>;
    case FilterCondition.GREATER_THAN:
      return <>&gt; {value}</>;
    case FilterCondition.IN:
      return <>In [{value}]</>;
    case FilterCondition.LESS_THAN:
      return <>&lt; {value}</>;
    case FilterCondition.LIKE:
      return <>Like {value}</>;
    case FilterCondition.NOT_EQUALS:
      return <>!= {value}</>;
    case FilterCondition.NOT_IN:
      return <>Not in [{value}]</>;
    case FilterCondition.ONE_OF:
      return <>One of [{value}]</>;
  }
};

const CustomChip = withStyles((theme) => ({
  root: {
    height: 36,
    padding: theme.spacing(0, 0.5),
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
  },
}))(Chip);

interface FilterChipProps {
  filter: FilterDto;
  index: number;
  fields: FilterField[];
  onUpdate: (index: number) => void;
  onDelete: (index: number) => void;
}

export default function FilterChip({
  filter,
  index,
  fields,
  onUpdate,
  onDelete,
  ...props
}: FilterChipProps & Omit<ChipProps, "onDelete">) {
  const onClick = useCallback(() => onUpdate(index), [onUpdate, index]);
  const onDeletePress = useCallback(() => onDelete(index), [onDelete, index]);

  const label = (
    <>
      {StringUtils.formatFieldName(filter.field)}:{" "}
      {filterConditionMap(fields, filter)}
    </>
  );

  return (
    <CustomChip
      {...props}
      label={label}
      key={index}
      onClick={onClick}
      onDelete={onDeletePress}
    />
  );
}
