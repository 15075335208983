import { RoleDto } from "../../dto/role/RoleDto";

export const REQUEST_ROLES = "REQUEST_ROLES";
export const RECEIVE_ROLES = "RECEIVE_ROLES";

interface RequestRolesAction {
  type: typeof REQUEST_ROLES;
}

interface ReceiveRolesAction {
  type: typeof RECEIVE_ROLES;
  payload: RoleDto[];
}

export type RoleActionTypes = RequestRolesAction | ReceiveRolesAction;
