import { RemarkDto } from "../../dto/purchase/remarks/RemarkDto";

export const REQUEST_REMARK = "REQUEST_REMARK";
export const RECEIVE_REMARK = "RECEIVE_REMARK";

interface RequestRemarkAction {
  type: typeof REQUEST_REMARK;
}

interface ReceiveRemarkAction {
  type: typeof RECEIVE_REMARK;
  payload: RemarkDto[];
}

export type RemarkActionTypes =
  | RequestRemarkAction
  | ReceiveRemarkAction;
