import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BoxProps,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useCallback, useState } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 1),
    boxShadow:
      "0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);",
  },
  heading: {
    fontWeight: "bold",
  },
}));

interface DetailCardProps {
  title: string;
}

export default function DetailCard({
  title,
  ...props
}: DetailCardProps & BoxProps) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);

  const handleChange = useCallback((_, isExpanded: boolean) => {
    setExpanded(isExpanded);
  }, []);

  return (
    <Box {...props}>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className={classes.container}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" className={classes.heading}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </Box>
  );
}
