import { Box, Button, Typography, makeStyles } from "@material-ui/core";

import { Api } from "../../../utils/Api";
import { Notification } from "../../../utils/Notification";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: theme.spacing(2, 0),
    "& > *": {
      margin: theme.spacing(2),
    },
  },
}));

export default function Admin() {
  const classes = useStyles();

  const runMigration = useCallback((path: string) => {
    Api()
      .post<string>("/migrations/" + path)
      .then((res) => {
        Notification.showSuccess(res.data ?? "Run successfully");
      })
      .catch((err: any) => {
        Notification.showApiError(err, "Failed to run migration");
      });
  }, []);

  return (
    <Box>
      <Typography variant="h5">ADMIN AREA</Typography>
      <Typography variant="body1" color="error">
        Please do not use this section without explicit instructions from the
        developers
      </Typography>
      <Box className={classes.buttonContainer} display="flex">
        <Button
          variant="contained"
          onClick={() => runMigration("audio-format")}
        >
          Migrate Audio Format
        </Button>
      </Box>
    </Box>
  );
}
