import { CrewDto } from "../dto/movie/CrewDto";
import { CrewRoles } from "../dto/movie/CrewRoles";

export default class MovieUtils {
  static getCrewInfo = (crew: CrewDto[], crewRole: CrewRoles) => {
    return MovieUtils.getCrew(crew, crewRole).join(", ") ?? "-";
  };

  static getCrew = (crew?: CrewDto[], crewRole?: CrewRoles) => {
    return crew?.find((c) => c.role === crewRole)?.people ?? [];
  };

  static updateCrew = (
    crew: CrewDto[],
    crewRole: CrewRoles,
    newPeople: string[]
  ) => {
    const newCrew = crew.filter((cr) => cr.role !== crewRole);
    newCrew.push({ role: crewRole, people: newPeople });
    return newCrew;
  };
}
