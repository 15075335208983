import { Grid, Typography, makeStyles } from "@material-ui/core";

import DashboardCard from "./DashboardCard";
import DashboardCardType from "./DashboardCardType";
import EventAvailable from "@material-ui/icons/EventAvailable";
import { GetPrevilage } from "../../../utils/Previlage";
import MovieIcon from "@material-ui/icons/Movie";
import PeopleIcon from "@material-ui/icons/People";
import PolicyIcon from "@material-ui/icons/Policy";
import PublicIcon from "@material-ui/icons/Public";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1400,
    margin: "auto",
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));


const Dashboard = () => {
  const movieReportPrevilage = GetPrevilage('Reports', 'Movie');
  const purchaseReportPrevilage = GetPrevilage('Reports', 'Purchase');
  const salesReportPrevilage = GetPrevilage('Reports', 'Sales');
  const ownedRightsReportPrevilage = GetPrevilage('Reports', 'Owned Rights');
  
  const classes = useStyles();

  const cards: DashboardCardType[] = [
    {
      title: "Movies",
      description: "Add or manage movies",
      link: "/portal/movies",
      icon: <MovieIcon color="inherit" />,
      isVisible: GetPrevilage('Movies', 'View'),
    },
    {
      title: "Rights",
      description: "Add or manage right types",
      link: "/portal/rights",
      icon: <PolicyIcon color="inherit" />,
      isVisible: GetPrevilage('Rights', 'View'),
    },
    {
      title: "Territory Set",
      description: "Create territory groups for rights",
      link: "/portal/country-set",
      icon: <PublicIcon color="inherit" />,
      isVisible: GetPrevilage('CountrySet', 'View'),
    },
    {
      title: "Purchase",
      description: "Manage movie right purchases",
      link: "/portal/purchase",
      icon: <ShoppingCartIcon color="inherit" />,
      isVisible: GetPrevilage('Purchase', 'View'),
    },
    {
      title: "Sales",
      description: "Manage movie right sales",
      link: "/portal/sales",
      icon: <ReceiptIcon color="inherit" />,
      isVisible: GetPrevilage('Sales', 'View'),
    },
    {
      title: "Movie Report",
      description: "Query movies based on actor, language, release date, etc.",
      link: movieReportPrevilage ? "/portal/report/movies" : "/portal/dashboard",
      icon: <MovieIcon color="inherit" />,
      isVisible: GetPrevilage('Reports', 'Movie'),
    },
    {
      title: "Purchase Report",
      description:
        "Query purchase records based on movie, buyer, rights purchased, etc.",
      link: purchaseReportPrevilage ? "/portal/report/purchase" : "/portal/dashboard",
      icon: <ShoppingCartIcon color="inherit" />,
      isVisible: GetPrevilage('Reports', 'Purchase'),
    },
    {
      title: "Sales Report",
      description:
        "Query sales records based on movie, agreement date, rights sold, etc.",
      link: salesReportPrevilage ? "/portal/report/sales" : "/portal/dashboard",
      icon: <ReceiptIcon color="inherit" />,
      isVisible: GetPrevilage('Reports', 'Sales'),
    },
    {
      title: "Owned Rights (Not Sold)",
      description: "Query rights currently owned for movie",
      link: ownedRightsReportPrevilage ? "/portal/report/owned-rights" : "/portal/dashboard",
      icon: <EventAvailable color="inherit" />,
      isVisible: GetPrevilage('Reports', 'Owned Rights'),
    },
    {
      title: "Users",
      description: "Add or manage users",
      link: "/portal/users",
      icon: <PeopleIcon color="inherit" />,
      isVisible: GetPrevilage('Users', 'View'),
    },
  ];
  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Dashboard
      </Typography>

      <Grid container spacing={4}>
        {cards.map((card, index) => (
          <>
            {card.isVisible ? (
              <DashboardCard card={card} key={index} />
            ) : null}
          </>
        ))}
      </Grid>
    </div>
  );
};

export default Dashboard;
