import { PurchaseStringProperties } from "../../dto/purchase/PurchaseDto";
import { RootState } from "..";
import { makeSelectPurchasePropertyValues } from "./PurchaseSelector";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function usePurchaseProperty(
  property: PurchaseStringProperties
) {
  const propertySelector = useMemo(makeSelectPurchasePropertyValues, []);
  const values = useSelector((state: RootState) =>
    propertySelector(state, property)
  );
  return values;
}
