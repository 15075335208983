import { ChangeEvent, HTMLProps, useCallback } from "react";
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { CrewRoles } from "../../../../dto/movie/CrewRoles";
import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import CustomSpacingGrid from "../../../common/layout/CustomSpacingGrid";
import DeleteIcon from "@material-ui/icons/Delete";
import MovieUtils from "../../../../utils/MovieUtils";
import { SongDto } from "../../../../dto/movie/SongDto";
import TextFieldArray from "../../../common/form/TextFieldArray";
import classNames from "classnames";
import useMovieAutocomplete from "../../../common/hooks/UseMovieAutocomplete";

const useStyles = makeStyles((theme) => ({
  inputRow: {
    display: "flex",
    alignItems: "center",
  },
  inputs: {
    flexGrow: 1,
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  deleteIcon: {
    marginTop: theme.spacing(2),
  },
}));

interface SongFromProps {
  song: SongDto;
  index: number;
  onUpdateSong: (index: number, song: SongDto) => void;
  onDeleteSong: (index: number) => void;
}

export default function SongForm({
  song,
  index,
  onUpdateSong,
  onDeleteSong,
  ...props
}: SongFromProps & HTMLProps<HTMLDivElement>) {
  const classes = useStyles();
  const optionsGenerator = useMovieAutocomplete();

  const onUpdateName = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onUpdateSong(index, { ...song, name: e.target.value });
    },
    [song, index, onUpdateSong]
  );

  const onUpdateGenre = useCallback(
    (genre: string) => {
      onUpdateSong(index, { ...song, genre });
    },
    [song, index, onUpdateSong]
  );

  const onUpdateSinger = useCallback(
    (val: string[]) => {
      onUpdateSong(index, {
        ...song,
        crew: MovieUtils.updateCrew(song.crew, CrewRoles.SINGER, val),
      });
    },
    [song, index, onUpdateSong]
  );

  const onUpdateLyricist = useCallback(
    (val: string[]) => {
      onUpdateSong(index, {
        ...song,
        crew: MovieUtils.updateCrew(song.crew, CrewRoles.LYRICIST, val),
      });
    },
    [song, index, onUpdateSong]
  );

  const onDelete = useCallback(() => {
    onDeleteSong(index);
  }, [index, onDeleteSong]);

  return (
    <div {...props} className={classNames(classes.inputRow, props.className)}>
      <div className={classes.inputs}>
        <CustomSpacingGrid container horizontalSpacing={2} verticalSpacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">Song {index + 1}:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Name"
              type="text"
              required
              fullWidth
              value={song.name}
              onChange={onUpdateName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldArray
              label="Singer"
              type="text"
              name="singer"
              required
              fullWidth
              value={MovieUtils.getCrew(song.crew, CrewRoles.SINGER)}
              onUpdate={onUpdateSinger}
              options={optionsGenerator.people}
              freeSolo
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldArray
              label="Lyricists"
              type="text"
              name="lyricist"
              fullWidth
              value={MovieUtils.getCrew(song.crew, CrewRoles.LYRICIST)}
              onUpdate={onUpdateLyricist}
              options={optionsGenerator.people}
              freeSolo
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutocomplete
              label="Genre"
              type="text"
              fullWidth
              required={false}
              inputValue={song.genre}
              onInputChange={onUpdateGenre}
              options={optionsGenerator.genre}
              freeSolo
            />
          </Grid>
        </CustomSpacingGrid>
      </div>
      <IconButton
        color="secondary"
        onClick={onDelete}
        className={classes.deleteIcon}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}
