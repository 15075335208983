export enum PurchaseType {
  OUTRIGHT = "outright",
  PERCENTAGE = "percentage",
  COMMISSION = "distribution",
}

export const PurchaseTypeOptions = [
  PurchaseType.OUTRIGHT,
  PurchaseType.PERCENTAGE,
  PurchaseType.COMMISSION,
];

export const PurchaseTypeLabels = (option?: PurchaseType) => {
  switch (option) {
    case PurchaseType.OUTRIGHT:
      return "Outright";
    case PurchaseType.PERCENTAGE:
      return "Percentage";
    case PurchaseType.COMMISSION:
      return "Distribution";
    default:
      return "";
  }
};
