import "./Main.scss";

import LoggedOutHeader from "../layouts/header/LoggedOutHeader";
import Login from "../auth/login/Login";

const Main = () => {
  return (
    <div className="app-main">
      <LoggedOutHeader />
      <Login />
    </div>
  );
};

export default Main;
