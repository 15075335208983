import {
  Box,
  Button,
  Grid,
  GridProps,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useCallback, useContext } from "react";

import { AuthContext } from "../../auth/AuthProvider";
import { DateUtils } from "../../../utils/DateUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import { GetPrevilage } from "../../../utils/Previlage";
import { RemarkDto } from "../../../dto/purchase/remarks/RemarkDto";

interface RemarkItemProps {
  remark: RemarkDto;
  index: number;
  onEdit?: (index: number) => void;
  onDelete?: (remarkId: string) => void;
}

export default function RemarkItem({
  remark,
  index,
  onEdit,
  onDelete,
  ...props
}: RemarkItemProps & GridProps) {
  const authDetails = useContext(AuthContext);
  const editPrevilage = GetPrevilage('Purchase', 'Edit');
  const deletePrevilage = GetPrevilage('Purchase', 'Delete');
  const onEditClick = useCallback(() => {
    onEdit?.(index);
  }, [onEdit, index]);

  const onDeleteClick = useCallback(() => {
    onDelete?.(remark?.id ?? "");
  }, [onDelete, remark]);

  return (
    <Grid {...props} container spacing={1} alignItems="center">
      <Grid item xs={1}>
        {index + 1}.
      </Grid>
      <Grid item xs>
        <Typography variant="body1">{remark.remark}</Typography>
        <Typography variant="body2">
          Created By: <span style={{textTransform: "capitalize"}}>{remark.createdBy.name}</span>
        </Typography>
        <Typography variant="body2">
          Updated: {DateUtils.getFormattedDate(remark.timeUpdated)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {authDetails?.auth?.user?.id === remark.createdBy?.id && editPrevilage && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={onEditClick}
            >
              Edit
            </Button>
          )}
          {authDetails?.auth?.user?.id === remark.createdBy?.id && deletePrevilage && (
            <IconButton onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
