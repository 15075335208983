export enum Currency {
  INR = "₹",
  USD = "$",
  EUR = "€",
  SAR = "SR",
  MYR = "RM",
  YEN = "¥",
  KRW = "₩",
  GBP = "£",
  CHF = "CHF",
  SGD = "S$",
  HKD = "HK$",
}

export const CurrencyOptions = [
  Currency.INR,
  Currency.USD,
  Currency.EUR,
  Currency.SAR,
  Currency.MYR,
  Currency.YEN,
  Currency.KRW,
  Currency.GBP,
  Currency.CHF,
  Currency.SGD,
  Currency.HKD,
];

export const CurrencyLabel = (option: Currency) => {
  switch (option) {
    case Currency.INR:
      return "₹ (INR)";
    case Currency.USD:
      return "$ (USD)";
    case Currency.EUR:
      return "€ (EUR)";
    case Currency.SAR:
      return "SR (SAR)";
    case Currency.MYR:
      return "RM (MYR)";
    case Currency.YEN:
      return "¥ (YEN)";
    case Currency.KRW:
      return "₩ (KRW)";
    case Currency.GBP:
      return "£ (GBP)";
    case Currency.CHF:
      return "CHF (CHF)";
    case Currency.SGD:
      return "S$ (SGD)";
    case Currency.HKD:
      return "HK$ (HKD)";
    default:
      return "Currency";
  }
};
