import { Backdrop, Fade, Modal, makeStyles } from "@material-ui/core";
import {
  HTMLAttributes,
  LegacyRef,
  PropsWithChildren,
  createContext,
  forwardRef,
  useCallback,
  useState,
} from "react";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface ModelaContextProps {
  open: (component: JSX.Element) => void;
  close: () => void;
}

export const ModalContext = createContext<ModelaContextProps>({
  open: () => null,
  close: () => null,
});

const ModalComponent = forwardRef(
  (props: HTMLAttributes<HTMLDivElement>, ref: LegacyRef<HTMLDivElement>) => {
    return (
      <div ref={ref} {...props}>
        {props.children}
      </div>
    );
  }
);

export default function ModalProvider({ children }: PropsWithChildren<{}>) {
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState<JSX.Element>();

  const classes = useStyles();

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback((comp: JSX.Element) => {
    setComponent(comp);
    setOpen(true);
  }, []);

  return (
    <ModalContext.Provider value={{ open: onOpen, close: onClose }}>
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ModalComponent>{component}</ModalComponent>
        </Fade>
      </Modal>
      {children}
    </ModalContext.Provider>
  );
}
