export enum PurchaseFieldNames {
  AGREEMENT_DATE = "AgreementDate",
  PRODUCTION_BANNER = "ProductionBanner",
  SELLER = "Seller",
  BUYER = "Buyer",
  VALIDITY = "Validity",
  PURCHASE_AMOUNT = "PurchaseAmount",
  PURCHASE_AMOUNT_CURRENCY = "PurchaseAmountCurrency",
  PURCHASE_AMOUNT_VALUE = "PurchaseAmountValue",
  PURCHASE_TYPE = "PurchaseType",
  PURCHASE_PERCENTAGE = "PurchasePercentage",
  ADVANCE_PAID = "AdvancePaid",
  ADVANCE_AMOUNT = "AdvanceAmount",
  ADVANCE_AMOUNT_CURRENCY = "AdvanceAmountCurrency",
  ADVANCE_AMOUNT_VALUE = "AdvanceAmountValue",
  MINIMUM_GUARANTEE = "MinimumGuarantee",
  IS_INTERMEDIARY = "IsIntermediary",
  MAIN_AGREEMENT = "MainAgreementCount",
  LINK_AGREEMENT = "LinkAgreementCount",
  CENSOR_CERTIFICATE = "CensorCertificateCount",
  LAB_CERTIFICATE = "LabCertificateCount",
  REAL_IMAGE_LETTER = "RealImageLetterCount",
}
