import { HTMLProps } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    "& > *": {
      margin: theme.spacing(1, 3, 0, 0),
    },
  },
}));

export default function FormButtonContainer(props: HTMLProps<HTMLDivElement>) {
  const classes = useStyles();
  return (
    <div
      {...props}
      className={classNames(classes.buttonContainer, props.className)}
    >
      {props.children}
    </div>
  );
}
