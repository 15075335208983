import { useEffect, useState } from "react";

import { Api } from "../../../../utils/Api";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { GetPrevilage } from "../../../../utils/Previlage";
import { Notification } from "../../../../utils/Notification";
import { PurchaseDto } from "../../../../dto/purchase/PurchaseDto";
import { PurchaseReportFields } from "./PurchaseReportFields";
import PurchaseReportResult from "./PurchaseReportResult";
import useCountrySetAutocomplete from "../../../common/hooks/UseCountrySetAutocomplete";
import useMovieAutocomplete from "../../../common/hooks/UseMovieAutocomplete";
import usePurchaseAutocomplete from "../../../common/hooks/UsePurchaseAutocomplete";
import useRightGroupsAutocomplete from "../../../common/hooks/UseRightGroupsAutocomplete";

interface PurchaseReportProps {
  filters: FilterDto[];
  setFilters?: (filters: FilterDto[]) => void;
  tableId?: string;
  title?: string;
}

export default function PurchaseReport({
  filters,
  setFilters,
  tableId,
  title,
}: PurchaseReportProps) {
  const [purchases, setPurchases] = useState<PurchaseDto[]>([]);
  const purchaseReportPrevilage = GetPrevilage('Reports', 'Purchase');

  useEffect(() => {
    if(purchaseReportPrevilage) {
      Api()
        .post<PurchaseDto[]>("/reports/purchase", { filters })
        .then((res) => {
          setPurchases(res.data);
        })
        .catch((err) => {
          Notification.showApiError(err, "Unable to get purchases report");
        });
    }
  }, [filters, purchaseReportPrevilage]);

  const movieOptions = useMovieAutocomplete();
  const purchaseOptions = usePurchaseAutocomplete();
  const rightOptions = useRightGroupsAutocomplete().rights;
  const countrySetOptions = useCountrySetAutocomplete();

  return (
    <>
      {purchaseReportPrevilage ? (
        <PurchaseReportResult
          purchases={purchases}
          filters={filters}
          setFilters={setFilters}
          fields={PurchaseReportFields(
            movieOptions,
            purchaseOptions,
            rightOptions,
            countrySetOptions
          )}
          tableId={tableId}
          title={title}
        />
      ) : (
        null
      )}
    </>
  );
}
