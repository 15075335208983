import { useEffect, useState } from "react";

import { Api } from "../../../../utils/Api";
import { FilterCondition } from "../../../../dto/report/FilterCondition";
import { FilterDto } from "../../../../dto/report/FilterDto";
import { GetPrevilage } from "../../../../utils/Previlage";
import { Notification } from "../../../../utils/Notification";
import { PurchaseDto } from "../../../../dto/purchase/PurchaseDto";
import { PurchaseFieldNames } from "../../../../dto/report/purchase/PurchaseFieldNames";
import { PurchaseReportFields } from "../purchaseReport/PurchaseReportFields";
import PurchaseReportResult from "../purchaseReport/PurchaseReportResult";
import useCountrySetAutocomplete from "../../../common/hooks/UseCountrySetAutocomplete";
import useMovieAutocomplete from "../../../common/hooks/UseMovieAutocomplete";
import usePersistedState from "../../../common/hooks/UsePersistedState";
import usePurchaseAutocomplete from "../../../common/hooks/UsePurchaseAutocomplete";
import useRightGroupsAutocomplete from "../../../common/hooks/UseRightGroupsAutocomplete";

const addTodayAsDefaultValue = (value: FilterDto[]) => {
  for (let filter of value) {
    if (filter.field === PurchaseFieldNames.VALIDITY) {
      if (filter.condition === FilterCondition.EQUALS) {
        filter.value = new Date();
      }
      return value;
    }
  }
  value.unshift({
    field: PurchaseFieldNames.VALIDITY,
    condition: FilterCondition.EQUALS,
    value: new Date(),
  });
  return value;
};

export default function OwnedRightsReport() {
  const { state: filters, setState: setFilters } = usePersistedState<
    FilterDto[]
  >("owned-rights-report-filters", [], addTodayAsDefaultValue);
  const [ownedRights, setOwnedRights] = useState<PurchaseDto[]>([]);
  const ownedRightsReportPrevilage = GetPrevilage('Reports', 'Owned Rights');

  useEffect(() => {
    if(ownedRightsReportPrevilage) {
      Api()
        .post<PurchaseDto[]>("/reports/owned-rights", { filters })
        .then((res) => {
          setOwnedRights(res.data);
        })
        .catch((err) => {
          Notification.showApiError(err, "Unable to get owned rights report");
        });
    }
  }, [filters, ownedRightsReportPrevilage]);

  const movieOptions = useMovieAutocomplete();
  const purchaseOptions = usePurchaseAutocomplete();
  const rightOptions = useRightGroupsAutocomplete().rights;
  const countrySetOptions = useCountrySetAutocomplete();

  return (
    <>
      {ownedRightsReportPrevilage ? (
        <PurchaseReportResult
          title="Owned Rights Report"
          purchases={ownedRights}
          filters={filters}
          setFilters={setFilters}
          fields={PurchaseReportFields(
            movieOptions,
            purchaseOptions,
            rightOptions,
            countrySetOptions
          )}
        />
      ) : (
        null
      )}
    </>
  );
}
