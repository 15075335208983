import {
  MovieStringProperties,
  selectMovieValuesByProperty,
} from "../../dto/movie/MovieDto";

import { RootState } from "..";
import { createSelector } from "reselect";

const selectMovies = (state: RootState) => state.movie.movies;
const selectProperty = (_: RootState, property: MovieStringProperties) =>
  property;

export const makeSelectMoviePropertyValues = () => {
  const selectMoviePropertyValues = createSelector(
    selectMovies,
    selectProperty,
    selectMovieValuesByProperty
  );
  return selectMoviePropertyValues;
};
