import { Button, makeStyles } from "@material-ui/core";

import { Link } from "react-router-dom";
import { PropsWithChildren } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
  },
}));

interface ConditionalLinkProps {
  link?: string;
}

export default function ConditionalLink({
  link,
  children,
}: PropsWithChildren<ConditionalLinkProps>) {
  const classes = useStyles();
  return link ? (
    <Link to={link}>
      <Button variant="text" color="primary" className={classes.button}>
        {children}
      </Button>
    </Link>
  ) : (
    <>{children}</>
  );
}
