import { Button, Paper, Typography, makeStyles } from "@material-ui/core";
import { PlatformType, SaleDto } from "../../../../dto/sale/SaleDto";
import {
  RightRequestDto,
  mapToRightRequestDto,
} from "../../../../dto/right/RightsRequestDto";
import {
  SaleBasicDetailsRequestDto,
  SaleCostDetailsRequestDto,
  SalePlatformDetailsRequestDto,
  SaleRequestDto,
} from "../../../../dto/sale/SaleRequestDto";
import {
  addNewSale,
  getAllSales,
  updateSale,
} from "../../../../store/sale/SaleAction";
import { useCallback, useEffect, useState } from "react";

import CustomForm from "../../../common/form/CustomForm";
import FormButtonContainer from "../../common/FormButtonContainer";
import { PriceRequestDto } from "../../../../dto/common/PriceRequestDto";
import RightsForm from "../../right/RightsForm";
import { RootState } from "../../../../store";
import { SaleActionTypes } from "../../../../store/sale/SaleTypes";
import SaleCostDetailsForm from "./SaleCostDetailsForm";
import SalePlatformDetailsForm from "./SalePlatformDetailsForm";
import SalesBasicDetailsForm from "./SalesBasicDetailsForm";
import { StringUtils } from "../../../../utils/StringUtils";
import SubmitButton from "../../../common/button/SubmitButton";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3),
  },
  platformDetailsForm: {
    margin: theme.spacing(5, 0),
  },
  costDetailsForm: {
    margin: theme.spacing(5, 0),
  },
}));

const resetOtherPlatformFields = (
  sales: SalePlatformDetailsRequestDto
): SalePlatformDetailsRequestDto => ({
  ...sales,
  internet:
    sales.platform === PlatformType.Internet ? sales.internet : undefined,
  television:
    sales.platform === PlatformType.Television ? sales.television : undefined,
});

const DefaultSaleBasicDetails = (
  sale?: SaleDto
): SaleBasicDetailsRequestDto => ({
  movie: sale?.movie.id ?? "",
  language: sale?.language ?? "",
  isDeleted: sale?.isDeleted ?? false,
});

const DefaultSalePlatformDetails = (
  sale?: SaleDto
): SalePlatformDetailsRequestDto => ({
  platform: sale?.platform ?? PlatformType.PayPerUse,
  customerName: sale?.customerName ?? "",
  dateOfPublishing: sale?.dateOfPublishing,
  television: sale?.television,
  internet: sale?.internet,
});

const DefaultSaleCostDetails = (sale?: SaleDto): SaleCostDetailsRequestDto => ({
  agreementDate: sale?.agreementDate,
  agreementExpiry: sale?.agreementExpiry,
  validFrom: sale?.validFrom,
  validTill: sale?.validTill,
  saleAmount: sale?.saleAmount && new PriceRequestDto(sale?.saleAmount),
});

interface SaleFormProps {
  sale?: SaleDto;
}

const SaleForm = ({ sale }: SaleFormProps) => {
  const classes = useStyles();

  const [saleBasicDetails, setSaleBasicDetails] = useState(
    DefaultSaleBasicDetails()
  );
  const [salePlatformDetails, setSalePlatformDetails] = useState(
    DefaultSalePlatformDetails()
  );
  const [saleCostDetails, setSaleCostDetails] = useState(
    DefaultSaleCostDetails(sale)
  );
  const [rights, setRights] = useState<RightRequestDto[]>([]);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, SaleActionTypes>>();
  const history = useHistory();

  const submitSales = async () => {
    const newSale: SaleRequestDto = {
      ...saleBasicDetails,
      customerName: StringUtils.formatFieldValue(
        salePlatformDetails.customerName
      ),
      ...resetOtherPlatformFields(salePlatformDetails),
      ...saleCostDetails,
      rights,
    };

    if (sale && sale.id) {
      await dispatch(updateSale(sale.id, newSale));
      history.push(`/portal/sales/${sale.id}`);
    } else {
      await dispatch(addNewSale(newSale));
      history.push("/portal/sales");
    }
  };

  const resetForm = useCallback(() => {
    setSaleBasicDetails(DefaultSaleBasicDetails(sale));
    setSalePlatformDetails(DefaultSalePlatformDetails(sale));
    setSaleCostDetails(DefaultSaleCostDetails(sale));
    setRights(sale?.rights.map(mapToRightRequestDto) ?? []);
  }, [sale]);

  //dispatch get All Sales
  useEffect(() => {
    dispatch(getAllSales());
  }, [dispatch]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Paper elevation={2} className={classes.container}>
      <CustomForm onSubmit={submitSales}>
        <Typography variant="h5">
          {sale ? "Update Sale" : "Add Sale"}
        </Typography>
        <SalesBasicDetailsForm
          sales={saleBasicDetails}
          setSales={setSaleBasicDetails}
        />
        <SalePlatformDetailsForm
          sales={salePlatformDetails}
          setSales={setSalePlatformDetails}
          className={classes.platformDetailsForm}
        />
        <SaleCostDetailsForm
          platform={salePlatformDetails.platform}
          sales={saleCostDetails}
          setSales={setSaleCostDetails}
          className={classes.costDetailsForm}
        />
        <RightsForm rights={rights} onUpdate={setRights} />
        <FormButtonContainer>
          <SubmitButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {sale ? "Update sale" : "Add sale"}
          </SubmitButton>
          <Button
            type="button"
            variant="text"
            color="secondary"
            size="large"
            onClick={resetForm}
          >
            RESET
          </Button>
        </FormButtonContainer>
      </CustomForm>
    </Paper>
  );
};

export default SaleForm;
