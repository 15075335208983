import { Column } from "../../../components/common/layout/Table";
import { CrewRoles } from "../../movie/CrewRoles";
import { MovieFieldNames } from "../movie/MovieFieldNames";
import { RightFieldNames } from "../right/RightFieldNames";
import { SaleReportDto } from "../../sale/SaleDto";
import { SalesFieldNames } from "./SalesFieldNames";
import TableUtils from "../../../utils/TableUtils";

export const SalesColumns: {
  [key in
    | MovieFieldNames
    | SalesFieldNames
    | RightFieldNames]: Column<SaleReportDto>;
} = {
  MovieId: {
    title: "Movie Id",
    field: "movie.id",
    render: TableUtils.stringValueRender("movie.id"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Name: {
    title: "Movie Name",
    field: "movie.name",
    render: TableUtils.stringValueRender("movie.name"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  WorkingTitle: {
    title: "Movie Working Title",
    field: "movie.workingTitle",
    render: TableUtils.stringValueRender("movie.workingTitle"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  ReleaseTitle: {
    title: "Movie Release Title",
    field: "movie.releaseTitle",
    render: TableUtils.stringValueRender("movie.releaseTitle"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MovieLanguage: {
    title: "Movie Language",
    field: "movie.language",
    render: TableUtils.stringValueRender("movie.language"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MovieGenre: {
    title: "Movie Genre",
    field: "movie.genre",
    render: TableUtils.stringValueRender("movie.genre"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  ReleaseDate: {
    title: "Movie Release Date",
    field: "movie.releaseDate",
    render: TableUtils.dateValueRender("movie.releaseDate"),
    customSort: TableUtils.dateValueCustomSort("movie.releaseDate"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Format: {
    title: "Movie Format",
    field: "movie.format",
    render: TableUtils.stringArrayValueRender("movie.format"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SourceAvailability: {
    title: "Movie Source Availability",
    field: "movie.sourceAvailability",
    render: TableUtils.booleanValueRender("movie.sourceAvailability"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Actor: {
    title: "Actors",
    field: "movie.crew.actor",
    render: TableUtils.movieCrewValueRender("movie.crew", CrewRoles.ACTOR),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Director: {
    title: "Directors",
    field: "movie.crew.director",
    render: TableUtils.movieCrewValueRender("movie.crew", CrewRoles.DIRECTOR),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Cinematographer: {
    title: "Cinematographer",
    field: "movie.crew.cinematographer",
    render: TableUtils.movieCrewValueRender(
      "movie.crew",
      CrewRoles.CINEMATOGRAPHER
    ),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Lyricist: {
    title: "Lyricist",
    field: "movie.crew.lyricist",
    render: TableUtils.movieCrewValueRender("movie.crew", CrewRoles.LYRICIST),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  MusicDirector: {
    title: "Music Director",
    field: "movie.crew.musicDirector",
    render: TableUtils.movieCrewValueRender(
      "movie.crew",
      CrewRoles.MUSIC_DIRECTOR
    ),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Producer: {
    title: "Producer",
    field: "movie.crew.producer",
    render: TableUtils.movieCrewValueRender("movie.crew", CrewRoles.PRODUCER),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Singer: {
    title: "Singer",
    field: "movie.songs.crew.singer",
    render: TableUtils.songCrewValueRender("movie.songs", CrewRoles.SINGER),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },

  PostersCount: {
    title: "Posters",
    field: "movie.posters",
    render: TableUtils.attachmentValueRender("posters"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  TrailersCount: {
    title: "Trailers",
    field: "movie.trailers",
    render: TableUtils.attachmentValueRender("movie.trailers"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SaleLanguage: {
    title: "Sale Language",
    field: "language",
  },
  SalePlatform: {
    title: "Sale Platform",
    field: "platform",
  },
  SaleCustomerName: {
    title: "Customer Name",
    field: "customerName",
  },
  SaleDateOfPublish: {
    title: "Date of Publishing",
    field: "dateOfPublishing",
    render: TableUtils.dateValueRender("dateOfPublishing"),
    customSort: TableUtils.dateValueCustomSort("dateOfPublishing"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  PermittedRuns: {
    title: "Permitted Runs",
    field: "television.permittedRuns",
    render: TableUtils.stringValueRender("television.permittedRuns"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  InternetMedia: {
    title: "Internet Media",
    field: "internet.media",
    render: TableUtils.stringValueRender("internet.media"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  InternetChannelName: {
    title: "Internet Channel Name",
    field: "internet.channelName",
    render: TableUtils.stringValueRender("internet.channelName"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  JukeboxType: {
    title: "Jukebox type",
    field: "internet.jukebox.type",
    render: TableUtils.stringValueRender("internet.jukebox.type"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  JukeboxSongType: {
    title: "Jukebox Song type",
    field: "internet.jukebox.songType",
    render: TableUtils.stringValueRender("internet.jukebox.songType"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  JukeboxName: {
    title: "Jukebox Name",
    field: "internet.jukebox.name",
    render: TableUtils.stringValueRender("internet.jukebox.name"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  JukeboxVideoId: {
    title: "Jukebox Video Id",
    field: "internet.jukebox.videoId",
    render: TableUtils.stringValueRender("internet.jukebox.videoId"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  JukeboxItemDuration: {
    title: "Jukebox item duration",
    field: "internet.jukebox.itemDuration",
    render: TableUtils.timeDurationValueRender("internet.jukebox.itemDuration"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  JukeboxDuration: {
    title: "Jukebox duration",
    field: "internet.jukebox.jukeboxDuration",
    render: TableUtils.timeDurationValueRender(
      "internet.jukebox.jukeboxDuration"
    ),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  AgreementDate: {
    title: "Agreement Date",
    field: "agreementDate",
    render: TableUtils.dateValueRender("agreementDate"),
    customSort: TableUtils.dateValueCustomSort("agreementDate"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  Validity: {
    title: "Validity",
    field: "validFrom",
    render: TableUtils.dateRangeValueRender("validFrom", "validTill"),
    customSort: TableUtils.dateValueCustomSort("validFrom"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SaleAmount: {
    title: "Sale Amount",
    field: "saleAmount",
    render: TableUtils.priceValueRender("saleAmount"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SaleAmountCurrency: {
    title: "Sale Amount (Currency)",
    field: "saleAmount.currency",
    render: TableUtils.stringValueRender("saleAmount.currency"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SaleAmountValue: {
    title: "Sale Amount (Value)",
    field: "saleAmount.amount",
    render: TableUtils.stringValueRender("saleAmount.amount"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SaleAgreementCount: {
    title: "Sale Agreement",
    field: "saleAgreement",
    render: TableUtils.attachmentValueRender("saleAgreement"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  SaleInvoicesCount: {
    title: "Sale Invoices",
    field: "saleInvoices",
    render: TableUtils.attachmentValueRender("saleInvoices"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  RightType: {
    title: "Right Types",
    field: "rights.rightType",
    render: TableUtils.rightTypeValueRender("rights"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
    cellStyle: {
      minWidth: 300,
    },
  },
  RightTerritory: {
    title: "Right Territory",
    field: "rights.countrySet",
    render: TableUtils.rightCountryValueRender("rights"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  RightIncluded: {
    title: "Right Exclusive",
    field: "rights.exclusive",
    render: TableUtils.booleanValueRender("rights.0.exclusive"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
};
