import { AxiosError } from "axios";

export default interface AppErrorDto {
  message: string;
  status: number;
  blocking: boolean;
  errors: any;
}

export const isBlockingError = (err: any) =>
  (err as AxiosError<AppErrorDto>).response?.data.blocking ?? true;
