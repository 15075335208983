import { ClipboardEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import { RootState } from "../../../../store";
import { getAllCountrySets } from "../../../../store/countrySet/CountrySetAction";

interface CountrySetChooseProps {
  countrySet: string;
  setCountrySet: (countrySet: string) => void;
  label?: string;
  disabled?: boolean;
  onPaste?: (e: ClipboardEvent) => void;
}

export function CountrySetChoose(props: CountrySetChooseProps) {
  const countrySets = useSelector(
    (state: RootState) => state.countrySet.countrySets
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCountrySets());
  }, [dispatch]);

  return (
    <CustomAutocomplete
      value={
        countrySets.find((countrySet) => countrySet.id === props.countrySet) ??
        null
      }
      onChange={(newCountrySet) => props.setCountrySet(newCountrySet?.id ?? "")}
      options={countrySets}
      getOptionLabel={(countrySet) => countrySet?.name ?? ""}
      label={props.label ?? "Territory Set"}
      disabled={props.disabled}
      onPaste={props.onPaste}
    />
  );
}
