import {
  CountrySetActionTypes,
  RECEIVE_COUNTRY_SETS,
  REQUEST_COUNTRY_SETS,
} from "./CountrySetTypes";

import { CountrySetDto } from "../../dto/countrySet/CountrySetDto";
import { Reducer } from "redux";

export interface CountrySetState {
  countrySets: CountrySetDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: CountrySetState = {
  countrySets: [],
  loading: false,
  loaded: false,
};

const CountrySetReducer: Reducer<CountrySetState, CountrySetActionTypes> = (
  state: CountrySetState = initialState,
  action: CountrySetActionTypes
) => {
  switch (action.type) {
    case REQUEST_COUNTRY_SETS:
      return { ...state, loading: true };
    case RECEIVE_COUNTRY_SETS:
      const countrySets = action.payload;
      return { countrySets: [...countrySets], loading: false, loaded: true };
    default:
      return state;
  }
};

export default CountrySetReducer;
