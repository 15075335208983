import BackTo from "../../../common/button/BackTo";
import SaleForm from "../salesForm/SalesForm";

const SaleAdd = () => {
  return (
    <div>
      <BackTo name="Sales" path="/portal/sales" />
      <SaleForm />
    </div>
  );
};

export default SaleAdd;
