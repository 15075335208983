import { Button, Link, makeStyles } from "@material-ui/core";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    textAlign: "left",
    lineHeight: theme.typography.body1.lineHeight,
    padding: 0,
  },
}));

interface MediaPreviewProps {
  url: string;
  name?: string;
  index?: number;
}

export default function MediaPreview({ url, name, index }: MediaPreviewProps) {
  const classes = useStyles();
  return (
    <Link
      variant="body1"
      color="primary"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        color="primary"
        endIcon={<OpenInNewIcon />}
        className={classes.button}
      >
        {name ? name : `File ${index ? index : ""}`}
      </Button>
    </Link>
  );
}
