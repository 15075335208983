import { AgreementExpiryDto } from "../common/AgreementExpirtyDto";
import { MediaDto } from "../common/MediaDto";
import { MovieDto } from "../movie/MovieDto";
import { PriceDto } from "../common/PriceDto";
import { PurchaseStatus } from "./PurchaseStatus";
import { PurchaseType } from "./PurchaseType";
import { RemarkDto } from "./remarks/RemarkDto";
import { RightDto } from "../right/RightDto";

export interface PurchaseBasicDetailsDto {
  movie: MovieDto;
  agreementDate?: Date;
  productionBanner?: string;
  seller?: string;
  buyer?: string;
  validFrom?: Date;
  agreementExpiry?: AgreementExpiryDto;
  validTill?: Date;
}

export interface PurchaseDto extends PurchaseBasicDetailsDto {
  id?: string;
  purchaseType?: PurchaseType;
  purchaseAmount?: PriceDto;
  purchasePercentage?: number;
  minimumGuarantee?: boolean;
  advancePaid: boolean;
  advanceAmount?: PriceDto;
  isIntermediary?: boolean;
  rights: RightDto[];
  intermediaryRights: RightDto[];
  status: PurchaseStatus;
  mainAgreement: MediaDto[];
  linkAgreement: MediaDto[];
  censorCertificate: MediaDto[];
  labCertificate: MediaDto[];
  realImageLetter: MediaDto[];
  remarks: RemarkDto[];
  isDeleted: boolean;
}

export interface PurchaseReportDto extends PurchaseDto {
  parentId?: string;
}

export type PurchaseStringProperties = "productionBanner" | "seller" | "buyer";

export const selectPurchaseValuesByProperty = (
  purchases: PurchaseDto[],
  key: PurchaseStringProperties
) => {
  const values = purchases
    .map((purchase) => purchase[key] ?? "")
    .filter((value) => value !== "");
  return Array.from(new Set(values));
};
