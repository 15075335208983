import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useContext, useState } from 'react';
import { addNewRole, updateRole } from "../../../../store/role/RoleAction";

import { AuthContext } from "../../../auth/AuthProvider";
import CustomCheckbox from "../../../common/form/CustomCheckbox";
import CustomForm from '../../../common/form/CustomForm';
import { RoleActionTypes } from "../../../../store/role/RoleTypes";
import { RoleCategoryDto } from "../../../../dto/role/RoleCategoryDto";
import { RoleCategoryOptionDto } from "../../../../dto/role/RoleCategoryOptionDto";
import { RoleDto } from "../../../../dto/role/RoleDto";
import { RootState } from "../../../../store";
import SubmitButton from '../../../common/button/SubmitButton';
import { ThunkDispatch } from "redux-thunk";
import roleConfigs from '../roleConfig.json';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  formInputContainer: {
    width: `calc(100% + ${theme.spacing(6)}px)`,
    margin: theme.spacing(0, -3),
    "& > *": {
      padding: theme.spacing(1, 3),
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(1.5),
    "& > *": {
      margin: theme.spacing(1, 3, 1, 0),
    },
  },
}));

interface RoleFormProps {
  role?: RoleDto;
}

const RoleForm = ({ role }: RoleFormProps) => {
  const authDetails = useContext(AuthContext);
  const [roleConfig, setRoleConfig] = useState(role ? JSON.parse(JSON.stringify(role.previlages)) : JSON.parse(JSON.stringify(roleConfigs)));
  const classes = useStyles();
  const [rolename, setRolename] = useState(role ? role.name : "");
  const history = useHistory();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, RoleActionTypes>>();
  
  const resetForm = useCallback(() => {
    setRolename("");
    setRoleConfig(roleConfigs);
  }, []);

  const roleSubmit = async () => {
    const prepareReqBody: RoleDto = {
      name: rolename,
      previlages: roleConfig
    };
    if (role && role.id) {
      await dispatch(updateRole(role.id, prepareReqBody));
      history.push(`/portal/roles/${role.id}`);
      authDetails.updateAuth();
    } else {
      await dispatch(addNewRole(prepareReqBody));
      history.push("/portal/roles");
      authDetails.updateAuth();
    }
  }

  const optionChangeFn = (roleParam: RoleCategoryDto, roleoptionParam: RoleCategoryOptionDto, valueParam: boolean) => {
    const roleCategoryIndex = roleConfig.findIndex((element: { name: string; }) => element.name === roleParam.name );
    const roleCategoryOptionIndex = roleConfig[roleCategoryIndex].options.findIndex((element: { name: string; }) => element.name === roleoptionParam.name );
    roleConfig[roleCategoryIndex].options[roleCategoryOptionIndex].value = valueParam;
    setRoleConfig([...roleConfig]);
  }
  return (
    <div>
      <Paper elevation={2} className={classes.container}>
        <Typography variant="h6">{role ? "Update Role" : "Add Role"}</Typography>
        <CustomForm onSubmit={roleSubmit}>
          <Grid container className={classes.formInputContainer}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                type="text"
                name="name"
                fullWidth
                required
                disabled={authDetails.auth?.user?.roleDetails.name.toLowerCase() !== 'admin' ? true : false}
                value={rolename}
                onChange={(e) => setRolename(e.target.value)}
                helperText={role ? "" : "Unique name for each role"}
              />
            </Grid>
          </Grid>
          {roleConfig && roleConfig.length> 0 && (
            <div>
              {roleConfig.map((role: RoleCategoryDto) => (
                <div key={role.name}>
                  <h4>{role.name}</h4>
                  {role.options && role.options.length > 0 && (
                    <div>
                      {role.options.map((roleoption: any) => (
                        <CustomCheckbox
                          key={`${role.name}-${roleoption.name}`}
                          checked={roleoption.value}
                          onChange={(e) => optionChangeFn(role, roleoption, e.target.checked)}
                          name={roleoption.name}
                          label={roleoption.name}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className={classes.buttonContainer}>
            <SubmitButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {!role ? 'ADD' : 'Update'} ROLE
            </SubmitButton>
            <Button
              type="button"
              variant="text"
              color="secondary"
              size="large"
              onClick={resetForm}
            >
              RESET
            </Button>
          </div>
        </CustomForm>
      </Paper>
    </div>
  )
}

export default RoleForm
