import { UserDto } from "../../dto/user/UserDto";

export const REQUEST_USERS = "REQUEST_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";

interface RequestUsersAction {
  type: typeof REQUEST_USERS;
}

interface ReceiveUsersAction {
  type: typeof RECEIVE_USERS;
  payload: UserDto[];
}

export type UserActionTypes = RequestUsersAction | ReceiveUsersAction;
