import { Box, Grid, makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";

import AddButton from "../../common/button/AddButton";
import DetailCard from "../common/DetailCard";
import { MediaDto } from "../../../dto/common/MediaDto";
import MediaFormPopup from "./MediaFormPopup";
import MediaItem from "./MediaItem";

const useStyle = makeStyles((theme) => ({
  addButtonContainer: {
    marginTop: theme.spacing(2),
  },
}));

interface MediaListAndUpdateProps<T> {
  name: T;
  label: string;
  medias?: MediaDto[];
  onUpdateMedias?: (name: T, medias: MediaDto[]) => Promise<void>;
  onUploadMedia?: (name: T, media: MediaDto) => Promise<void>;
  editPrevilage: boolean;
}

export default function MediaListAndUpdate<T>({
  name,
  label,
  medias,
  onUpdateMedias,
  onUploadMedia,
  editPrevilage
}: MediaListAndUpdateProps<T>) {
  const classes = useStyle();

  const [open, setOpen] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(-1);

  const onOpenForm = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseForm = useCallback(() => {
    setOpen(false);
    setEditIndex(-1);
  }, []);

  const onStartEdit = useCallback(
    (i: number) => {
      if (medias && i >= 0 && i < medias.length) {
        setEditIndex(i);
        onOpenForm();
      }
    },
    [medias, onOpenForm]
  );

  const onAddMedia = useCallback(
    async (media: MediaDto) => {
      await onUploadMedia?.(name, media);
      onCloseForm();
    },
    [name, onUploadMedia, onCloseForm]
  );

  const onFinishEdit = useCallback(
    async (media: MediaDto) => {
      if (medias && editIndex !== undefined) {
        const temp = [...medias];
        if (editIndex >= 0 && editIndex < temp.length) {
          temp[editIndex] = { ...media };
        }
        await onUpdateMedias?.(name, temp);
        setEditIndex(-1);
      }
      onCloseForm();
    },
    [medias, name, editIndex, onUpdateMedias, onCloseForm]
  );

  const onDelete = useCallback(
    async (i: number) => {
      if (medias) {
        if (i >= medias.length || i < 0) {
          return;
        }
        const temp = [...medias];
        temp.splice(i, 1);
        await onUpdateMedias?.(name, temp);
      }
    },
    [medias, name, onUpdateMedias]
  );

  return (
    <DetailCard title={label}>
      <MediaFormPopup
        label={label}
        open={open}
        onClose={onCloseForm}
        onAddMedia={onAddMedia}
        media={editIndex >= 0 ? medias?.[editIndex] : undefined}
        onUpdateMedia={onFinishEdit}
      />

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Grid container spacing={1}>
          {medias?.map((media, i) => (
            <Grid item xs={12} key={i}>
              <MediaItem
                media={media}
                index={i}
                onEdit={onStartEdit}
                onDelete={onDelete}
              />
            </Grid>
          ))}
        </Grid>
        {editPrevilage && (
          <div className={classes.addButtonContainer}>
            <AddButton onClick={onOpenForm} label={label} />
          </div>
        )}
      </Box>
    </DetailCard>
  );
}
