import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Logout from "../components/auth/logout/Logout";
import Main from "../components/main/Main";
import PortalLayout from "../components/portal/layout/PortalLayout";
import PortalRoute from "../components/portal/PortalRoute";
import ScrollToTop from "./ScrollToTop";

export default function AppRouter() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/portal/logout" component={Logout} />
        <PortalRoute path="/portal" component={PortalLayout} />
      </Switch>
    </Router>
  );
}
