import Table, { Column } from "../../../common/layout/Table";
import { deletePurchase, getAllPurchases } from "../../../../store/purchase/PurchaseAction";
import { useDispatch, useSelector } from "react-redux";

import AddButtonRow from "../../common/AddButtonRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { PurchaseColumns } from "../../../../dto/report/purchase/PurchaseColumns";
import { PurchaseDto } from "../../../../dto/purchase/PurchaseDto";
import { RootState } from "../../../../store";
import { useEffect } from "react";
import useRowClickListener from "../../../common/hooks/UseRowClickListener";

const columns: Column<PurchaseDto>[] = [
  PurchaseColumns.Name,
  PurchaseColumns.Buyer,
  PurchaseColumns.Seller,
  PurchaseColumns.Validity,
  PurchaseColumns.AdvancePaid,
  PurchaseColumns.MinimumGuarantee,
];

const allColumns = Object.values(PurchaseColumns);

const PurchaseList = () => {
  const addPrevilage = GetPrevilage('Purchase', 'Add');
  const viewPrevilage = GetPrevilage('Purchase', 'View');
  const deletePrevilage = GetPrevilage('Purchase', 'Delete');
  const purchaseReportPrevilage = GetPrevilage('Reports', 'Purchase');

  const purchases = useSelector((state: RootState) => state.purchase.purchases);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPurchases(true));
  }, [dispatch]);

  const goToPurchaseDetail = useRowClickListener((purchase?: PurchaseDto) => {
    if (purchase?.id && viewPrevilage) {
      return `/portal/purchase/${purchase.id}`;
    }
  });

  const deletePurchaseFn = async (purchase: any) => {
    if (purchase && purchase.id) {
      await dispatch(deletePurchase(purchase.id));
      dispatch(getAllPurchases());
    }
  }

  return (
    <div>
      <AddButtonRow route="/portal/purchase/add" label="Purchase" addPrevilage={addPrevilage} />
      <Table
        id="purchase-list"
        title="Purchase List"
        data={purchases}
        columns={columns}
        allColumns={allColumns}
        reportPrevilage={purchaseReportPrevilage}
        onRowClick={goToPurchaseDetail}
        options={{
          actionsColumnIndex: -1
        }}
        onRowDelete={deletePrevilage ? deletePurchaseFn : undefined}
      />
    </div>
  );
};

export default PurchaseList;
