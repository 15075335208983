import { Box, Toolbar, Typography, makeStyles } from "@material-ui/core";

import logo from "../../../assets/img/aplogo.png";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
  container: {
    width: "100%",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  logo: {
    maxWidth: 70,
    margin: "0 20px",
  },
  title: {
    textAlign: "center",
  },
}));

export default function LoggedOutHeader() {
  const classes = useStyles();
  return (
    <Toolbar className={classes.header}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        className={classes.container}
      >
        <img src={logo} alt="Logo" className={classes.logo} />
        <Typography variant="h5" color="primary" className={classes.title}>
          Rights Management Platform
        </Typography>
      </Box>
    </Toolbar>
  );
}
