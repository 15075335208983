import Table, { Column } from "../../../common/layout/Table";
import { deleteMovie, getAllMovies } from "../../../../store/movie/MovieAction";
import { useDispatch, useSelector } from "react-redux";

import AddButtonRow from "../../common/AddButtonRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { MovieColumns } from "../../../../dto/report/movie/MovieColumns";
import { MovieDto } from "../../../../dto/movie/MovieDto";
import { RootState } from "../../../../store";
import { useEffect } from "react";
import useRowClickListener from "../../../common/hooks/UseRowClickListener";

const columns: Column<MovieDto>[] = [
  MovieColumns.Name,
  MovieColumns.MovieLanguage,
  MovieColumns.MovieGenre,
  MovieColumns.Format,
  MovieColumns.ReleaseDate,
];

const allColumns = Object.values(MovieColumns);

const MoviesList = () => {
  const viewPrevilage = GetPrevilage('Movies', 'View');
  const addPrevilage = GetPrevilage('Movies', 'Add');
  const movieReportPrevilage = GetPrevilage('Reports', 'Movie');
  const deletePrevilage = GetPrevilage('Movies', 'Delete');
    
  const movies = useSelector((state: RootState) => state.movie.movies);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMovies());
  }, [dispatch]);

  const goToMovieDetail = useRowClickListener((movie?: MovieDto) => {
    if (movie?.id && viewPrevilage) {
      return `/portal/movies/${movie.id}`;
    }
  });

  const deleteMovieFn = async (movie: any) => {
    if (movie && movie.id) {
      await dispatch(deleteMovie(movie.id));
      dispatch(getAllMovies());
    }
  }

  return (
    <div>
      <AddButtonRow route="/portal/movies/add" label="Movie" addPrevilage={addPrevilage} />
      <Table
        id="movie-list"
        title="Movies List"
        data={movies}
        columns={columns}
        allColumns={allColumns}
        reportPrevilage={movieReportPrevilage}
        onRowClick={goToMovieDetail}
        options={{
          actionsColumnIndex: -1
        }}
        onRowDelete={deletePrevilage ? deleteMovieFn : undefined}
      />
    </div>
  );
};

export default MoviesList;
