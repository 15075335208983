import { BoxProps, Grid } from "@material-ui/core";

import { DateUtils } from "../../../../utils/DateUtils";
import DetailCard from "../../common/DetailCard";
import DetailItem from "../../common/DetailItem";
import { ReactNode } from "react";
import { SaleDto } from "../../../../dto/sale/SaleDto";
import { StringUtils } from "../../../../utils/StringUtils";

const getDetailsMap = (
  sale: SaleDto
): Array<{ label: string; value: ReactNode; link?: string }> => {
  return [
    {
      label: "Agreeement Date",
      value: DateUtils.getFormattedDate(sale.agreementDate),
    },
    {
      label: "Agreement Expiry",
      value: StringUtils.formatAgreementExpiry(sale.agreementExpiry),
    },
    {
      label: "Validity",
      value: DateUtils.getFormattedDuration(sale.validFrom, sale.validTill),
    },
    {
      label: "Sale Amount",
      value: StringUtils.formatPriceString(sale.saleAmount),
    },
  ];
};

interface SalesCostDetailsProps {
  sale: SaleDto;
}

export default function SalesCostDetails({
  sale,
  ...props
}: SalesCostDetailsProps & BoxProps) {
  const saleData = getDetailsMap(sale);

  return (
    <DetailCard title="Cost Information" {...props}>
      <Grid container spacing={2}>
        {saleData.map((data, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <DetailItem label={data.label} link={data.link}>
              {data.value}
            </DetailItem>
          </Grid>
        ))}
      </Grid>
    </DetailCard>
  );
}
