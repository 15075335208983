import { PurchaseDto } from "../../dto/purchase/PurchaseDto";

export const REQUEST_PURCHASES = "REQUEST_PURCHASES";
export const RECEIVE_PURCHASES = "RECEIVE_PURCHASES";

interface RequestPurchasesAction {
  type: typeof REQUEST_PURCHASES;
}

interface ReceivePurchasesAction {
  type: typeof RECEIVE_PURCHASES;
  payload: PurchaseDto[];
}

export type PurchaseActionTypes =
  | RequestPurchasesAction
  | ReceivePurchasesAction;
