import { AuthContext } from "../components/auth/AuthProvider";
import { useContext } from "react";

export const GetPrevilage = (moduleName: string, actionName: string) => {
  const authDetails = useContext(AuthContext);
  let previlageFlag: any = false;
  previlageFlag = authDetails?.auth?.user?.roleDetails?.previlages
    .filter(item => item.name === moduleName)[0].options
    .filter(option => option.name === actionName)[0].value;
  return previlageFlag;
}