import { Route, Switch, useRouteMatch } from "react-router-dom";

import { GetPrevilage } from "../../../utils/Previlage";
import { PAGE_NOT_AUTHORIZED } from "../../../constants/constants";
import PurchaseAdd from "./purchaseAdd/PurchaseAdd";
import PurchaseDetails from "./purchaseDetails/PurchaseDetails";
import PurchaseEdit from "./purchaseEdit/PurchaseEdit";
import PurchaseList from "./purchaseList/PurchaseList";

const Purchase = () => {
  const match = useRouteMatch();
  const viewPrevilage = GetPrevilage('Purchase', 'View');
  
  return (
    <>
      {viewPrevilage ? (
        <Switch>
          <Route path={match.path} exact component={PurchaseList} />
          <Route path={`${match.path}/add`} component={PurchaseAdd} />
          <Route
            path={`${match.path}/:purchaseId`}
            exact
            component={PurchaseDetails}
          />
          <Route path={`${match.path}/:purchaseId/edit`} component={PurchaseEdit} />
        </Switch>
      ) : (
        <span>{PAGE_NOT_AUTHORIZED}</span>
      )}
    </>
  );
};

export default Purchase;
