import BackTo from "../../../common/button/BackTo";
import RoleForm from "../roleForm/RoleForm";

const AddRole = () => {
  return (
    <div>
      <BackTo name="Roles" path="/portal/roles" />
      <RoleForm />
    </div>
  );
};

export default AddRole;
