import { FilterDto } from "../../../../dto/report/FilterDto";
import PurchaseReport from "./PurchaseReport";
import { Typography } from "@material-ui/core";
import usePersistedState from "../../../common/hooks/UsePersistedState";

export default function PurchaseReportPage() {
  const { state: filters, setState: setFilters } = usePersistedState<
    FilterDto[]
  >("purchase-report-filters", []);

  return (
    <div>
      <Typography variant="h5">Purchase Report</Typography>
      <PurchaseReport filters={filters} setFilters={setFilters} />
    </div>
  );
}
