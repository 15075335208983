import { Route, Switch, useRouteMatch } from "react-router-dom";

import AddRight from "./addRight/AddRight";
import EditRight from "./editRight/EditRight";
import { GetPrevilage } from "../../../utils/Previlage";
import { PAGE_NOT_AUTHORIZED } from "../../../constants/constants";
import RightDetails from "./rightDetails/RightDetails";
import RightsList from "./rightList/RightsList";

export default function RightPage() {
  const match = useRouteMatch();
  const viewPrevilage = GetPrevilage('Rights', 'View');
  
  return (
    <>
      {viewPrevilage ? (
        <Switch>
          <Route path={match.path} exact component={RightsList} />
          <Route path={`${match.path}/add`} exact component={AddRight} />
          <Route path={`${match.path}/:rightId`} exact component={RightDetails} />
          <Route path={`${match.path}/:rightId/edit`} exact component={EditRight} />
        </Switch>
      ) : (
        <span>{PAGE_NOT_AUTHORIZED}</span>
      )}
    </>
  );
}
