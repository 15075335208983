import Table, { Column } from "../../../common/layout/Table";
import { useDispatch, useSelector } from "react-redux";

import AddButtonRow from "../../common/AddButtonRow";
import { CountrySetDto } from "../../../../dto/countrySet/CountrySetDto";
import { GetPrevilage } from "../../../../utils/Previlage";
import { RootState } from "../../../../store";
import TableUtils from "../../../../utils/TableUtils";
import { getAllCountrySets } from "../../../../store/countrySet/CountrySetAction";
import { useEffect } from "react";
import useRowClickListener from "../../../common/hooks/UseRowClickListener";

const columns: Column<CountrySetDto>[] = [
  { title: "Name", field: "name" },
  {
    title: "Territories",
    field: "countries",
    render: TableUtils.stringArrayValueRender("countries"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
  {
    title: "Include/Exclude",
    field: "included",
    render: (rowData) => (rowData.included ? "Include" : "Exclude"),
    customFilterAndSearch: TableUtils.customFilterAndSerachForCustomRender,
  },
];

export default function CountrySetList() {
  const addPrevilage = GetPrevilage('CountrySet', 'Add');
  const viewPrevilage = GetPrevilage('CountrySet', 'View');
  const editPrevilage = GetPrevilage('CountrySet', 'Edit');
    
  const countrySets = useSelector(
    (state: RootState) => state.countrySet.countrySets
  );

  const dispatch = useDispatch();

  const goToCountrySetDetails = useRowClickListener(
    (countrySet?: CountrySetDto) => {
      if (countrySet?.id && viewPrevilage) {
        return `/portal/country-set/${countrySet.id}`;
      }
    }
  );

  useEffect(() => {
    dispatch(getAllCountrySets());
  }, [dispatch]);

  return (
    <div>
      <AddButtonRow route="/portal/country-set/add" label="Territory Set" addPrevilage={addPrevilage} />
      <Table
        id="country-set-list"
        title="Territory Sets"
        data={countrySets}
        columns={columns}
        reportPrevilage={editPrevilage}
        onRowClick={goToCountrySetDetails}
      />
    </div>
  );
}
