import {
  CountrySetActionTypes,
  RECEIVE_COUNTRY_SETS,
  REQUEST_COUNTRY_SETS,
} from "./CountrySetTypes";

import { Api } from "../../utils/Api";
import { CountrySetDto } from "../../dto/countrySet/CountrySetDto";
import { Notification } from "../../utils/Notification";
import { RootState } from "..";
import { ThunkAction } from "redux-thunk";

const requestCountrySets = (): CountrySetActionTypes => ({
  type: REQUEST_COUNTRY_SETS,
});

const receiveCountrySets = (
  countrySets: CountrySetDto[]
): CountrySetActionTypes => ({
  type: RECEIVE_COUNTRY_SETS,
  payload: countrySets,
});

const getAllCountrySetsFromApi = async (): Promise<CountrySetDto[]> => {
  try {
    const countrySets = await Api().get<CountrySetDto[]>("/country-sets");
    return countrySets.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get Territory Sets");
    return [];
  }
};

const addCountrySetApi = async (
  countrySetDto: CountrySetDto
): Promise<CountrySetDto> => {
  try {
    const countrySet = await Api().post<CountrySetDto>(
      "/country-sets",
      countrySetDto
    );
    return countrySet.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to add Territory set");
    throw err;
  }
};

const updateCountrySetApi = async (
  countrySetId: string,
  countrySetDto: CountrySetDto
): Promise<CountrySetDto> => {
  try {
    const countrySet = await Api().put<CountrySetDto>(
      `/country-sets/${countrySetId}`,
      countrySetDto
    );
    return countrySet.data;
  } catch (err) {
    Notification.showApiError(err, "Unable to update Territory set");
    throw err;
  }
};

export const getAllCountrySets =
  (
    force = false
  ): ThunkAction<void, RootState, unknown, CountrySetActionTypes> =>
  async (dispatch, getState) => {
    const { countrySet } = getState();
    if (countrySet.loading || (!force && countrySet.loaded)) {
      return;
    }
    dispatch(requestCountrySets());
    const countrySets: CountrySetDto[] = await getAllCountrySetsFromApi();
    dispatch(receiveCountrySets(countrySets));
  };

export const addCountrySet =
  (
    countrySetDto: CountrySetDto
  ): ThunkAction<
    Promise<CountrySetDto>,
    RootState,
    unknown,
    CountrySetActionTypes
  > =>
  async (dispatch) => {
    const countrySet = await addCountrySetApi(countrySetDto);
    dispatch(getAllCountrySets(true));
    return Promise.resolve(countrySet);
  };

export const updateCountrySet =
  (
    countrySetId: string,
    countrySetDto: CountrySetDto
  ): ThunkAction<
    Promise<CountrySetDto>,
    RootState,
    unknown,
    CountrySetActionTypes
  > =>
  async (dispatch) => {
    const countrySet = await updateCountrySetApi(countrySetId, countrySetDto);
    dispatch(getAllCountrySets(true));
    return Promise.resolve(countrySet);
  };

export const getCountrySetDetails = async (
  countrySetId: string
): Promise<CountrySetDto> => {
  try {
    const countrySet = await Api().get<CountrySetDto>(
      `/country-sets/${countrySetId}`
    );
    return countrySet.data;
  } catch (err) {
    Notification.showApiError(err, "Cannot get details of Territory set");
    throw err;
  }
};
