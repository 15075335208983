// import { addNewRemark, updateRemark } from "../../../../store/remark/RemarkAction";

import { addNewRemark, deleteRemark, updateRemark } from "../../../../store/remark/RemarkAction";
import {
  getPurchaseDetailsApi,
  updatePurchaseMedia,
} from "../../../../store/purchase/PurchaseAction";
import { useCallback, useEffect, useState } from "react";

import EditItemRow from "../../common/EditItemRow";
import { GetPrevilage } from "../../../../utils/Previlage";
import { Grid } from "@material-ui/core";
import { MediaDto } from "../../../../dto/common/MediaDto";
import MediaListAndUpdate from "../../media/MediaListAndUpdate";
import MovieSalesReport from "../../report/salesReport/MovieSalesReport";
import { PurchaseActionTypes } from "../../../../store/purchase/PurchaseTypes";
import PurchaseBasicDetails from "./PurchaseBasicDetails";
import PurchaseCostDetails from "./PurchaseCostDetails";
import { PurchaseDto } from "../../../../dto/purchase/PurchaseDto";
import { PurchaseMediaRequestDto } from "../../../../dto/purchase/PurchaseMediaRequestDto";
import { RemarkDto } from "../../../../dto/purchase/remarks/RemarkDto";
import RemarkListAndUpdate from "../../remark/RemarkListAndUpdate";
import RightDetails from "../../right/RightDetails";
import { RootState } from "../../../../store";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

type PurchaseMediaKeys = keyof PurchaseMediaRequestDto;
type PurchaseRemarkKey = "remarks";

const attachments: { key: PurchaseMediaKeys; name: string }[] = [
  { key: "mainAgreement", name: "Main Agreement" },
  { key: "linkAgreement", name: "Link Agreement" },
  { key: "censorCertificate", name: "Censor Certificate" },
  { key: "labCertificate", name: "Lab Certificate" },
  { key: "realImageLetter", name: "Real Image Letter" },
];

const PurchaseDetails = () => {
  const editPrevilage = GetPrevilage('Purchase', 'Edit');
  const salesViewPrevilage = GetPrevilage('Sales', 'View');
  const rightsViewPrevilage = GetPrevilage('Rights', 'View');

  const { purchaseId } = useParams<{ purchaseId: string }>();

  const [purchase, setPurchase] = useState<PurchaseDto>();
  const [error, setError] = useState(false);

  const dispatch =
    useDispatch<ThunkDispatch<RootState, unknown, PurchaseActionTypes>>();

  const getPurchase = useCallback(() => {
    getPurchaseDetailsApi(purchaseId)
      .then((purchase) => setPurchase(purchase))
      .catch(() => setError(true));
  }, [purchaseId]);

  useEffect(getPurchase, [getPurchase]);

  const onUpdateMedia = useCallback(
    async (key: PurchaseMediaKeys, medias: MediaDto[]) => {
      if (purchase) {
        const newPurcahse: PurchaseMediaRequestDto = {
          [key]: medias,
        };
        await dispatch(updatePurchaseMedia(purchase.id ?? "", newPurcahse));
        getPurchase();
      }
    },
    [getPurchase, purchase, dispatch]
  );

  const onUploadFiles = useCallback(
    async (key: PurchaseMediaKeys, media: MediaDto) => {
      if (purchase) {
        let medias = purchase[key] ?? [];
        medias = medias.concat(media);
        await onUpdateMedia(key, medias);
      }
    },
    [purchase, onUpdateMedia]
  );

  const onDeleteComment =async (remarkId: string) => {
    await dispatch(deleteRemark('purchases', purchaseId, remarkId));
    getPurchase();
  }

  const onAddComment = async (key: PurchaseRemarkKey, remark: RemarkDto) => {
    let reqBody: any = {
      remark: remark.remark,
      createdBy: remark.createdBy?.id ?? "",
      timeCreated: remark.timeCreated,
      timeUpdated: remark.timeUpdated,
    }
    if(remark && purchaseId) {
      if(remark.id === '') {
        await dispatch(addNewRemark('purchases', reqBody, purchaseId));
      } else {
        let remarkId = remark.id ?? "";
        await dispatch(updateRemark('purchases', purchaseId, reqBody, remarkId));
      }
      getPurchase();
    }
  };

  return (
    <div>
      <EditItemRow
        backLabel="Purchases"
        backRoute="/portal/purchase"
        editLabel="Purchase"
        editPrevilage={editPrevilage}
        editRoute={purchase && `/portal/purchase/${purchase.id}/edit`}
      />
      {error ? <p>Failed to fetch purchase</p> : null}
      {purchase ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PurchaseBasicDetails purchase={purchase} />
          </Grid>
          <Grid item xs={12}>
            <PurchaseCostDetails purchase={purchase} />
          </Grid>
          <Grid item xs={12}>
            <RemarkListAndUpdate
              name="remarks"
              key="Remark"
              label="Remark"
              remarks={purchase.remarks}
              onAddComment={onAddComment}
              onDeleteComment={onDeleteComment}
              editPrevilage={editPrevilage ? editPrevilage : false}
            />
          </Grid>
          {purchase.isIntermediary ? (
            <Grid item xs={12}>
              <RightDetails
                label="Intermediary (Primary) Rights"
                rights={purchase.intermediaryRights}
              />
            </Grid>
          ) : null}
          {rightsViewPrevilage ? (
            <Grid item xs={12}>
              <RightDetails label="Rights (Purchase)" rights={purchase.rights} />
            </Grid>
          ) : null}
          {attachments.map((attachment) => (
            <Grid item xs={12} md={6} key={attachment.key}>
              <MediaListAndUpdate
                name={attachment.key}
                label={attachment.name}
                medias={purchase[attachment.key]}
                onUpdateMedias={onUpdateMedia}
                onUploadMedia={onUploadFiles}
                editPrevilage={editPrevilage ? editPrevilage : false}
              />
            </Grid>
          ))}
          {salesViewPrevilage ? (
            <Grid item xs={12}>
              <MovieSalesReport movie={purchase.movie} />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </div>
  );
};

export default PurchaseDetails;
