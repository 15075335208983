import {
  Box,
  Button,
  Grid,
  GridProps,
  IconButton,
  Typography,
} from "@material-ui/core";

import { DateUtils } from "../../../utils/DateUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import { MediaDto } from "../../../dto/common/MediaDto";
import MediaPreview from "./MediaPreview";
import { useCallback } from "react";

interface MediaItemProps {
  media: MediaDto;
  index: number;
  onEdit?: (index: number) => void;
  onDelete?: (index: number) => void;
}

export default function MediaItem({
  media,
  index,
  onEdit,
  onDelete,
  ...props
}: MediaItemProps & GridProps) {
  const onEditClick = useCallback(() => {
    onEdit?.(index);
  }, [onEdit, index]);

  const onDeleteClick = useCallback(() => {
    onDelete?.(index);
  }, [onDelete, index]);

  return (
    <Grid {...props} container spacing={1} alignItems="center">
      <Grid item xs={1}>
        {index + 1}.
      </Grid>
      <Grid item xs>
        <MediaPreview url={media.url} name={media.name} index={index + 1} />
        <Typography variant="body1">{media.comment}</Typography>
        <Typography variant="body2">
          Updated: {DateUtils.getFormattedDate(media.date)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onEditClick}
          >
            Edit
          </Button>
          <IconButton onClick={onDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
}
