import { Grid, Typography, makeStyles } from "@material-ui/core";

import DashboardCard from "../../dashboard/DashboardCard";
import DashboardCardType from "../../dashboard/DashboardCardType";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { GetPrevilage } from "../../../../utils/Previlage";
import MovieIcon from "@material-ui/icons/Movie";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
}));

const ReportDashboard = () => {
  const movieReportPrevilage = GetPrevilage('Reports', 'Movie');
  const purchaseReportPrevilage = GetPrevilage('Reports', 'Purchase');
  const salesReportPrevilage = GetPrevilage('Reports', 'Sales');
  const ownedRightsReportPrevilage = GetPrevilage('Reports', 'Owned Rights');

  const classes = useStyles();

  const ReportCards: DashboardCardType[] = [
    {
      title: "Movie Report",
      description: "Query movies based on actor, language, release date, etc.",
      link: movieReportPrevilage ? "/portal/report/movies" : "/portal/report",
      icon: <MovieIcon color="inherit" />,
      isVisible: movieReportPrevilage,
    },
    {
      title: "Purchase Report",
      description:
        "Query purchase records based on movie, buyer, rights purchased, etc.",
      link: purchaseReportPrevilage ? "/portal/report/purchase" : "/portal/report",
      icon: <ShoppingCartIcon color="inherit" />,
      isVisible: purchaseReportPrevilage,
    },
    {
      title: "Sales Report",
      description:
        "Query sales records based on movie, agreement date, rights sold, etc.",
      link: salesReportPrevilage ? "/portal/report/sales" : "/portal/report",
      icon: <ReceiptIcon color="inherit" />,
      isVisible: salesReportPrevilage,
    },
    {
      title: "Owned Rights (Not Sold)",
      description: "Query rights currently owned for movie",
      link: ownedRightsReportPrevilage ? "/portal/report/owned-rights" : "/portal/report",
      icon: <EventAvailableIcon color="inherit" />,
      isVisible: ownedRightsReportPrevilage,
    },
  ];

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Report Dashboard
      </Typography>
      <Grid container spacing={4}>
        {ReportCards.map((card, index) => (
          <>
            {card.isVisible && (
              <DashboardCard card={card} key={index} />
            )}
          </>
        ))}
      </Grid>
    </div>
  );
};

export default ReportDashboard;
