import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const year3000 = new Date("3000-01-01");

interface DateFieldProps {
  value?: Date;
  onChange: (date?: Date) => void;
}

const DateField = ({
  value,
  onChange,
  ...props
}: DateFieldProps & Omit<KeyboardDatePickerProps, "value" | "onChange">) => {
  const onCustomChange = (date: MaterialUiPickersDate) => {
    date?.setHours(0, 0, 0, 0);
    onChange(date ?? undefined);
  };
  return (
    <KeyboardDatePicker
      {...props}
      value={value ? value : null}
      onChange={onCustomChange}
      format="dd/MM/yyyy"
      maxDate={year3000}
    />
  );
};

export default DateField;
