import { useDispatch, useSelector } from "react-redux";

import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import { RootState } from "../../../../store";
import { getAllMovies } from "../../../../store/movie/MovieAction";
import { useEffect } from "react";

interface ChooseMovieProps {
  movie: string;
  setMovie: (movie: string) => void;
  label?: string;
  disabled?: boolean;
}

export function ChooseMovie(props: ChooseMovieProps) {
  const movies = useSelector((state: RootState) => state.movie.movies);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMovies());
  }, [dispatch]);
  return (
    <CustomAutocomplete
      value={movies.find((movie) => movie.id === props.movie) ?? null}
      onChange={(newMovie) => props.setMovie(newMovie?.id ?? "")}
      options={movies}
      getOptionLabel={(movie) => movie?.name ?? ""}
      label={props.label ?? "Movie"}
      variant="outlined"
      disabled={props.disabled}
    />
  );
}
