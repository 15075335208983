import React, { useCallback, useEffect, useState } from 'react'

import BlockIcon from '@material-ui/icons/Block';
import DetailCard from '../../common/DetailCard';
import DetailItem from '../../common/DetailItem';
import DoneIcon from '@material-ui/icons/Done';
import EditItemRow from '../../common/EditItemRow';
import { GetPrevilage } from '../../../../utils/Previlage';
import { Grid } from '@material-ui/core';
import { RoleDto } from '../../../../dto/role/RoleDto';
import { getRoleDetails } from '../../../../store/role/RoleAction';
import { useParams } from 'react-router-dom';

const RoleDetails = () => {
  const editPrevilage = GetPrevilage('Roles', 'Edit');
  const { roleId } = useParams<{ roleId: string }>();
  const [role, setRole] = useState<RoleDto>();
  const [error, setError] = useState<boolean>(false);

  const getMovie = useCallback(() => {
    getRoleDetails(roleId)
      .then(setRole)
      .catch(() => setError(true));
  }, [roleId]);

  useEffect(getMovie, [getMovie]);

  return (
    <div>
      <EditItemRow
        backLabel="Roles"
        backRoute="/portal/roles"
        editLabel="Role"
        editPrevilage={editPrevilage}
        editRoute={role && `/portal/roles/${role?.id}/edit`}
      />
      {error ? <p>Failed to fetch role details</p> : null}
      {role ? (
        <DetailCard title="Role information">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* <h3 style={{margin: "0"}}>Name</h3> */}
              <DetailItem label="Name">{role.name}</DetailItem>
              <h4 style={{marginBottom: "10px"}}>Previlages</h4>
              {role.previlages.map((previlage, idx) => (
                <div key={idx} style={{marginBottom: "20px"}}>
                  <DetailItem label="">
                    <span style={{margin: "0 0 6px", display: "block", fontWeight: 500, fontSize: "12px"}}>{previlage.name}</span>
                  </DetailItem>
                  {previlage.options.map((option, innerIdx) => (
                    <div style={{display: "inline-block", marginRight: "30px"}} key={innerIdx}>
                      <DetailItem label="">
                        <span style={{margin: "0", fontWeight: 500, fontSize: "12px"}}>{option.name}</span>
                      </DetailItem>
                      <div style={{textAlign: "center"}}>
                        {option.value ? <DoneIcon /> : <BlockIcon />}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </Grid>
          </Grid>
        </DetailCard>
      ) : null}
    </div>
  )
}

export default RoleDetails
