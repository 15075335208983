import { Grid, GridProps } from "@material-ui/core";

import { ChooseMovie } from "../../movie/chooseMovie/ChooseMovie";
import CustomAutocomplete from "../../../common/form/CustomAutocomplete";
import CustomSpacingGrid from "../../../common/layout/CustomSpacingGrid";
import FormSection from "../../common/FormSection";
import { SaleBasicDetailsRequestDto } from "../../../../dto/sale/SaleRequestDto";
import useSaleProperty from "../../../../store/sale/useSaleProperty";

interface SalesBasicDetailsFormProps {
  sales: SaleBasicDetailsRequestDto;
  setSales: (sales: SaleBasicDetailsRequestDto) => void;
}

export default function SalesBasicDetailsForm({
  sales,
  setSales,
  ...props
}: SalesBasicDetailsFormProps & GridProps) {
  const setMovie = (movie: string) => setSales({ ...sales, movie });
  const setLanguage = (language: string) => setSales({ ...sales, language });

  const languageOptions = useSaleProperty("language");

  return (
    <FormSection label="Sale Details" {...props}>
      <CustomSpacingGrid container horizontalSpacing={2} verticalSpacing={1}>
        <Grid item xs={12} md={6}>
          <ChooseMovie movie={sales.movie} setMovie={setMovie} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            label="Language"
            type="text"
            options={languageOptions}
            freeSolo
            required={false}
            inputValue={sales.language}
            onInputChange={setLanguage}
          />
        </Grid>
      </CustomSpacingGrid>
    </FormSection>
  );
}
